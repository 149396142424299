
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "breadcrum",
})
export class Breadcrum extends Vue {
  @Prop({ required: true }) title!: string[2];
  @Prop({ required: true }) path!: string;

  navigate(): void {
    this.$router.push(this.path);
  }
}
export default Breadcrum;
