import { RawProvince } from "@/entities";

export class Province {
  readonly id: string;
  readonly name: string;

  constructor(data: RawProvince) {
    this.id = data.id || "";
    this.name = data.name || "";
  }
}
