
import { Component, Prop, Vue } from "vue-property-decorator";
import { PButton, PTableSimple } from "@/common/components";
import { NoData, Utilities } from "@/components/commons";
import { header_fields } from "./AutomaticRulesHeaderFields";
import { MachineDetail, Rule } from "@/entities";
import { EventBus } from "@/event-bus";
import Pageable from "@/entities/pageable/pageable_collection";

@Component({
  name: "automatic-rules-grid",
  components: {
    PTableSimple,
    PButton,
    NoData,
  },
})
export class AutomaticRulesGrid extends Vue {
  @Prop({ required: false, default: [] }) rules!: Rule[];

  _pageSize!: number;
  _currentPage!: number;
  Items: MachineDetail[] = [];
  ItemsTemp: MachineDetail[] = [];
  itemSize = 100;
  paramsfilter!: Record<string, unknown>;
  isLoading = true;

  created(): void {
    this.refresh();
  }

  mounted(): void {
    EventBus.$on("refresh-automatic-grid", this.refresh);
  }
  beforeDestroy(): void {
    EventBus.$off("refresh-automatic-grid", this.refresh);
  }

  async refresh(): Promise<void> {
    this.isLoading = true;
    this._currentPage = this.$props.currentPage;
    this._pageSize = this.$props.pageSize;
  }

  async onSortChanged({ sortField, sortOrder }: { sortField: string; sortOrder: string }) {
    await this.fetchMachineDetail(sortOrder, sortField);
  }
  async fetchMachineDetail(sortOrder = "ASC", sortField = "id") {
    this.paramsfilter = header_fields;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rulesNew = this.rules.map(({ id, ...rule }) => {
      rule.fieldValue = rule.fieldValue.split(";")[1];
      if (rule.fieldValue.split(",").length > 1) {
        rule.fieldValue = rule.fieldValue.split(",")[1];
      }
      return rule;
    });
    const offset = (this._currentPage - 1) * this._pageSize;
    const pagination = Utilities.pageable(this._pageSize, offset, sortField, sortOrder);
    const map = {
      pageRequest: pagination,
      rules: rulesNew,
    };
    const data = await this.$services.machine.fetchMachinesByAutomaticRules(map);
    this.setMachineDetail(data);
  }

  setMachineDetail(data: Pageable<MachineDetail>) {
    this.itemSize = data.totalResult;
    this.Items = data.results;
    this.ItemsTemp = this.Items;
    this.isLoading = false;
  }
}

export default AutomaticRulesGrid;
