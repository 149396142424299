import Pageable from "@/entities/pageable/pageable_collection";
import { SolutionHistoryService } from "./solutionHistory.types";
import Vue from "vue";
import { BACKEND_BASE_URL_READS, Filter } from "../backend.types";
import { RawSolutionHistory, SolutionHistory } from "@/entities/solution-history";
import { AxiosRequestConfig } from "axios";
import { RawPage } from "@/entities/pageable/pageable_types";

export class SolutionHistoryServiceImpl implements SolutionHistoryService {
  async fetchSolutionFileHistory(map: Record<string, unknown>): Promise<Pageable<SolutionHistory>> {
    const { data } = await Vue.$axios.post<RawPage<RawSolutionHistory>>(
      `${BACKEND_BASE_URL_READS}/api/v1/solutionhistory`,
      map
    );
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawSolutionHistory) => new SolutionHistory(rawSolutionHistory)) ?? [],
    };
  }

  async downloadSolutionFileHistory(
    sortFields: [],
    filters: Filter[],
    fields: { field: string; label: string }[]
  ): Promise<File> {
    const url = `${BACKEND_BASE_URL_READS}/api/v1/solutions/export`;
    const body = { sortFields, filters, fields };
    const responseType: AxiosRequestConfig = { responseType: "blob" };
    const response = await Vue.$axios.post(url, body, responseType);
    const contentType = response.headers["content-type"];
    const filename = `report-${filters[0].value}.xlsx`;
    const blob = new Blob([response.data as BlobPart], {
      type: contentType,
    });
    return new File([blob], filename);
  }
}
