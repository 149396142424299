import { RawUser } from "../user";
import { RawAssignedServicePoint } from "@/entities/assignedServicePoint";

export type RawGroup = {
  readonly id: string;
  readonly aggregate_id: string;
  readonly name: string;
  readonly description: string;
  readonly members: Array<RawUser>;
  readonly lastUpdate: string;
  readonly assignedServicePoints: RawAssignedServicePoint[];
};

export const iniRawGroup: RawGroup = {
  id: "",
  aggregate_id: "",
  name: "",
  description: "",
  members: Array.of(),
  lastUpdate: "",
  assignedServicePoints: Array.of(),
};
