
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "wizard-tooltip",
  components: {},
})
export class WizardToolTip extends Vue {
  @Prop({ required: false, default: "" }) text!: string;
}

export default WizardToolTip;
