import { RawMachineType } from "./machinetype.type";
import { MachineType } from "./machinetype";

export const mockRawMachineType = (): RawMachineType[] => [
  {
    id: "0fb3ee2a-651f-33a6-9a07-318e7313b1dd",
    name: "ACR",
  },
  {
    id: "1fc5efe0-2098-3df2-813a-97ce9f2f586f",
    name: "BCC",
  },
];

export const mockMachineType = (data: RawMachineType[] = mockRawMachineType()): MachineType[] =>
  data.map((item) => new MachineType(item));
