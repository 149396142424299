
import { Vue, Prop, Component } from "vue-property-decorator";
import { PTableMultilevelSettings } from "./PTableMultilevel.type";
import { PTableMaker } from "./PTableMaker";
import { EventBus } from "@/event-bus";

@Component({
  name: "p-table-multilevel",
})
export class PTableMultilevel extends Vue {
  @Prop({ required: true }) settings!: PTableMultilevelSettings;

  localSettings: PTableMultilevelSettings = {} as PTableMultilevelSettings;
  ptableMaker: PTableMaker = {} as PTableMaker;

  created(): void {
    this.localSettings = this.$props.settings as PTableMultilevelSettings;
    this.ptableMaker = new PTableMaker("table_multilevel", this.localSettings);
    this.ptableMaker.$on("show-subtable", this.emitClickEventShowSubTable);
    EventBus.$on("ptablemaker_subtable_click", this.emitClickEventOnSubTableLastCell);
    EventBus.$on("ptablemaker_click-on-first-table-cell", this.emitTableClickOnFirstCell);
    EventBus.$on("p-table-multilevel-header-click", this.orderByColumn);
  }

  mounted(): void {
    this.ptableMaker.initializeTable();
  }

  public refreshData(rows: Record<string, unknown>[]): void {
    this.ptableMaker.refreshDataSource(rows);
  }

  public makeSubTable(rowindex: number, settings: PTableMultilevelSettings): void {
    this.ptableMaker.pSubtableMaker.showSubTable(rowindex, settings);
  }

  public orderByColumn(key: string): void {
    const span: HTMLElement | null = document.getElementById("span-icon-" + key);
    let orderASC: boolean = false;
    if (span != null) {
      span.className = "p-table-multi-level-0-icon";
      const icon = span.innerHTML;
      if (icon == "▼") {
        span.innerHTML = "▲";
        orderASC = true;
      } else {
        span.innerHTML = "▼";
        orderASC = false;
      }
    }
    EventBus.$emit("p-table-multilevel-order-by-column", key, orderASC);
  }

  private emitClickEventOnSubTableLastCell(rowindex: number): void {
    this.$emit("show-modal-on-subtable", rowindex);
  }
  private emitClickEventShowSubTable(rowindex: number): void {
    this.$emit("show-subtable", rowindex);
  }
  private emitTableClickOnFirstCell(value: string): void {
    this.$emit("show-info-on-first-cell-table", value);
  }
}
export default PTableMultilevel;
