import { RawMachine } from "@/entities";

export class Machine {
  readonly id: string;
  readonly name: string;
  readonly maker: string;
  readonly model: string;
  readonly type: string;
  readonly country: string;
  readonly delegation: string;
  readonly last_update: string;
  readonly code: string;
  readonly service_point_count: number;

  constructor(data: RawMachine) {
    this.name = data.name || "";
    this.id = data.id || "";
    this.maker = data.manufacturer || "";
    this.model = data.model || "";
    this.type = data.type || "";
    this.country = data.countryCode || "";
    this.delegation = data.delegationCode || "";
    this.last_update = data.lastUpdate || "";
    this.code = data.code || "";
    this.service_point_count = data.servicePointCount || 0;
  }
}
