
import { PTableMakerParent } from "./PtableMakerParent";
import { HeaderRows, PTableMultilevelSettings } from "./PTableMultilevel.type";

export class PSubtableMaker extends PTableMakerParent {

tbody: HTMLTableSectionElement= {} as HTMLTableSectionElement; 
table: HTMLTableElement ={} as HTMLTableElement;
class_LastRowLevel0Clicked = "last_row_level_0_clicked";
class_SubTableLevel0Row = "SubTableLevel0_row_";
class_Multilevel ="table_multilevel_";
class_collapseRow= "collapse_row";
class_clickShowIcon ="click-show-icon";

constructor(tbody: HTMLTableSectionElement, table: HTMLTableElement){
 super();
 this.tbody= tbody;
 this.table =table;
}
   
showSubTable(rowindex: number,settings:PTableMultilevelSettings): void {
    
    const tr : HTMLTableRowElement = document.createElement("tr");
    const td : HTMLTableCellElement = document.createElement("td");

    const headers:HeaderRows[] = settings.datasource.header.fields.filter((header)=>header.isVisible);
    td.colSpan=this.getColSpanOnMainTable();
    td.append(this.createSubTable(this.showFieldHeader(settings,headers),headers));
    tr.append(td);
    td.className="subtable-td-class";
    tr.id= this.class_SubTableLevel0Row + String(rowindex);
    const existSubTable =this.existSubTable(rowindex)
    if (existSubTable){
        this.removeSubTable(rowindex);     
    }
    
    this.tbody.childNodes.forEach((p)=> {
        const nodeRow : HTMLTableRowElement = p as HTMLTableRowElement; 
        if (nodeRow.className === this.class_Multilevel + String(rowindex))
        {   
            if (!existSubTable){
                this.changeChrevonIcon(nodeRow,true);
                nodeRow.after(tr);    
            }else{
                this.changeChrevonIcon(nodeRow,false);
            }                                       
        }  
    });  
}
  
launchClickEventOnLastCell(row: number): void {
   this.$emit('click-event-on-last-cell',row);
}

private showFieldHeader(settings:PTableMultilevelSettings, headers:HeaderRows[]) :Record<string,unknown>[] {
    let rows:Record<string,unknown>[] = [];
    
    settings.datasource.rows.forEach((row:Record<string,unknown>,idx)=> { 
        let customRow:Record<string,unknown>= {};
        headers.forEach((header)=> {
            if(row.hasOwnProperty(header.key)){
                customRow[header.key] =row[header.key];
            }
        });     
        rows.push(customRow);
    });   
   
    return rows;
}


private changeChrevonIcon(nodeRow : HTMLTableRowElement, show:boolean ): void{
    show ? nodeRow.lastElementChild?.classList.add(this.class_LastRowLevel0Clicked) :   nodeRow.lastElementChild?.classList.remove(this.class_LastRowLevel0Clicked) ;      
    let cell : HTMLTableCellElement | null= nodeRow.cells.item(nodeRow.cells.length - 1);
    document.getElementById("chevron_rowlevel0_idx_" + nodeRow.rowIndex)?.remove();    
    if (cell !=null){
       let value = cell.textContent || "";
       if (show){
        cell.innerHTML="<span> "+ value + "<div style='display: contents' id='chevron_rowlevel0_idx_" + nodeRow.rowIndex + "'>" + this.chevronup() +" </div></span>"; 
       }else{
        cell.innerHTML="<span> "+ value + "<div style='display: contents' id='chevron_rowlevel0_idx_" + nodeRow.rowIndex + "'>" + this.chevrondown()   +" </div></span>"; 
       }
                
    }        
}
      
private createSubTable(rows: Record<string,unknown>[], columns: HeaderRows[]): HTMLTableElement {
    const table : HTMLTableElement = document.createElement("table");    
    const thead: HTMLTableSectionElement= table.createTHead();
    const tbody: HTMLTableSectionElement= table.createTBody();
    const thead_tr : HTMLTableRowElement = thead.insertRow(0);
    thead_tr.className= "subtable-tr-class";


    if(rows.length == 0){
        const div : HTMLElement = document.createElement("div");
        div.className = "no-data";
        div.innerHTML = "No hay información para mostrar."
        tbody.append(div);
        return table;
    }

    columns.forEach( (col,index)=>{
            const th: HTMLTableCellElement = document.createElement("th");
            th.innerText=col.value.toUpperCase();
            thead_tr.appendChild(th);
    });



    rows.forEach((row,rowIndex)=>{                
        const newRow : HTMLTableRowElement =tbody.insertRow(rowIndex); 

            columns.forEach( (col,index)=>{

            if (col.key=="ShowSubLevel"){               
                let newcell = newRow.insertCell();
                newcell.setAttribute(":class","isActive")
                newcell.classList.add(this.class_collapseRow);
                newcell.classList.add(this.class_clickShowIcon);
                if (row[col.key] as number > 0){
                newcell.addEventListener('click', ()=>{this.launchClickEventOnLastCell(rowIndex)} )
                newcell.insertAdjacentHTML("beforeend","<span>" + String(row[col.key]) +" "+ this.getEyeIcon()  + "</span>");
        
                }else
                newcell.insertAdjacentHTML("beforeend","<span>" + this.getEyeIcon()  + "</span>");
        
            }  else {

                const newcell = newRow.insertCell();
                newcell.innerText=String(row[col.key]);
                if(col.dataOnFieldShowBolderFont != undefined && col.dataOnFieldShowBolderFont){
                  newcell.classList.add("subtable-datafield-bold-active")
                }else{
                    newcell.classList.remove("subtable-datafield-bold-active");
                }
            } 
            
        })
    });
    return table;
}

    
private getSubTable(rowindex : number): HTMLElement | null{
  const element: HTMLElement | null = document.getElementById(this.class_SubTableLevel0Row + String(rowindex));
  return element;
}
    
private existSubTable(rowindex: number): boolean {   
  return this.getSubTable(rowindex) != null ? true : false;
}
    
private removeSubTable(rowindex : number) : void {  
  const subtable: HTMLElement | null = this.getSubTable(rowindex); 
  if (subtable!=null){
      this.tbody.removeChild(subtable);   
  }   
}

private getColSpanOnMainTable(): number {
  return this.table.tHead?.childNodes[0].childNodes.length == undefined ? 0:
         this.table.tHead?.childNodes[0].childNodes.length + 1 ;
}

private getEyeIcon(): string {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M10 12.458q.708 0 1.177-.468.469-.469.469-1.178 0-.708-.469-1.177-.469-.468-1.177-.468-.708 0-1.177.468-.469.469-.469 1.177 0 .709.469 1.178.469.468 1.177.468Zm4.25 4.125q.146 0 .271-.093.125-.094.208-.261l-3.312-3.291q-.355.229-.698.333-.344.104-.719.104-1.062 0-1.812-.75t-.75-1.813q0-1.062.75-1.812T10 8.25q1.062 0 1.812.75t.75 1.812q0 .438-.114.782-.115.344-.344.677l2.813 2.896V7.25l-3.229-3.833H5.604q-.208 0-.364.156-.157.156-.157.365v12.124q0 .209.157.365.156.156.364.156Zm-8.646.917q-.625 0-1.031-.406-.406-.406-.406-1.032V3.938q0-.626.406-1.032T5.604 2.5h6.521l3.708 4.458v9.146q0 .625-.406 1.011-.406.385-1.031.385Zm4.854-7.312Z"/></svg>
`
}

}



