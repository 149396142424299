import Vue from "vue";
import { ValidationProvider, extend, configure } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  confirmed,
  alpha_spaces,
  alpha_num,
  regex,
  min_value,
  max_value,
  between,
  digits,
} from "vee-validate/dist/rules";
import { i18n } from "@/i18n";

configure({
  defaultMessage: (field, values): string => i18n.t(`validation.messages.${values?._rule_}`, values) as string,
});

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);
extend("between", between);
extend("confirmed", confirmed);
extend("alpha_num", alpha_num);
extend("alpha_spaces", alpha_spaces);
extend("regex", regex);
extend("digits", digits);

extend("min_characters", {
  validate: (value, { length }) => {
    return value.length >= length;
  },
  params: ["length"],
  message: (value, { length }) => i18n.t("validation.messages.min_characters", { length }) as string,
});

extend("min_digits", {
  validate: (value, { length }) => {
    return value.length >= length;
  },
  params: ["length"],
  message: (value, { length }) => i18n.t("validation.messages.min_digits", { length }) as string,
});

extend("selected", {
  ...required,
  message: () => i18n.t("validation.messages.selected") as string,
});

extend("phone", {
  validate: (value) => {
    const patt = new RegExp(/^[+]{0,1}[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g);
    return patt.test(value);
  },
  message: () => i18n.t("errorValidator.phone") as string,
});

extend("min-interval-hours", {
  validate: (value, args) => {
    const stringArgs = args as string[];
    const minimunIntervalTime = stringArgs[0] ? Number(stringArgs[0]) : undefined;
    const initalTime = stringArgs[1];
    const finalTime = stringArgs[2];
    if (
      !minimunIntervalTime ||
      !initalTime ||
      !finalTime ||
      initalTime?.split(":").length < 2 ||
      finalTime?.split(":").length < 2
    ) {
      return true;
    }

    const initialHour = initalTime.split(":")[0];
    const initialMinute = initalTime.split(":")[1];
    const finalHour = finalTime.split(":")[0];
    const finalMinute = finalTime.split(":")[1];
    const difHour = Number(finalHour) - Number(initialHour);
    const difMinute = Number(finalMinute) - Number(initialMinute);

    if (difHour < minimunIntervalTime || (difHour === minimunIntervalTime && difMinute < 0)) {
      return false;
    }

    return true;
  },
  message: () => i18n.t("validation.messages.minimumRangeIntervalHour") as string,
});
extend("min-starting-interval", {
  validate: (value, args) => {
    const stringArgs = args as string[];

    if (stringArgs.length < 2 || !stringArgs[0] || !stringArgs[1] || !value) {
      return true;
    }

    const minDiff = stringArgs[0];
    const date = new Date(stringArgs[1]);
    date.setHours(value.split(":")[0]);
    date.setMinutes(value.split(":")[1]);
    date.setSeconds(value.split(":")[2]);
    const diff = (date.getTime() - new Date().getTime()) / 36e5;

    if (diff < Number(minDiff)) {
      return false;
    }

    return true;
  },
  message: () => i18n.t("validation.messages.minimumStartingInterval") as string,
});
extend("min-hour", {
  validate: (value, args) => {
    const stringArgs = args as string[];

    if (stringArgs.length < 1 || !stringArgs[0] || !value) {
      return true;
    }

    const minHour = stringArgs[0];
    const hour = value.split(":")[0];

    if (Number(hour) < Number(minHour)) {
      return false;
    }

    return true;
  },
  message: () => i18n.t("validation.messages.initialHour") as string,
});
extend("max-hour", {
  validate: (value, args) => {
    const stringArgs = args as string[];

    if (stringArgs.length < 1 || !stringArgs[0] || !value) {
      return true;
    }

    const maxHour = stringArgs[0];
    const hour = value.split(":")[0];

    if (Number(hour) > Number(maxHour)) {
      return false;
    }

    return true;
  },
  message: () => i18n.t("validation.messages.finalHour") as string,
});

extend("gt", {
  validate: (value, args) => {
    const stringArgs = args as string[];
    if (stringArgs.length < 1 || !stringArgs[0] || !value) {
      return true;
    }
    const minValue = Number(stringArgs[0]);

    if (Number(value) <= minValue) {
      return false;
    }

    return true;
  },
  message: (key, args) => i18n.t("validation.messages.greaterThan", args) as string,
});

Vue.component("ValidationProvider", ValidationProvider);
