
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import Popper, { Placement } from "popper.js";

@Component
export class PDropDownContainer extends Vue {
  @Prop({ required: false, default: false }) value!: boolean;
  @Watch("value")
  onChangeShow(): void {
    this.isOpen = this.value;
  }
  @Prop({ required: false, default: false }) alwaysOpen!: boolean;
  @Prop({ required: false, default: false }) alwaysClose!: boolean;
  @Prop({required: false, default: false}) minContainer!: boolean;
  @Ref("button") readonly button!: any;
  @Ref("dropdown") readonly dropdown!: any;
  @Prop({ required: false, default: "bottom-start" }) placement!: Placement;
  @Prop({ required: false, default: 0 }) offset!: number;

  popper: any = undefined;
  isOpen = false;
  get classOptions(): string {
    if (this.alwaysOpen) {
      return "alwaysOpen_PDropDownContainer";
    } else {
      return "optionsSelect_PDropDownContainer";
    }
  }
  beforeDestroy(): void {
    if (this.popper) this.popper.destroy();
  }
  mounted(): void {
    if (this.alwaysOpen) {
      this.isOpen = true;
    }
  }

  setupPopper(): void {
    // this.popper var is not reactive because it is not into data()
    // we use for save the instanciate new Popper once and avoid memory leaks using always this.popper
    if (this.popper === undefined) {
      if(this.offset){
        this.popper = new Popper(this.button, this.dropdown, {
          placement: "top-start",
          modifiers: {
            offset: {
              offset: "0, -"+this.offset+"px"
            }
          }
        });
      }else {
        this.popper = new Popper(this.button, this.dropdown, {
          placement: this.placement,
        });
      }
    } else {
      // recalculate
      this.popper.scheduleUpdate();
    }
  }

  open(): void {
    if (!this.alwaysClose) {
      this.isOpen = !this.isOpen;
      this.$emit("input", this.isOpen);
      // Wait to nextTick due to searchInput focus() it's not already mounted ( v-show )
      if (this.popper) this.setupPopper();
      this.$nextTick(() => {
        this.setupPopper();
      });
    } else {
      if (this.popper) this.popper.destroy();
    }
  }
}
export default PDropDownContainer;
