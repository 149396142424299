
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniSolutionHistoryFilter, ISolutionHistoryFilter } from "@/services";
import { MachineImportNewModal } from "@/components/machines/machine-import-new-modal/MachineImportNewModal.vue";
import {
  PButton,
  PFormDatepickerActions,
  PFormInputHour,
  PFormInputTextSearch,
  PFormSelect,
  SelectType,
} from "@/common/components";
import { Getter } from "vuex-class";
import { Country, User } from "@/entities";
import DateTimeMixin from "@/mixins/datetime";
import { i18n } from "@/i18n";

@Component({
  name: "solution-history-filter-wrapper",
  components: {
    MachineImportNewModal,
    PFormDatepickerActions,
    PFormInputHour,
    PFormSelect,
    PFormInputTextSearch,
    PButton,
  },
  mixins: [DateTimeMixin],
})
export class SolutionHistoryFilterWrapper extends Vue {
  @Prop({ required: true }) value!: ISolutionHistoryFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) {
      this.valueLocal = JSON.parse(JSON.stringify(this.value));
      this.valueLocal.startDate = new Date(this.valueLocal.startDate);
      this.valueLocal.endDate = new Date(this.valueLocal.endDate);
      this.valueLocal.country = "";
    }
  }
  @Getter("getCountries") getCountries!: Country[];
  @Getter("getLoggedUser") getLoggedUser!: User;
  language = i18n.locale;
  solutionTypes: SelectType[] = [
    { text: this.$t("support_panel.dropdown.types.uploaded") as string, value: "Uploaded" },
    { text: this.$t("support_panel.dropdown.types.errors") as string, value: "Has errors" },
  ];
  valueLocal: ISolutionHistoryFilter = { ...iniSolutionHistoryFilter };
  maxDays = 31;
  timeStart = "00:00";
  timeEnd = "23:59";

  search(): void {
    this.$emit("input", this.valueLocal);
  }

  get endSelectorendDate(): Date | undefined {
    if (this.valueLocal.startDate) {
      const maxDate = this.addDaysToDate(this.valueLocal.startDate, this.maxDays);
      if (
        this.valueLocal.endDate &&
        this.intervalExceedNumberOfDays(this.valueLocal.startDate, this.valueLocal.endDate, this.maxDays)
      ) {
        this.valueLocal.endDate = new Date(maxDate.getTime());
      }
      return new Date(maxDate.getTime());
    }
    return undefined;
  }

  resetTime(): void {
    this.setTime("00:00", "23:59");
    this.search();
  }
  isIntervalDateValid(): boolean {
    return this.isIntervalValid(this.valueLocal.startDate, this.valueLocal.endDate);
  }
  getTimeOfDate(date: Date): string {
    return this.getHoursOfDate(date) + ":" + this.getMinutesOfDate(date);
  }
  getHoursOfDate(date: Date): string {
    return ("0" + date.getHours()).slice(-2);
  }
  getMinutesOfDate(date: Date): string {
    return ("0" + date.getMinutes()).slice(-2);
  }
  changeTimeStart(newTimeStart: string): void {
    this.setTime(newTimeStart, this.timeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeEnd(newTimeStart);
    }
  }
  changeTimeEnd(newTimeEnd: string): void {
    this.setTime(this.timeStart, newTimeEnd);
    if (!this.isIntervalDateValid()) {
      this.changeTimeStart(newTimeEnd);
    }
  }
  changeDateStart(): void {
    this.setTime(this.timeStart, this.timeEnd);
    if (this.valueLocal.startDate && !this.isIntervalDateValid()) {
      this.valueLocal.endDate = new Date(this.valueLocal.startDate.getTime());
      this.resetTime();
    }
  }
  changeDateEnd(): void {
    this.setTime(this.timeStart, this.timeEnd);
    if (this.valueLocal.endDate && !this.isIntervalDateValid()) {
      this.valueLocal.startDate = new Date(this.valueLocal.endDate.getTime());
      this.resetTime();
    }
  }
  setTimeFromString(time: string, date: Date, isForEnd = false): void {
    if (time && date) {
      const timeLocal = time.split(":");
      date.setHours(parseInt(timeLocal[0]));
      date.setMinutes(parseInt(timeLocal[1]));
      date.setSeconds(isForEnd ? 59 : 0);
      date.setMilliseconds(isForEnd ? 999 : 1);
    }
  }
  completeTwoDigitsWithZero(value: string): string {
    const timeLocal = value.split(":");
    return ("0" + timeLocal[0]).slice(-2) + ":" + ("0" + timeLocal[1]).slice(-2);
  }
  setTime(timeStart: string, timeEnd: string): void {
    if (this.valueLocal.startDate) this.setTimeFromString(timeStart, this.valueLocal.startDate);
    this.timeStart = this.completeTwoDigitsWithZero(timeStart);
    if (this.valueLocal.endDate) this.setTimeFromString(timeEnd, this.valueLocal.endDate, true);
    this.timeEnd = this.completeTwoDigitsWithZero(timeEnd);
  }
  changeCreditDateHourStart(time: string): void {
    if (this.valueLocal.startDate) this.setTimeFromString(time, this.valueLocal.startDate);
  }
  changeCreditDateHourEnd(time: string): void {
    if (this.valueLocal.endDate) this.setTimeFromString(time, this.valueLocal.endDate, true);
  }
  creditDateTodayDate(): void {
    this.valueLocal.startDate = this.getTodayDate();
    this.valueLocal.endDate = this.getTodayDate();
    this.resetTime();
  }
  creditDateYesterdayDate(): void {
    this.valueLocal.startDate = this.getYesterdayDate();
    this.valueLocal.endDate = this.getYesterdayDate();
    this.resetTime();
  }
  creditDateThisWeekDate(): void {
    const dates = this.getThisWeekDates();
    this.valueLocal.startDate = dates.start;
    this.valueLocal.endDate = dates.end;
    this.resetTime();
  }
  creditDateThisMonthDate(): void {
    const dates = this.getThisMonthDates();
    this.valueLocal.startDate = dates.start;
    this.valueLocal.endDate = dates.end;
    this.resetTime();
  }
}
export default SolutionHistoryFilterWrapper;
