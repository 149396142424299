export const header_fields = {
  fields: [
    {
      key: "check",
      type: "text",
      label: "Fecha error",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: false,
      showGroupedField: false,
      showHideField: false,
      showFilterField: false,
      // formatter: (id: string) => format(new Date(id), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      key: "code",
      type: "text",
      label: "Código error",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
      // formatter: (id: string) => format(new Date(id), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      key: "description",
      type: "text",
      label: "Descripción",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
      // formatter: (id: string) => format(new Date(id), "dd/MM/yyyy HH:mm:ss"),
    },
    {
      key: "actualId",
      type: "text",
      label: "Actual ID",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "entityId",
      type: "text",
      label: "Código Entidad",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "entity",
      type: "text",
      label: "Entidad",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "deviceId",
      type: "text",
      label: "Device ID",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "status",
      type: "text",
      label: "Estado",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "jsonDetail",
      type: "text",
      label: "Detalle",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: false,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
  ],
};
