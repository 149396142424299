
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PTableExtendType, FieldPTableExtendType } from "../PTableExtend/PTableExtend.type";
import { PTableMaker } from "../PTableMultilevel/PTableMaker";

@Component({
  name: "p-table-extend",
  components: {},
})
export class PTableExtend extends Vue {
  @Prop({ required: true }) value!: PTableExtendType;
  @Prop({ required: false, default: "" }) personalizeClass!: string;
  @Prop({ required: true }) items!: any[];
  @Prop({ required: false, default: "" }) headerLastColumnValue!: string;
  ptableMaker: PTableMaker = {} as PTableMaker;
  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.fieldsFormatter = this.fieldsFormatter.concat(this.value.fields);
  }

  valueLocal: PTableExtendType = this.getDefaultValues();
  fieldsFormatter: FieldPTableExtendType[] = [];
  existData = false;
  orderByField: string = "";
  direction: boolean = true;
  asc = "ASC";
  desc = "DESC";
  formatFiled(field: any, item: any): any {
    const filedTemp: any = this.fieldsFormatter.find((fi: FieldPTableExtendType) => fi.key == field.key);
    if (filedTemp?.formatter) {
      return filedTemp.formatter(item[field.key]);
    } else {
      return item[field.key];
    }
  }

  fireClick(item: any) {
    this.$emit("click", item);
  }
  /*este evento se utiliza para obtener
   * y desplegar en forma dinamica
   * nuevas filas que en nuestro caso particular
   * deplegaran nuevos puntos de servicios.
   */
  showIteration(row: number): void {
    this.$emit("display-row", row);
  }

  toogleOrderIcon(fieldKey: string): void {
    const htmlSpan: Vue = this.$refs[fieldKey] as Vue;
    if (htmlSpan == undefined) return;

    let valueSpan: Element = Array.isArray(htmlSpan) ? htmlSpan[0] : htmlSpan;
    if (valueSpan == undefined) return;

    if (valueSpan.innerHTML == "▼") {
      this.direction = true;
      valueSpan.innerHTML = "▲";
    } else {
      valueSpan.innerHTML = "▼";
      this.direction = false;
    }
    this.orderByField = fieldKey;
    this.$emit('sort-changed', { sortField: this.orderByField, sortOrder: this.direction ? this.asc : this.desc });
  }

  get itemsComp(): any[] {
    let itemsTemp: any[] = this.items.slice();
    if (itemsTemp.length > 0) this.existData = true;
    return itemsTemp.sort((a, b) => {
      return this.orderItems(a, b, this.orderByField, this.direction);
    });
  }

  orderItems(a: Record<string, any>, b: Record<string, any>, fieldKey: string, direction: boolean): number {
    if (fieldKey == undefined || fieldKey == "") {
      return 0;
    }
    let n1 = a[`${fieldKey}`];
    let n2 = b[`${fieldKey}`];

    if (Number.isNaN(n1)) {
      n1 = Number(n1);
    }

    if (Number.isNaN(n2)) {
      n2 = Number(n2);
    }
    if (n1 > n2) {
      return direction ? 1 : -1;
    }
    if (n1 < n2) {
      return direction ? -1 : 1;
    }
    return 0;
  }

  get fields(): FieldPTableExtendType[] {
    if (this.value.fields == undefined) return [];
    else return this.value.fields.filter((fie: FieldPTableExtendType) => fie?.show);
  }

  private getDefaultValues(): PTableExtendType {
    return {
      fields: [],
    };
  }

  public refreshData(rows: Record<string, unknown>[]): void {
    this.ptableMaker.refreshDataSource(rows);
  }
}
export default PTableExtend;
