import Pageable from "@/entities/pageable/pageable_collection";
import { SolutionHistory } from "@/entities/solution-history";
import { Filter } from "../backend.types";

export interface SolutionHistoryService {
  fetchSolutionFileHistory(map: Record<string, unknown>): Promise<Pageable<SolutionHistory>>;
  downloadSolutionFileHistory(
    sortField: [],
    filters: Filter[],
    fields: { field: string; label: string }[]
  ): Promise<File>;
}

export interface ISolutionHistoryFilter {
  text?: string;
  type?: string;
  country?: string;
  startDate: Date;
  endDate: Date;
}

export const iniSolutionHistoryFilter: ISolutionHistoryFilter = {
  text: "",
  type: "",
  country: "",
  startDate: new Date(new Date().setHours(0, 0, 0, 0)),
  endDate: new Date(new Date().setHours(23, 59, 59, 999)),
};

export interface SolutionHistoryServiceMock {
  fetchSolutionFileHistory: jest.Mock<Promise<Pageable<SolutionHistory>>>;
  downloadSolutionFileHistory: jest.Mock<Promise<File>>;
}
