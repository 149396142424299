
import { Component, Vue, Prop } from "vue-property-decorator";
import { i18n } from "@/i18n";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import Condition from "./condition/Condition.vue";
import { PModal, PButton } from "@/common/components";
import { Rule, User } from "@/entities";
import { EventBus } from "@/event-bus";
import { Getter } from "vuex-class";

@Component({
  name: "conditions-container",
  i18n,
  components: {
    PIcon,
    PModal,
    PButton,
    Condition,
  },
})
export class ConditionsContainer extends Vue {
  @Prop({ required: true }) rules!: Rule[];
  @Getter("getLoggedUser") getLoggedUser!: User;

  showDeleteAllConditionsModal = false;
  deleteAllConditionsModalTitle = this.$i18n.t("groups.permissions.delete_rule_confirmation");

  editRules(): void {
    this.$emit("launchWizard");
  }

  parseRule(field: string) {
    if (field.match(/country/gi)) {
      return "Country";
    }
    if (field.match(/delegation/gi)) {
      return "Delegation";
    }
    if (field.match("^entity.*")) {
      return "Entity";
    }
    if (field.match("^center.*")) {
      return "Center";
    }
    if (field.match("^servicePointEarlyValue.*")) {
      return "Fecha Valor";
    }
    if (field.match("^servicePoint.*")) {
      return "ServicePoint";
    }
    if (field.match("^device.*")) {
      return "Device";
    }
    return field;
  }

  parseValue(text: string): string {
    let value = text.split(";")[0];
    if (value.split(",").length > 1) {
      value = value.split(",")[1];
    }

    return value.length > 10 ? value.substring(0, 20) + "..." : value;
  }

  deleteAllAutomaticRules(): void {
    EventBus.$emit("delete-all-automatic-rules");
  }
  canEdit(rule: Rule): boolean {
    return this.getLoggedUser.corporateAdmin || !rule.fieldName.includes("country");
  }

  openDeleteAllConditionsModal(): void {
    this.showDeleteAllConditionsModal = true;
  }

  closeDeleteAllConditionsModal(): void {
    this.showDeleteAllConditionsModal = false;
  }
}
export default ConditionsContainer;
