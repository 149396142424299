
import { PFormInputText, PDropDownContainer, PFormSelect } from "@/common/components";
import { Component, Vue } from "vue-property-decorator";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import UserDropDownGroup from "./UserDropDownGroup.vue";

@Component({
  name: "user-group-filter",
  components: {
    PRow,
    PIcon,
    PFormInputText,
    PDropDownContainer,
    PFormSelect,
    UserDropDownGroup,
  },
})
export default class UserGroupFilter extends Vue {
  filter(value: string): void {
    this.$emit("filter", "group", value);
  }

  reset(): void {
    this.$emit("reset", "group");
  }
}
