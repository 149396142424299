import { RawPoint } from "@/entities";

export class Point {
  id: string;
  entity: string;
  center: number;
  code: string;
  name: string;

  constructor(data: RawPoint) {
    this.id = data.id || "";
    this.entity = data.entity || "";
    this.center = data.center || 0;
    this.code = data.code || "";
    this.name = data.name || "";
  }
}
