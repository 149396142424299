
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PFormDatepicker from "./PFormDatepicker.vue";
import { format } from "date-fns";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
@Component({
  components: {
    PFormDatepicker,
    PDropDownContainer,
  },
})
export class PFormDatepickerActions extends Vue {
  @Prop({ required: true }) value!: Date;
  @Prop({ required: false, default: "default" }) variant!: string; //default/filter
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.date = this.value;
    this.showLabelVarientLine();
  }
  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: undefined }) startSelector!: Date | undefined;
  @Prop({ required: false, default: undefined }) endSelector!: Date | undefined;
  @Prop({ required: false, default: "" }) label!: string;

  pussOption = false;
  date: Date | null = null;
  showLabel = false;

  mounted(): void {
    if (this.variant != "line") {
      this.showLabel = true;
    }
  }

  get inputClassComp(): string {
    return "inputDatepickerInterval" + "_" + this.variant;
  }
  get dateInput(): string {
    if (this.date) {
      this.pussOption = false;
      return format(new Date(this.date), "dd/MM/yyyy");
    } else {
      return "";
    }
  }

  showLabelVarientLine(): void {
    if (this.variant === "line") {
      if (this.date) {
        this.showLabel = true;
      } else {
        this.showLabel = false;
      }
    }
  }
  input(): void {
    this.showLabelVarientLine();
    this.$emit("input", this.date);
    this.$emit("change", this.date);
  }
  mostrarDatepickerInterval(): void {
    this.pussOption=true;
    if (!this.readonly) {
      this.pussOption = !this.pussOption;
    }
  }
}
export default PFormDatepickerActions;
