
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "support-tabs",
  components: {},
})
export class SupportTabs extends Vue {
  titleCashToday = "Incidencias";
  titleRegisterSolution = "Operaciones masivas";

  cashGridShowContent(value: string): void {
    this.titleCashToday = "Cash Today (" + value + ")";
  }
}
export default SupportTabs;
