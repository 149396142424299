import { render, staticRenderFns } from "./PFormSelectMultiSearch.vue?vue&type=template&id=2b61be0a&scoped=true&"
import script from "./PFormSelectMultiSearch.vue?vue&type=script&lang=ts&"
export * from "./PFormSelectMultiSearch.vue?vue&type=script&lang=ts&"
import style0 from "./PFormSelectMultiSearch.vue?vue&type=style&index=0&id=2b61be0a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b61be0a",
  null
  
)

export default component.exports