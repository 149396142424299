import { Context, IActions } from "./actions.types";
import { Country, User } from "@/entities";
import { i18n } from "@/i18n";

export const actions: IActions = {
  // Root
  async initialize(context: Context): Promise<void> {
    const isLoggedIn = await this.$services.auth.getSignedIn();
    if (isLoggedIn) {
      await Promise.all([context.dispatch("fetchLoggedUser"), context.dispatch("updateLoggedUser")]).finally(
        async () => {
          const user: User | undefined = context.state.loggedUser;
          if (user) {
            if (user.corporateAdmin) {
              await context.dispatch("fetchCountries");
            } else {
              await context.dispatch("fetchCountriesForNotCorporateAdmin");
            }
            context.commit("SET_IS_SIGNED_IN", true);
          }
        }
      );
    }
  },

  setLocale(context: Context, locale: string) {
    context.commit("SET_LOCALE", locale);
  },

  setUrl(context: Context, url: string) {
    context.commit("SET_URL", url);
  },
  // Auth
  setSignedIn(context: Context, isSignedIn: boolean) {
    context.commit("SET_IS_SIGNED_IN", isSignedIn);
  },

  // User
  async fetchLoggedUser(context: Context): Promise<User> {
    const data = await this.$services.user.fetchMe();
    context.commit("SET_LOGGED_USER", data);
    return data;
  },
  // User
  async updateLoggedUser(): Promise<void> {
    await this.$services.user.updateMe();
  },

  // Country
  async fetchCountries(context: Context): Promise<void> {
    const data = await this.$services.country.fetchCountries();
    context.commit("SET_COUNTRIES", data.countries);
  },

  async fetchCountriesForNotCorporateAdmin(context: Context): Promise<void> {
    const user = await this.$services.user.fetchMe();
    const countryList: Country[] = [];
    if (user.assignedCountries) {
      user.assignedCountries?.forEach((countryCode) => {
        countryList.push(
          new Country({
            name: i18n.t("country." + countryCode.toLowerCase()) as string,
            isoCode: countryCode,
          })
        );
      });
    } else {
      countryList.push(
        new Country({
          name: i18n.t("country." + user.country.toLowerCase()) as string,
          isoCode: user.country,
        })
      );
    }
    countryList.sort((a, b) => a.name.localeCompare(b.name));
    context.commit("SET_COUNTRIES", countryList);
  },
};
