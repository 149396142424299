import { RawCountry } from "@/entities";

export class Country {
  readonly isoCode: string;
  readonly name: string;

  constructor(data: RawCountry) {
    this.isoCode = data.isoCode || "";
    this.name = data.name || "";
  }
}
