import { RawGroup } from "@/entities";
import { AssignedServicePoint } from "@/entities/assignedServicePoint";

export class Group {
  readonly id: string;
  readonly aggregate_id: string;
  readonly name: string;
  readonly description: string;
  readonly members: number;
  readonly lastUpdate: string;
  assignedServicePoints: AssignedServicePoint[];
  constructor(data: RawGroup) {
    this.id = data.id || "";
    this.aggregate_id = data.aggregate_id || "";
    this.name = data.name || "";
    this.description = data.description || "";
    this.members = data.members.length || 0;
    this.lastUpdate = data.lastUpdate || "";
    this.assignedServicePoints = data.assignedServicePoints
      ? data.assignedServicePoints.map((value) => new AssignedServicePoint(value))
      : [];
  }

  private padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  private formatDate(date: Date): string {
    return (
      [date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())].join("-") +
      " " +
      [this.padTo2Digits(date.getHours()), this.padTo2Digits(date.getMinutes())].join(":")
    );
  }
}
