
import { Component, Vue, Prop } from "vue-property-decorator";
import { PRow, PCol } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  name: "no-data",
  components: {
    PRow,
    PCol,
  },
})
export class NoData extends Vue {
  @Prop({ required: false, default: "empty" }) keyTranslator!: string;
  @Prop({ required: false, default: "noData" }) variant!: string;
}
export default NoData;
