import {
  Machine,
  MachineDetail,
  MachineServicePoint,
  MachineType,
  Manufacturer,
  RawMachine,
  RawMachineDetail,
  RawMachineType,
  RawManufacturer,
} from "@/entities";
import Vue from "vue";
import { BACKEND_BASE_URL, BACKEND_BASE_URL_READS } from "../backend.types";
import { RawMachineServicePoint } from "@/entities/machine-service-point/machineServicePoint.types";
import Pageable from "@/entities/pageable/pageable_collection";
import { MachineService } from "./machine.types";
import { Delegation, RawDelegation } from "@/entities/delegation";
import { AxiosRequestConfig } from "axios";
import { RawPage } from "@/entities/pageable/pageable_types";

export class MachineServiceImpl implements MachineService {
  async fetchMachines(map: Record<string, unknown>): Promise<Pageable<Machine>> {
    const { data } = await Vue.$axios.post<RawPage<RawMachine>>(`${BACKEND_BASE_URL_READS}/api/v1/me/devices`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawMachine) => new Machine(rawMachine)) ?? [],
    };
  }

  async fetchMachineServicePoint(deviceId: string): Promise<Pageable<MachineServicePoint>> {
    const { data } = await Vue.$axios.get<RawPage<RawMachineServicePoint>>(
      `${BACKEND_BASE_URL_READS}/api/v1/devices/${deviceId}/servicepoints`
    );
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawMachineServicePoint) => new MachineServicePoint(rawMachineServicePoint)) ?? [],
    };
  }

  async fetchManufacturers(): Promise<Manufacturer[]> {
    const { data } = await Vue.$axios.get<RawManufacturer[]>(`${BACKEND_BASE_URL_READS}/api/v1/manufacturers`);
    return data.map((rawManufacturer) => new Manufacturer(rawManufacturer));
  }

  async fetchTypes(): Promise<MachineType[]> {
    const { data } = await Vue.$axios.get<RawMachineType[]>(`${BACKEND_BASE_URL_READS}/api/v1/devicetypes`);
    return data.map((RawMachineType) => new MachineType(RawMachineType));
  }

  async fetchDelegations(): Promise<Delegation[]> {
    const { data } = await Vue.$axios.get<RawDelegation[]>(`${BACKEND_BASE_URL_READS}/api/v1/delegations`);
    return data.map((rawDelegation) => new Delegation(rawDelegation));
  }

  async fetchDelegationSearch(map: Record<string, unknown>): Promise<Pageable<Delegation>> {
    const { data } = await Vue.$axios.post<RawPage<RawDelegation>>(`${BACKEND_BASE_URL_READS}/api/v1/delegations`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawDelegation) => new Delegation(rawDelegation)) ?? [],
    };
  }

  async fetchMachinesByAutomaticRules(map: Record<string, unknown>): Promise<Pageable<MachineDetail>> {
    const { data } = await Vue.$axios.post<RawPage<RawMachineDetail>>(
      `${BACKEND_BASE_URL}/api/v1/devices-by-automatic-rules`,
      map
    );
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawMachineDetail) => new MachineDetail(rawMachineDetail)) ?? [],
    };
  }

  async fetchMachinesByManualRules(map: Record<string, unknown>): Promise<Pageable<MachineDetail>> {
    const { data } = await Vue.$axios.post<RawPage<RawMachineDetail>>(
      `${BACKEND_BASE_URL}/api/v1/devices-by-manual-rules`,
      map
    );
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawMachineDetail) => new MachineDetail(rawMachineDetail)) ?? [],
    };
  }

  async createMachine(file: FormData): Promise<any> {
    return await Vue.$axios.post(`${BACKEND_BASE_URL}/api/v1/solutions`, file, {
      headers: { "Content-Type": "x-www-urlencoded" },
    });
  }

  async downloadSolutionTemplate(): Promise<File> {
    const url = `${BACKEND_BASE_URL_READS}/api/v1/solutions/template-download`;
    const responseType: AxiosRequestConfig = { responseType: "blob" };
    const response = await Vue.$axios.post(url, {}, responseType);
    const contentType = response.headers["content-type"];
    const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
    const blob = new Blob([response.data as BlobPart], {
      type: contentType,
    });
    return new File([blob], filename);
  }
}
