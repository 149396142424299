
import { Component, Vue } from "vue-property-decorator";
import { PModal, PButton } from "@/common/components";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { i18n } from "@/i18n";

@Component({
  name: "support-panel-alert",
  i18n,
  components: {
    PModal,
    PButton,
    PIcon,
    PRow,
  },
})
export class SupportPanelAlert extends Vue {
  showalert = false;
  message = "";
  showModal = false;
  title = "Cambio de estado";
  link = "link";
  done = this.$i18n.t("status.done");
  pending = this.$i18n.t("status.pending");
  inprogress = this.$i18n.t("status.inprogress");
  selected = this.inprogress;

  showAlert(): void {
    this.showalert = true;
    this.showModal = false;
    this.$emit("ShowAlert");
  }
  changeStatus(): void {
    this.showModal = true;
    this.$emit("ShowModal");
  }
  closeAlert(): void {
    this.showalert = false;
    this.showModal = false;
    this.$emit("CloseAlert");
    this.selected = this.inprogress;
  }
  closeModal(): void {
    this.showModal = false;
    this.$emit("CloseModal");
    this.selected = this.inprogress;
  }

  filter(): void {
    alert("Se ha cambiado el estado a " + this.selected);
    this.showalert = false;
    this.showModal = false;
    this.$emit("CloseAlert");
    this.selected = this.inprogress;
  }
}
export default SupportPanelAlert;
