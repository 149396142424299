import { RawRule, Rule } from "@/entities";

export const mockRawRules = (): RawRule[] => [
  {
    id: "1",
    fieldName: "country",
    fieldOperator: "equals",
    fieldValue: "ES",
    ruleOperator: "OR",
    fieldType: "string",
    showValue: "",
  },
];

export const mockRules = (data: RawRule[] = mockRawRules()): Rule[] => data.map((item) => new Rule(item));
