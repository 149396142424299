import { render, staticRenderFns } from "./SupportPanelHeader.vue?vue&type=template&id=3640aab3&scoped=true&"
import script from "./SupportPanelHeader.vue?vue&type=script&lang=ts&"
export * from "./SupportPanelHeader.vue?vue&type=script&lang=ts&"
import style0 from "./SupportPanelHeader.vue?vue&type=style&index=0&id=3640aab3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3640aab3",
  null
  
)

export default component.exports