import { MachineServiceMock } from "./machine.types";

export const mockMachineService = (): MachineServiceMock => ({
  fetchMachines: jest.fn(),
  fetchManufacturers: jest.fn(),
  fetchTypes: jest.fn(),
  fetchDelegations: jest.fn(),
  fetchDelegationSearch: jest.fn(),
  fetchMachineServicePoint: jest.fn(),
  fetchMachinesByAutomaticRules: jest.fn(),
  createMachine: jest.fn(),
  downloadSolutionTemplate: jest.fn(),
  fetchMachinesByManualRules: jest.fn(),
});
