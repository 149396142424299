import { RawMachineType } from "./machinetype.type";

export class MachineType {
  readonly id: string;
  readonly name: string;

  constructor(data: RawMachineType) {
    this.id = data.id || "";
    this.name = data.name || "";
  }
}
