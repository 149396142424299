import { SupportTransaction } from "@/entities";
import { IRequestServicePointFilter } from "@/services";

export class Utilities {
  public static searchByKey(key: string, search: string, items: Record<string, unknown>[]): Record<string, unknown>[] {
    try {
      return items.filter((item: Record<string, unknown>) => {
        let itemKey = item[`${key}`] as string;
        let searchKey = search;
        itemKey = itemKey.toString().toLowerCase();
        searchKey = searchKey.toString().toLowerCase();
        return itemKey.includes(searchKey);
      });
    } catch (error) {
      return [];
    }
  }

  public static getImageByUri(url: string): string {
    try {
      const images = require.context("@/assets/icons/", false, /\.png$/);
      return images("./" + url + ".png");
    } catch (error) {
      return "";
    }
  }

  public static pageable(
    limit?: number | null,
    offset?: number | null,
    sortField?: string | null,
    sortWay?: string | null,
    filters?: Record<string, unknown>[]
  ): Record<string, unknown> {
    const pagination: Record<string, unknown> = {};
    pagination["limit"] = limit || 300;
    pagination["offset"] = offset || 0;
    pagination["sortField"] = sortField || "id";
    pagination["sortWay"] = sortWay || "ASC";
    pagination["filters"] = filters || [];
    return pagination;
  }

  public static pageableServicePoint(
    limit?: number | null,
    offset?: number | null,
    sortField?: string | null,
    sortWay?: string | null,
    filters?: IRequestServicePointFilter | null
  ): Record<string, unknown> {
    const pagination: Record<string, unknown> = {};
    pagination["limit"] = limit || 300;
    pagination["offset"] = offset || 0;
    pagination["filters"] = filters || {};
    pagination["sortFields"] = [
      {
        field: sortField || "id",
        direction: sortWay || "ASC",
      },
    ];
    return pagination;
  }

  public static sort(
    a: Record<string, unknown>,
    b: Record<string, unknown>,
    fieldKey: string,
    direction: boolean
  ): number {
    if (fieldKey == undefined || fieldKey == "") {
      return 0;
    }
    let n1: any = a[`${fieldKey}`];
    let n2: any = b[`${fieldKey}`];

    if (Number.isNaN(n1)) {
      n1 = Number(n1);
    }

    if (Number.isNaN(n2)) {
      n2 = Number(n2);
    }
    if (n1 > n2) {
      return direction ? 1 : -1;
    }
    if (n1 < n2) {
      return direction ? -1 : 1;
    }
    return 0;
  }

  public static rawTransactionToTransaction(results: SupportTransaction[]): Record<string, unknown>[] {
    const transactions: SupportTransaction[] = [];
    results.forEach((result) => transactions.push(result));
    return transactions.map((transaction) => JSON.parse(JSON.stringify(transaction)));
  }

  public static isObjectEmpty(object: any): boolean {
    if (object.constructor === Object) {
      return Object.values(object).every((item: any) => {
        return item === null || item === undefined || item === "" || (item.constructor === Array && item.length === 0);
      });
    } else {
      return (
        object === null ||
        object === undefined ||
        object === "" ||
        (object.constructor === Array && object.length === 0)
      );
    }
  }
}

export default Utilities;
