import { Center } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";

export interface CenterService {
  fetchCenters(map: Record<string, unknown>): Promise<Pageable<Center>>;
  fetchCentersByEntityId(entityIs: string, map: Record<string, unknown>): Promise<Pageable<Center>>;
  uploadImage(entityId: string, image: FormData): Promise<void>;
  deleteImage(entityId: string): Promise<void>;
}

export interface ICenterFilter {
  name?: string;
  code: string;
}

export const iniCenterFilter: ICenterFilter = {
  name: "",
  code: "",
};

export interface CenterServiceMock {
  fetchCenters: jest.Mock<Promise<Pageable<Center>>>;
  fetchCentersByEntityId: jest.Mock<Promise<Pageable<Center>>>;
  uploadImage: jest.Mock<Promise<void>>;
  deleteImage: jest.Mock<Promise<void>>;
}
