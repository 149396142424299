
import { Vue, Component, Prop } from "vue-property-decorator";
import { PButton } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { TableHtml } from "@/components/commons";

@Component({
  name: "grid-alert",
  components: {
    PIcon,
    PButton,
  },
})
export class PGridAlert extends Vue {
  @Prop({ required: true }) elementsSelected!: any[];
  @Prop({ required: true }) messageButton!: string;
  @Prop({ required: true }) messageSingleSelect!: string;
  @Prop({ required: true }) messageMultiSelect!: string;
  @Prop({ required: true }) messageTitleConfirm!: string;
  @Prop({ required: true }) messageTitleTable!: string[]; //maximo 2 elementos
  @Prop({ required: true }) propertiesTable!: string[]; //maximo 2 elementos

  hasElementsSelected(): boolean {
    return this.elementsSelected.length > 0;
  }

  doAction(): void {
    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
        cancelButton: "swal2_prosegur_cancel",
      },
      showCancelButton: true,
      cancelButtonText: this.$t("users.user_alert.cancel-button") as string,
      html: this.renderMessage(this.messageTitleConfirm, this.elementsSelected),
    }).then(async (response) => {
      if (response.isConfirmed) {
        this.$emit("confirm");
      }
    });
  }

  private renderMessage(title: string, elements: any[]): string {
    const headers: string[] = this.messageTitleTable;
    if(this.propertiesTable.length>1) {
      type DataTable = { col1: string; col2: string }
      const dataMap: DataTable[] = elements.map<DataTable>((element) => {
        return {col1: element[this.propertiesTable[0]] as string, col2: element[this.propertiesTable[1]] as string};
      });
      const htmlTable: TableHtml<DataTable> = new TableHtml<DataTable>(headers, dataMap, title);
      htmlTable.buildTable("user");
      return htmlTable.getTable();
    }
    else{
      type DataTable = { col1: string}
      const dataMap: DataTable[] = elements.map<DataTable>((element) => {
        return {col1: element[this.propertiesTable[0]] as string};
      });
      const htmlTable: TableHtml<DataTable> = new TableHtml<DataTable>(headers, dataMap, title);
      htmlTable.buildTable("user");
      return htmlTable.getTable();
    }
  }

  closeAlert(): void {
    this.$emit("close-alert");
  }
}
export default PGridAlert;
