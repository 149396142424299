
import { Component, Vue } from "vue-property-decorator";
import { i18n } from "@/i18n";
import PButton from "@/common/components/look/PButton/PButton.vue";
import PModal from "@/common/components/look/PModal/PModal.vue";
import PFormInputText from "@/common/components/look/PFormInput/PFormInputText.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  name: "machine-import-new-modal",
  i18n,
  components: {
    PIcon,
    PModal,
    PFormInputText,
    PButton,
  },
})
export class MachineImportNewModal extends Vue {
  file: any = "";
  showModal = false;
  title = this.$i18n.t("machine.solution-register-modal.title");
  labelName = this.$i18n.t("machine.solution-register-modal.label_name");
  secondary = "secondary";
  isDisabled = true;
  fileName = "";

  openModal(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
    this.fileName = "";
  }

  clear(): void {
    this.showModal = false;
    this.isDisabled = true;
    this.fileName = "";
  }

  async handlingFileCSV() {
    this.isDisabled = false;
    this.fileName = this.file.name;
  }

  async uploadCSV() {
    const date = new Date();
    const formData = new FormData();
    formData.append("date", date.toLocaleString());
    formData.append("file", this.file);
    await this.$services.machine
      .createMachine(formData)
      .then((resp: any) => {
        if (resp.status == 201) {
          Vue.swal({
            icon: "success",
            title: "Archivo cargado exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Vue.swal({
            icon: "warning",
            title: "Error al cargar archivo",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      })
      .then(() => this.$emit("refresh"))
      .then(() => this.closeModal())
      .catch((error) => {
        console.log("error", error);
      });
  }

  async downloadTemplate() {
    await this.$services.machine.downloadSolutionTemplate().then((file: File) => {
      const aElement = document.createElement("a");
      const url = URL.createObjectURL(file);
      aElement.href = url;
      aElement.download = file.name;
      aElement.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
export default MachineImportNewModal;
