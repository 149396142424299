import { RawManufacturer } from "./manufacturer.type";
import { Manufacturer } from "./manufacturer";

export const mockRawManufacturer = (): RawManufacturer[] => [
  {
    id: "0fb3ee2a-651f-33a6-9a07-318e7313b1dd",
    name: "ACR",
  },
  {
    id: "1fc5efe0-2098-3df2-813a-97ce9f2f586f",
    name: "BCC",
  },
];

export const mockManufacturer = (data: RawManufacturer[] = mockRawManufacturer()): Manufacturer[] =>
  data.map((item) => new Manufacturer(item));
