import { RawManufacturer } from "./manufacturer.type";

export class Manufacturer {
  readonly id: string;
  name: string;

  constructor(data: RawManufacturer) {
    this.id = data.id || "";
    this.name = data.name || "";
  }
}
