
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { i18n } from "@/i18n";
import { GroupAddMember } from "@/components/groups/group-filter-wrapper/group-add-member";
import { iniUserFilter, IUserFilter } from "@/services";
import { EventBus } from "@/event-bus";

@Component({
  name: "group-filter-wrapper",
  i18n,
  components: {
    GroupAddMember,
  },
})
export class GroupFilterWrapper extends Vue {
  @Prop({ required: true }) groupId!: string;
  @Prop({ required: true }) value!: IUserFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Watch("usersToAddList", { immediate: true, deep: true })
  onChangeUsersToAddList(): void {
    EventBus.$emit("updateUsersCount", this.usersToAddList.length);
  }

  valueLocal: IUserFilter = { ...iniUserFilter };
  added = this.$i18n.t("groups.users_added");
  usersToAddList: Record<string, unknown>[] = [];

  cancelMember(): void {
    EventBus.$emit("cancel-users", this.usersToAddList);
    this.usersToAddList = [];
  }

  saveMember(): void {
    this.$services.userGroup
      .addMembers(
        this.usersToAddList.map((user) => user.id as string),
        this.groupId
      )
      .then(() =>
        Vue.swal({
          icon: "success",
          title: this.added,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.search();
          this.usersToAddList = [];
        })
      );
  }

  search(): void {
    this.$emit("input", this.valueLocal);
  }

  getUsers(users: Record<string, unknown>[]): void {
    this.usersToAddList = [...this.usersToAddList, ...users];
  }
}
export default GroupFilterWrapper;
