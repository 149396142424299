
import { PFormInputText, PDropDownContainer, PButton, SelectType, PFormSelect } from "@/common/components";
import { Component, Vue } from "vue-property-decorator";
import { Country } from "@/entities";
import { Getter } from "vuex-class";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  name: "user-drop-down-country",
  components: {
    PRow,
    PButton,
    PFormInputText,
    PDropDownContainer,
    PFormSelect,
    PIcon,
  },
})
export class EntityDropDownCity extends Vue {
  @Getter("getCountries") getCountries!: Country[];
  filterProperties: SelectType[] = [];
  city = "";
  selectItemFilterPropertie = "";
  label_country = "País";
  label_city = "Ciudad";
  isEnabled = false;
  link = "link";

  created(): void {
    this.getCountries.forEach((it: Country) =>
      this.filterProperties.push({ text: it.name.toString(), value: it.isoCode.toString() })
    );
  }

  setItem(item: string): void {
    this.selectItemFilterPropertie = item;
  }

  setCity(city: string): void {
    this.city = city;
  }

  reset(): void {
    this.selectItemFilterPropertie = this.$t("entity.filter.country").toString();
    this.city = "";
    this.$emit("clear-filter-by-country", this.selectItemFilterPropertie, this.city);
  }

  filter(): void {
    this.$emit("filter-by-country-user", this.selectItemFilterPropertie, this.city);
  }
}
export default EntityDropDownCity;
