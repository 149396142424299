
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "group-last-update",
})
export class GroupLastUpdate extends Vue {
  @Prop({ required: false, default: "" }) lastUpdate!: string;

  formatDate(): string {
    const newDate = new Date(this.lastUpdate).toLocaleTimeString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    const newDate2 = newDate.replace(",", "");
    return newDate2;
  }
}

export default GroupLastUpdate;
