import { RawMachineServicePoint } from "@/entities";

export class MachineServicePoint {
  readonly id: string;
  readonly servicePoint: string;
  readonly center: string;
  readonly solutionType: string;
  readonly logicMachinesCount: number;

  constructor(data: RawMachineServicePoint) {
    this.id = data.id || "";
    this.servicePoint = data.servicePoint || "";
    this.center = data.center || "";
    this.solutionType = data.solutionType || "";
    this.logicMachinesCount = data.logicCashTodayCount || 0;
  }
}
