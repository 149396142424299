import { render, staticRenderFns } from "./CenterHeader.vue?vue&type=template&id=fbcb7ee6&scoped=true&"
import script from "./CenterHeader.vue?vue&type=script&lang=ts&"
export * from "./CenterHeader.vue?vue&type=script&lang=ts&"
import style0 from "./CenterHeader.vue?vue&type=style&index=0&id=fbcb7ee6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbcb7ee6",
  null
  
)

export default component.exports