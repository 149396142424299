import { SupportTransaction, mockRawSupportTransaction, mockSupportTransactions } from "@/entities";

export class SupportTransactionServiceImpl {
  async fetchTransactions(): Promise<SupportTransaction[]> {
    return mockSupportTransactions();
  }

  async fetchEntityIds(): Promise<string[]> {
    const entityId = new Set<string>();
    mockRawSupportTransaction().forEach((element) => {
      entityId.add(element.entityId);
    });

    return Array.from(entityId);
  }

  async fetchDeviceIds(): Promise<string[]> {
    const deviceId = new Set<string>();
    mockRawSupportTransaction().forEach((element) => {
      deviceId.add(element.deviceId);
    });

    return Array.from(deviceId);
  }

  async fetchStatus(): Promise<string[]> {
    const status = new Set<string>();
    mockRawSupportTransaction().forEach((element) => {
      status.add(element.status);
    });

    return Array.from(status);
  }

  async fetchActualIds(): Promise<string[]> {
    const actualId = new Set<string>();
    mockRawSupportTransaction().forEach((element) => {
      actualId.add(element.actualId);
    });

    return Array.from(actualId);
  }
}
