import Vue from "vue";
import { Country, RawUserCountries, UserCountries } from "@/entities";
import { CountryService } from "./country.types";
import { BACKEND_BASE_URL_READS } from "../backend.types";
import { i18n } from "../../../i18n";
export class CountryServiceImpl implements CountryService {
  async fetchCountries(): Promise<UserCountries> {
    const { data } = await Vue.$axios.get<RawUserCountries>(`${BACKEND_BASE_URL_READS}/api/v1/countries`);
    const sortedCountries = this.sortCountries(data);
    return new UserCountries({ countries: sortedCountries });
  }

  async fetchCountriesByName(nameSearch: string): Promise<UserCountries> {
    const { data } = await Vue.$axios.get<RawUserCountries>(`${BACKEND_BASE_URL_READS}/api/v1/countries/search`, {
      params: {
        name: nameSearch,
      },
    });
    const sortedCountries = this.sortCountries(data);
    return new UserCountries({ countries: sortedCountries });
  }

  private sortCountries(data: RawUserCountries): Country[] {
    const countries: Country[] = data.countries.map((rawCountry) => {
      const translationLabel = `country.${rawCountry.isoCode.toLowerCase()}`;
      return new Country({ isoCode: rawCountry.isoCode, name: i18n.t(translationLabel).toString() });
    });
    return countries.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else return 0;
    });
  }
}
