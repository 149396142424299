
import { Component, Vue } from "vue-property-decorator";
import { PTabs, PTab } from "@/common/components";

@Component({
  name: "center-tabs",
  components: {
    PTabs,
    PTab,
  },
})
export class CenterTabs extends Vue {}
export default CenterTabs;
