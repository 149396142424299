import { Locality, RawLocality } from "@/entities";

export const mockRawLocalities = (): RawLocality[] => {
  return [
    {
      id: "be89ee31-e052-4a19-ab7d-e399e87c1ca8",
      name: "Quilmes", //Argentina
    },
    {
      id: "7d65854b-9964-467b-90e3-5bedc92e3cbb",
      name: "CABA", //Argentina
    },
    {
      id: "6a5e2b9a-c581-11ec-9d64-0242ac120002",
      name: "Campo Real", //Madrid
    },
    {
      id: "8c087aa2-c581-11ec-9d64-0242ac130007",
      name: "Venturada", //Madrid
    },
  ];
};

export const mockLocality = (data: RawLocality[] = mockRawLocalities()): Locality[] =>
  data.map((item) => new Locality(item));
