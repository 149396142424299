
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UserSearch from "./user-search/UserSearch.vue";
import UserCountry from "./user-country/UserCountry.vue";
import UsersAll from "./users-all/UsersAll.vue";
import UserGroupFilter from "./user-group/UserGroupFilter.vue";
import { iniUserFilter, IUserFilter } from "@/services";
import { Getter } from "vuex-class";
import { Country, User } from "@/entities";
import { PButton, PFormInputTextSearch, PFormSelect, SelectType } from "@/common/components";

@Component({
  name: "user-filter-wrapper",
  components: {
    UserSearch,
    UserCountry,
    UsersAll,
    UserGroupFilter,
    PFormSelect,
    PFormInputTextSearch,
    PButton,
  },
})
export class UserFilterWrapper extends Vue {
  @Prop({ required: true }) value!: IUserFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Getter("getCountries") getCountries!: Country[];
  @Getter("getLoggedUser") getLoggedUser!: User;

  isBtnSearchDisabled = true;
  valueLocal: IUserFilter = { ...iniUserFilter };
  userTypes: SelectType[] = [
    { text: "Prosegur", value: "Prosegur" },
    { text: "Customer", value: "Customer" },
  ];
  groups: SelectType[] = [];
  userStatus: SelectType[] = [
    { text: this.$t("status.done") as string, value: "Done" },
    { text: this.$t("status.pending") as string, value: "Pending" },
  ];

  mounted(): void {
    this.searchGroups();
  }

  async searchGroups(): Promise<void> {
    const data = await this.$services.group.fetchGroups();
    if (data) {
      const options = data.results;
      options?.forEach((option) => {
        this.groups.push({ text: option.name as string, value: option.name as string });
      });
    }
  }

  enableBtnSearch(): void {
    const hasValidText = this.valueLocal.text && this.valueLocal.text.length >= 3;
    const hasType = !!this.valueLocal.type;
    const hasStatus = !!this.valueLocal.status;
    const hasGroup = !!this.valueLocal.group;
    const hasCountry = !!this.valueLocal.country;
    this.isBtnSearchDisabled = !(hasValidText || hasType || hasStatus || hasGroup || hasCountry);
  }

  search(): void {
    if (!this.isBtnSearchDisabled) {
      this.$emit("input", this.valueLocal);
    }
  }
}
export default UserFilterWrapper;
