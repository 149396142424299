import { RawEntity, Entity } from "@/entities";

export const mockRawEntities = (): RawEntity[] => [
  {
    id: "7741e61e-30ae-42c5-bba6-23225f9258e0",
    name: "Zara ES",
    code: "ES0100000001",
    imageLink: "icon-zara",
    centerCount: 164,
    servicePointCount: 200,
    logicCashTodayCount: 3000,
    customerCount: 234,
    countryCode: "ES",
  },
  {
    id: "71c365bc-928f-4aae-9a9e-3425d5a7ecfa",
    name: "Cencosud AR",
    code: "ES0100000002",
    imageLink: "icon-zara",
    centerCount: 456,
    servicePointCount: 200,
    logicCashTodayCount: 3000,
    customerCount: 234,
    countryCode: "AR",
  },
  {
    id: "c5ab3a78-b40c-4713-a8d4-4ff08979d3e5",
    name: "Unicenter AR",
    code: "ES0100000003",
    imageLink: "icon-unicenter",
    centerCount: 364,
    servicePointCount: 300,
    logicCashTodayCount: 3000,
    customerCount: 334,
    countryCode: "AR",
  },
  {
    id: "e20bc433-3851-46fd-8757-37094e2913b3",
    name: "Coca-Cola",
    code: "ES0100000003",
    imageLink: "icon-zara",
    centerCount: 200,
    servicePointCount: 110,
    logicCashTodayCount: 100,
    customerCount: 244,
    countryCode: "ES",
  },
  {
    id: "e20bc433-3851-46fd-8757-37094e2913b3",
    name: "Coca-Cola",
    code: "ES0100000003",
    imageLink: "icon-zara",
    centerCount: 200,
    servicePointCount: 110,
    logicCashTodayCount: 100,
    customerCount: 244,
    countryCode: "CH",
  },
];

export const mockEntities = (data: RawEntity[] = mockRawEntities()): Entity[] => data.map((item) => new Entity(item));
