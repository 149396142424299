import { Center, Device, Entity, RawServicePoint } from "@/entities";

export class ServicePoint {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly earlyValue: boolean;
  readonly entityId: string;
  readonly centerId: string;
  readonly deviceId: string;
  readonly entity: Entity | null;
  readonly center: Center | null;
  readonly device: Device | null;

  constructor(data: RawServicePoint) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.code = data.code || "";
    this.earlyValue = data.earlyValue || false;
    this.entityId = data.entity ? data.entity.id : "";
    this.centerId = data.center ? data.center.id : "";
    this.deviceId = data.device ? data.device.id : "";
    this.entity = data.entity ? new Entity(data.entity) : null;
    this.center = data.center ? new Center(data.center) : null;
    this.device = data.device ? new Device(data.device) : null;
  }
}
