
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ICenterFilter, iniCenterFilter } from "@/services";

@Component({
  name: "center-filters",
  components: {},
})
export class CenterFilters extends Vue {
  @Prop({ required: true }) value!: ICenterFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  valueLocal: ICenterFilter = { ...iniCenterFilter };

  search(): void {
    this.$emit("input", this.valueLocal);
  }
}
export default CenterFilters;
