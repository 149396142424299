
import { Component, Vue } from "vue-property-decorator";
import { PDropDownContainer } from "@/common/components";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import SupportDropDownDeviceId from "./SupportDropDownDeviceId.vue";

@Component({
  name: "support-device-id-filter",
  components: {
    PRow,
    PIcon,
    PDropDownContainer,
    SupportDropDownDeviceId,
  },
})
export default class SupportDeviceIdFilter extends Vue {}
