import { RawGroup, Group } from "@/entities";

export const mockRawGroup = (): RawGroup[] => [
  {
    id: "63b15c17-1885-4891-8f1a-169cb7329770",
    aggregate_id: "63b15c17-1885-4891-8f1a-169cb7329770",
    name: "Prosegur-AR",
    members: Array.of(),
    description: "",
    lastUpdate: new Date().toISOString().slice(0, 10),
    assignedServicePoints: Array.of(),
  },
  {
    id: "e3f8bb58-ddcf-48b5-9330-66010e2cf752",
    aggregate_id: "e3f8bb58-ddcf-48b5-9330-66010e2cf752",
    name: "Zara",
    members: Array.of(),
    description: "",
    lastUpdate: new Date().toISOString().slice(0, 10),
    assignedServicePoints: Array.of(),
  },
  {
    id: "e6a9ca60-3ffg-87fd-hgyu-94635eae0146",
    aggregate_id: "e6a9ca60-3ffg-87fd-hgyu-94635eae0146",
    name: "Cencosud",
    members: Array.of(),
    description: "",
    lastUpdate: new Date().toISOString().slice(0, 10),
    assignedServicePoints: Array.of(),
  },
  {
    id: "efdhjs4-3c5e-3fvv-89ff-nxgf68rjfkri",
    aggregate_id: "efdhjs4-3c5e-3fvv-89ff-nxgf68rjfkri",
    name: "Starbucks",
    members: Array.of(),
    description: "",
    lastUpdate: new Date().toISOString().slice(0, 10),
    assignedServicePoints: Array.of(),
  },
  {
    id: "ncjd8rjg-8696-405f-8aa7-ksb47fh67if7",
    aggregate_id: "ncjd8rjg-8696-405f-8aa7-ksb47fh67if7",
    name: "McDonalds",
    members: Array.of(),
    description: "",
    lastUpdate: new Date().toISOString().slice(0, 10),
    assignedServicePoints: Array.of(),
  },
  {
    id: "jd84ut7e-8483-gh8d-8rr6-d968jf836dht",
    aggregate_id: "jd84ut7e-8483-gh8d-8rr6-d968jf836dht",
    name: "Prosegur ES",
    members: Array.of(),
    description: "",
    lastUpdate: new Date().toISOString().slice(0, 10),
    assignedServicePoints: Array.of(),
  },
];

export const mockGroup = (data: RawGroup[] = mockRawGroup()): Group[] => data.map((item) => new Group(item));
