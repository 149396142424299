import { RawCenter } from "@/entities";

export class Center {
  readonly id: string;
  readonly entityId: string;
  readonly code: string;
  readonly name: string;
  readonly address: string;
  readonly countryCode: string;
  readonly provinceName: string;
  readonly cityName: string;
  readonly zipCode: string;

  constructor(data: RawCenter) {
    this.id = data.id || "";
    this.entityId = data.entityId || "";
    this.code = data.code || "";
    this.name = data.name || "";
    this.address = data.address || "";
    this.countryCode = data.countryCode || "";
    this.provinceName = data.provinceName || "";
    this.cityName = data.cityName || "";
    this.zipCode = data.zipCode || "";
  }
}
