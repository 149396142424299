import { RawAssignedServicePoint } from "@/entities/assignedServicePoint/assignedServicePoint.types";
import { ServicePoint } from "@/entities";

export class AssignedServicePoint {
  readonly groupId: string;
  readonly servicePoint: ServicePoint;
  readonly associationType: string;
  constructor(data: RawAssignedServicePoint) {
    this.groupId = data.groupId || "";
    this.servicePoint = new ServicePoint(data.servicePoint);
    this.associationType = data.associationType || "";
  }
}
