import { GroupServiceMock } from "./group.types";

export const mockGroupService = (): GroupServiceMock => ({
  fetchGroup: jest.fn(),
  fetchGroups: jest.fn(),
  fetchUsersGroup: jest.fn(),
  createGroup: jest.fn(),
  updateGroupDescription: jest.fn(),
  renameGroup: jest.fn(),
  deleteGroup: jest.fn(),
  fetchAutomaticRules: jest.fn(),
  addRules: jest.fn(),
  deleteAutomaticCondition: jest.fn(),
  deleteAllAutomaticRules: jest.fn(),
  addCashTodays: jest.fn(),
});
