import Vue from "vue";
import { Currency, RawCurrency } from "@/entities";
import { CurrencyService } from "./currency.types";
import { BACKEND_BASE_URL_READS } from "../backend.types";

export class CurrencyServiceImpl implements CurrencyService {
  async fetchCurrencies(): Promise<Currency[]> {
    const { data } = await Vue.$axios.get<RawCurrency[]>(`${BACKEND_BASE_URL_READS}/api/v1/currencies`);
    return data.map((rawCurrency) => new Currency(rawCurrency));
  }
}
