
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PPagination, PFormSelect, PaginationPTableType } from "@/common/components";
import { i18n } from "@/i18n";

@Component({
  name: "local-pagination",
  components: {
    PPagination,
    PFormSelect,
  },
})
export class LocalPagination extends Vue {
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: 1 }) currentPage!: number;
  @Prop({ required: false }) itemName!: string;
  @Prop({ required: false, default: 20 }) pageSize!: number;
  @Prop({ required: false, default: 20 }) itemSize!: number;
  language = i18n.locale;
  pagination: PaginationPTableType = {
    pageSize: 0,
    totalElements: 0,
    pageSizeDefault: 20,
    currentPage: 0,
  };
  created(): void {
    this.pagination = {
      pageSize: this.$props.pageSize,
      totalElements: this.$props.itemSize,
      pageSizeDefault: 20,
      currentPage: this.$props.currentPage,
    };
  }
  @Watch("itemSize", { immediate: true })
  onItemSizeChange(newValue: number): void {
    this.pagination.totalElements = newValue;
  }

  change(valueLocal: PaginationPTableType): void {
    this.pagination = valueLocal;
    this.$emit("paginate", valueLocal.pageSize, valueLocal.currentPage);
  }
}
export default LocalPagination;
