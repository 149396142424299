
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  name: "title-size-page",
  components: {},
})
export class TitleSizePage extends Vue {
  @Prop({ required: true }) pageSize!: number;
  @Prop({ required: true }) itemSize!: number;
  @Prop({ required: true }) title!: number;

  legend(): string {
    let legend = "";
    if (this.pageSize > this.itemSize) legend = `1-${this.itemSize} de ${this.itemSize} `;
    else legend = `1-${this.pageSize} de ${this.itemSize} `;
    return legend;
  }
}
export default TitleSizePage;
