import { ServicePoint, RawServicePoint } from "@/entities";

export const mockRawServicePoints = (): RawServicePoint[] => [
  {
    id: "030fe6cc-cf36-3a3f-8e4c-75bb005a465d",
    aggregateId: "030fe6cc-cf36-3a3f-8e4c-75bb005a465d",
    code: "70",
    name: "EDS MEDELLIN UNIVERSIDAD",
    center: {
      id: "0dfe6c66-5036-3561-affe-9c28c6380847",
      code: "0001",
      name: "0001",
      zipCode: "1245",
      cityName: "Bogota",
      entityId: "4049a175-47d0-3e62-ad91-5f9585aba531",
      countryCode: "CO",
      provinceName: "Bogota",
      serviceCount: 2,
    },
    device: {
      id: "289e126a-de22-3376-9a03-cd6f9e8e80a1",
      ip: "10.28.51.79",
      code: "98234000170",
      name: "EDS MEDELLIN UNIVERSIDAD",
      port: "1288",
      type: "Multicenter",
      model: "SDM 504",
      currencies: ["COP"],
      lastUpdate: "2023-04-10T16:22:54.945579500-03:00[America/Buenos_Aires]",
      countryCode: "CO",
      manufacturer: "CIMA",
      noteCapacity: 10000,
      solutionType: "Depósito fácil",
      isMulticlient: false,
      delegationCode: "3",
      servicePointCount: 2,
    },
    entity: {
      id: "4049a175-47d0-3e62-ad91-5f9585aba531",
      code: "098234",
      name: "ESTACION DE SERVICIO UNIVERSIDAD S A",
      imageLink: "https://emazgenkindstg03.z6.web.core.windows.net/img/entity/4049a175-47d0-3e62-ad91-5f9585aba531.png",
      centerCount: 1,
      countryCode: "CO",
      customerCount: 0,
      servicePointCount: 1,
      logicCashTodayCount: 1,
    },
    earlyValue: false,
  },
];

export const mockServicePoints = (data: RawServicePoint[] = mockRawServicePoints()): ServicePoint[] =>
  data.map((item) => new ServicePoint(item));
