
import { Component, Vue } from "vue-property-decorator";
import { CenterFilters, CenterGrid, CenterHeader, CenterTabs } from "@/components";
import { Entity, RawEntity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { Breadcrum } from "@/components/commons";
import { ICenterFilter, iniCenterFilter } from "@/services";

@Component({
  components: {
    CenterHeader,
    Breadcrum,
    CenterTabs,
    CenterGrid,
    CenterFilters,
  },
})
export class CenterPage extends Vue {
  centerFilter: ICenterFilter = { ...iniCenterFilter };
  breadcrum: string[] = [this.$t("centerPage.breadcrum") as string];
  entityId!: string;
  entity: Partial<RawEntity> = {};
  loaded = false;
  countResults: { centers: number } = {
    centers: 0,
  };

  created(): void {
    this.entityId = this.$route.params.id;
    this.fetchEntity();
  }

  fetchEntity(): void {
    const map = {
      limit: 1,
      offset: 0,
      sortWay: "ASC",
      sortField: "aggregateId",
      filters: [
        {
          field: "aggregateId",
          operator: "equals",
          value: this.entityId,
        },
      ],
    };
    this.$services.entity
      .fetchEntities(map)
      .then((response: Pageable<Entity>) => {
        if (response.results.length == 1) {
          this.entity = response.results[0];
          this.breadcrum.push(this.entity.name!);
          this.loaded = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default CenterPage;
