
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  PDropDownContainer,
  SelectType,
  PModal,
  PButton,
  PCheckboxDropdown,
  PFormCheckBox,
  PFormSelect,
} from "@/common/components";
import { Country, STATUS_DONE, STATUS_DONE_BACK, STATUS_PENDING, STATUS_PENDING_BACK, User } from "@/entities";
import UserDetailButtons from "@/components/user-detail/user-detail-buttons/UserDetailButtons.vue";
import { i18n } from "@/i18n";
import SwalFactory from "@/common/utils/swal";
import UserDetailGroup from "@/components/user-detail/user-detail-group/UserDetailGroup.vue";
import { Getter } from "vuex-class";

@Component({
  i18n,
  components: {
    PDropDownContainer,
    UserDetailButtons,
    UserDetailGroup,
    PModal,
    PButton,
    PCheckboxDropdown,
    PFormCheckBox,
    PFormSelect,
  },
})
export class UserDetailInfo extends Vue {
  @Prop({ required: true }) user!: User;
  @Getter("getLoggedUser") getLoggedUser!: User;

  filterProperties: SelectType[] = [];
  status = "";
  modalTitle = this.$i18n.t("userPage.save_changes");
  alertSuccess = this.$i18n.t("groups.update_successful");
  alertCouldntUpdateInformation = this.$i18n.t("users.user_alert.user-update-failed");
  showConfirmationModal = false;
  filterPropertiesCountries: SelectType[] = [];
  assignedCountries: string[] = this.user.assignedCountries || [];
  isGlobal = this.user.corporateAdmin;
  async mounted(): Promise<void> {
    this.filterProperties = [
      { text: this.$t("status.done") as string, value: STATUS_DONE },
      { text: this.$t("status.pending") as string, value: STATUS_PENDING },
    ];
    this.status = this.user.status.toLowerCase();
    const response = await this.$services.country.fetchCountries();
    if (!this.getLoggedUser.corporateAdmin) {
      this.getLoggedUser.assignedCountries?.forEach((data: string) => {
        const translationLabel = `country.${data.toLowerCase()}`;
        this.filterPropertiesCountries.push({ text: this.$t(translationLabel) as string, value: data });
      });
    } else {
      response.countries.forEach((data: Country) => {
        const translationLabel = `country.${data.isoCode.toLowerCase()}`;
        this.filterPropertiesCountries.push({ text: this.$t(translationLabel) as string, value: data.isoCode });
      });
    }
  }

  get isStatusDoneOrCompleted(): boolean {
    return this.user.status === "DONE";
  }

  setStatusValue(): string {
    return this.status === STATUS_PENDING ? STATUS_PENDING_BACK : this.status === STATUS_DONE ? STATUS_DONE_BACK : "";
  }

  launchConfModal(): void {
    this.showConfirmationModal = true;
  }

  closeConfirmationModal(): void {
    this.showConfirmationModal = false;
    location.reload();
  }

  get disableButton(): boolean {
    return (this.status === "" || !this.assignedCountries || this.assignedCountries.length === 0) && !this.isGlobal;
  }

  async saveChanges(): Promise<void> {
    // Check if assignedCountries are empty or undefined and show a warning
    if ((!this.assignedCountries || this.assignedCountries.length === 0) && !this.isGlobal) {
      SwalFactory.swalAlertWarning(this.alertCouldntUpdateInformation, this.closeConfirmationModal);
      return;
    }

    const fieldsToUpdate: Record<string, unknown> = {
      assignedCountries: this.assignedCountries.filter((country) => {
        return this.filterPropertiesCountries.some((c) => c.value === country);
      }),
      isCorporateAdmin: this.isGlobal,
    };

    try {
      await this.$services.user.updateUserById(this.user.id, fieldsToUpdate).then(async () => {
        await this.$services.user.updateUserStatus(this.user.id, this.setStatusValue());
      });
      // Success alert
      SwalFactory.swalAlertSuccess(this.alertSuccess, this.closeConfirmationModal);
    } catch (error) {
      // Error alert in case the update fails
      SwalFactory.swalAlertWarning(this.alertCouldntUpdateInformation, this.closeConfirmationModal);
    }
  }

  updateCountries(countries: string[]): void {
    this.assignedCountries = countries;
  }
  changeCheck(value: boolean): void {
    this.isGlobal = value;
  }
}
export default UserDetailInfo;
