
import { Component, Vue } from "vue-property-decorator";
import { PFormInputTextSearch } from "@/common/components";
import { EventBus } from "@/event-bus";

@Component({
  name: "support-search-filter",
  components: {
    PFormInputTextSearch,
  },
})
export class SupportSearchFilter extends Vue {
  search = "";
  support_input = "";

  setSearch(text: string): void {
    this.search = text;
  }

  searchCenter(): void {
    EventBus.$emit("search-support", this.search);
  }
}

export default SupportSearchFilter;
