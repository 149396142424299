
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "panel-widgets",
  components: {},
})
export class PanelWidgets extends Vue {
  public visible = false;

  constructor() {
    super();
  }
}
export default PanelWidgets;
