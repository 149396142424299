import { mockCountries } from "@/entities";
import { IGettersMock } from "./getters.types";

export const mockGetters = (): IGettersMock => ({
  // Root
  getLocale: jest.fn(),
  getUrl: jest.fn(),
  getIsSignedIn: jest.fn(),

  // User
  getLoggedUser: jest.fn(),

  // Country
  getCountries: jest.fn(() => mockCountries()),
});
