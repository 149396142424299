
import { i18n } from "@/i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniRawUser, User } from "@/entities";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  i18n,
  components: { PIcon },
})
export class GroupGridOptions extends Vue {
  @Prop({ required: true }) value!: User;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  open = false;
  valueLocal: User = new User(iniRawUser);

  goToInfo(): void {
    this.open = false;
    this.$emit("action", { action: "info", data: this.valueLocal });
  }

  deleteElement(): void {
    this.open = false;
    this.$emit("action", { action: "delete", data: this.valueLocal });
  }
}
export default GroupGridOptions;
