
import { Vue, Component, Prop } from "vue-property-decorator";
import { PButton } from "@/common/components";

@Component({
  components: {
    PButton,
  },
})
export class UserDetailButtons extends Vue {
  @Prop({ required: true, default: false }) isDisabled!: boolean;

  cancelChanges(): void {
    this.$router.push("/users");
  }

  saveChanges(): void {
    this.$emit("updateStatus");
  }
}
export default UserDetailButtons;
