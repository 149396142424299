
// En este compononente es complicado poder poner una variente
import { Component, Prop, Model, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "p-form-check-box",
})
export class PFormCheckBox extends Vue {
  @Model("intput") readonly checked!: any;
  @Watch("checked")
  onChangeChecked() {
    this.isCheckedFun();
  }
  @Prop({ required: true }) id!: string;
  @Prop({ required: false, default: true }) value!: any;
  @Prop({ required: false, default: false }) unchecked_value!: any;
  @Prop({ required: false }) label!: string;
  @Prop({ required: false, default: "p_form_checkbox_label" }) lclass!: string;
  @Prop({ required: false, default: false }) readonly!: boolean;

  public isChecked: boolean;
  public defaultCheked: boolean;
  public mostrarLabel: boolean;
  constructor() {
    super();
    this.isChecked = false;
    this.defaultCheked = false;
    this.mostrarLabel = false;
  }

  change(): void {
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.$emit("intput", this.value);
      this.$emit("change", this.value);
    } else {
      this.$emit("intput", this.unchecked_value);
      this.$emit("change", this.unchecked_value);
    }
  }
  isCheckedFun(): void {
    if (this.value === this.checked) {
      this.defaultCheked = true;
      this.isChecked = true;
    } else {
      this.defaultCheked = false;
      this.isChecked = false;
    }
  }

  mounted(): void {
    if (this.label === undefined) {
      this.mostrarLabel = false;
    } else {
      this.mostrarLabel = true;
    }
    this.isCheckedFun();
  }
  get dameClassReadOnly(): string {
    if (this.readonly) {
      return "sololectura";
    } else {
      return "";
    }
  }
}
export default PFormCheckBox;
