
import { Component, Vue, Prop } from "vue-property-decorator";
import { PFormInputText, PButton } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { i18n } from "@/i18n";

@Component({
  name: "group-description",
  i18n,
  components: {
    PIcon,
    PFormInputText,
    PButton,
  },
})
export class GroupDescription extends Vue {
  @Prop({ required: true }) groupDescription!: string;
  @Prop({ required: true }) groupId!: string;
  descriptionPlaceholder = this.$i18n.t("groups.description");
  newDescription = "";
  isActive = false;
  confirmationAlert = this.$i18n.t("groups.update_successful");

  toggleDescription(): void {
    this.newDescription = this.groupDescription;
    this.isActive = !this.isActive;
  }

  cancelChanges(): void {
    this.isActive = !this.isActive;
  }

  async saveChanges(): Promise<void> {
    await this.$services.group.updateGroupDescription(this.newDescription, this.groupId);
    Vue.swal({
      icon: "success",
      title: this.confirmationAlert,
      showConfirmButton: false,
      timer: 1500,
    });
    this.groupDescription = this.newDescription;
    this.isActive = !this.isActive;
  }
}
export default GroupDescription;
