import { Vue } from "vue-property-decorator";

export class PTableMakerParent extends Vue {

    constructor(){
        super();
    }

protected chevrondown(): string {
   return `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m10 13.062-5-5L6.062 7 10 10.938 13.938 7 15 8.062Z"/></svg>`;
 }
protected chevronup(): string {
   return `<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="24px" fill="#000000">
   <path d="M0 0h24v24H0V0z" fill="none" />
   <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z" />
 </svg>`
}
}

