
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import WizardItem from "./WizardItem.vue";
import { PButton } from "@/common/components/look";
import { EventBus } from "@/event-bus";
import { Rule, User } from "@/entities";
import { v4 } from "uuid";
import { Getter } from "vuex-class";

@Component({
  name: "wizard-conditions",
  components: {
    WizardItem,
    PButton,
  },
})
export class WizardConditions extends Vue {
  @Prop({ required: true }) rules!: Rule[];
  localRules: Rule[] = [];
  count = 1;
  isLoading = true;
  countries: string[] = [];

  @Getter("getLoggedUser") getLoggedUser!: User;

  @Watch("localRules", { deep: true })
  onLocalRulesChanged(newRules: Rule[]) {
    // Assuming that multiple rules could potentially change the countries, we need to filter them
    this.countries = newRules.filter((rule) => rule.fieldName == "countryCode").map((rule) => rule.fieldValue);
  }

  created(): void {
    this.localRules = [...this.rules];
    const uuId = v4();

    this.localRules.push({
      id: uuId,
      fieldName: "",
      fieldOperator: "",
      fieldValue: "",
      ruleOperator: "",
      fieldType: "",
      showValue: "",
    });

    this.isLoading = false;
  }

  replaceRule(ruleId: string, field: string, operator: string, value: string, condition: string, fieldType: string) {
    const index: number = this.localRules.findIndex((rule) => rule.id == ruleId);
    if (index == -1) return;

    this.$set(this.localRules, index, {
      id: this.localRules[index].id,
      fieldName: field,
      fieldOperator: operator,
      fieldValue: value,
      ruleOperator: condition ? condition : "AND",
      fieldType: fieldType,
      showValue: "",
    });
  }

  replaceCondition(ruleId: string, condition: string) {
    const index: number = this.localRules.findIndex((rule) => rule.id == ruleId);
    if (index == -1) return;

    this.localRules[index] = {
      id: this.localRules[index].id,
      fieldName: this.localRules[index].fieldName,
      fieldOperator: this.localRules[index].fieldOperator,
      fieldValue: this.localRules[index].fieldValue,
      ruleOperator: condition,
      fieldType: this.localRules[index].fieldType,
      showValue: this.localRules[index].showValue,
    };
  }

  addRule(): void {
    const uuId = v4();
    this.localRules.push({
      id: uuId,
      fieldName: "",
      fieldOperator: "",
      fieldValue: "",
      ruleOperator: "",
      fieldType: "",
      showValue: "",
    });
  }

  deleteRule(ruleId: string) {
    this.localRules = this.localRules.filter((rule) => rule.id != ruleId);
  }

  canEdit(rule: Rule): boolean {
    return this.getLoggedUser.corporateAdmin || !rule.fieldName.includes("country");
  }

  saveWizardRule(): void {
    EventBus.$emit("group-conditions-saved", this.localRules);
  }
}
export default WizardConditions;
