import { RawLocation, Location } from "@/entities";

export const mockRawLocations = (): RawLocation[] => [
  {
    id: "bc42fa0b-4837-49dc-b73d-b7ca5c42dd8e",
    name: "Madrid",
    country: "España",
    city: "Barcelona",
  },
  {
    id: "f1d68eca-4e51-4f17-a18b-6d256eb85d8c",
    name: "Location1",
    country: "Argentina",
    city: "Bs. As.",
  },
  {
    id: "0a740a36-4eb0-4891-b9e9-81262bb16223",
    name: "Location2",
    country: "Chile",
    city: "Santiago",
  },
];

export const mockLocations = (data: RawLocation[] = mockRawLocations()): Location[] =>
  data.map((item) => new Location(item));
