
import { i18n } from "@/i18n";
import { IField, User, UserStatus } from "@/entities";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType, PFormCheckBox, PPagination } from "@/common/components";
import { Utilities } from "@/components/commons";
import UserInitials from "@/components/users/user-initials/UserInitials.vue";
import UserGridAlert from "@/components/users/user-grid-alert/UserGridAlert.vue";
import { iniUserFilterCustom, IUserFilter, PageRequest } from "@/services";
import { customDebounce } from "@/common/utils";
import { Getter } from "vuex-class";
import { SearchCustomerRequest } from "@/components";

@Component({
  name: "user-grid",
  i18n,
  components: {
    PFormCheckBox,
    UserInitials,
    UserGridAlert,
    PPagination,
  },
})
export class UserGrid extends Vue {
  @Getter("getLoggedUser") getLoggedUser!: User;
  @Prop({ required: true }) value!: { users: number; groups: number };
  @Prop({ required: false, default: "" }) localFilter!: string;
  @Prop({ required: true }) filters!: IUserFilter;

  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: false,
    sortField: "name",
  };
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: SearchCustomerRequest = {
    limit: this.pagination.pageSize,
    offset: 0,
    sortField: this.sort.sortField,
    sortWay: this.sort.sortDesc ? "DESC" : "ASC",
  };

  atLeastTwoFilters = false;

  valueLocal: { users: number; groups: number } = {
    users: 0,
    groups: 0,
  };

  language = i18n.locale;
  users: User[] = [];
  paginationBack: PageRequest = {
    limit: this.params.limit,
    offset: this.params.offset,
    sortField: this.params.sortField,
    sortWay: this.params.sortWay,
    filters: [],
  };
  usersSelected: User[] = [];
  isCheckedAll = false;

  paginationDefault = {
    limit: 100,
  };
  filtrosBack = { ...iniUserFilterCustom };

  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.updateParams();
  }

  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    let counterValidFilters = 0;
    //Number of valid filters is 1 because active users counts as one both for corporateAdmin and notCorporateAdmin
    const numberValidFilters = 1;
    const mapParams: Record<string, any> = this.params;
    Object.keys(mapParams).forEach((key) => {
      if (!Utilities.isObjectEmpty(mapParams[key]) && Object.prototype.hasOwnProperty.call(this.filtrosBack, key)) {
        counterValidFilters++;
      }
    });

    if (counterValidFilters > numberValidFilters) {
      this.atLeastTwoFilters = true;
      customDebounce(() => this.getUsers(), 350);
    }
  }

  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }

  getStatus(item: User): string {
    if (!item.active) return this.$t("users.table.disabled") as string;
    if (item.status === UserStatus.PENDING) return this.$t("users.table.pending") as string;
    return this.$t("users.table.done") as string;
  }

  getStatusColor(item: User): string {
    if (!item.active) return "#6f6f6f";
    if (item.status === UserStatus.PENDING) return "#f5a623";
    return "#b6c608";
  }

  rowClass(item: User, type: string) {
    if (!item || type !== "row") return;
    if (!item.active) return "table-inactive";
  }

  isActive(item: User): boolean {
    if (!item.active) return false;
    return item.status !== UserStatus.PENDING;
  }

  get filterUsers(): User[] {
    return this.users?.filter(
      (opt: User) =>
        opt.fullName.toLowerCase().indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.fullName
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.email.toLowerCase().indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.email
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.id.toLowerCase().indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.id
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.localFilter.toLowerCase()) >= 0
    );
  }

  updateParams(): void {
    this.params = {
      countryCode: this.filters.country,
      groupName: this.filters.group,
      active: this.filters.enabled,
      fullNameOrEmail: this.filters.text,
      type: this.filters.type,
      status: this.filters.status,
      limit: this.paginationBack.limit,
      offset: this.paginationBack.offset,
      sortField: this.paginationBack.sortField,
      sortWay: this.paginationBack.sortWay,
    };
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async getUsers(): Promise<void> {
    this.usersSelected = [];
    this.isCheckedAll = false;
    try {
      const data = await this.$services.user.fetchUsersV2(this.params);
      this.users = data.results;
      this.pagination.totalElements = data.totalResult;
      this.valueLocal.users = data.totalResult;
    } catch (error) {
      console.error("Error fetching users:", error);
      this.users = [];
      this.pagination.totalElements = 0;
      this.valueLocal.users = 0;
    }
    if (!this.users.length) {
      this.valueLocal.users = 0;
    }
    this.$emit("input", this.valueLocal);
  }

  get fields(): IField[] {
    return [
      {
        key: "actions",
        label: "",
        sortable: false,
        thClass: "table-header table-header-checkbox",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: true,
      },
      {
        key: "fullName",
        label: this.$t("users.table.fullName") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "email",
        label: this.$t("users.table.email") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "country",
        label: this.$t("users.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "group.name",
        label: this.$t("users.table.group") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "status",
        label: this.$t("users.table.status") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }

  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.usersSelected = [];
    if (this.isCheckedAll) {
      this.users?.forEach((item) => {
        if (this.isActive(item)) {
          this.usersSelected.push(item);
        }
      });
    }
  }

  get canSelectUsers(): boolean {
    return (this.users ?? []).filter((value: User) => this.isActive(value)).length > 0;
  }

  changeCheckAll(): void {
    this.isCheckedAll = this.usersSelected.length == this.users?.filter((value: User) => this.isActive(value)).length;
  }

  closeAlert(): void {
    const listOfChecks = document.querySelectorAll("input[type=checkbox]");
    listOfChecks.forEach((element: Element) => {
      (element as HTMLInputElement).checked = false;
    });
    this.usersSelected = [];
    this.isCheckedAll = false;
  }

  goToUserPage(row: User): void {
    this.$router.push({ name: "user-page", params: { id: row.id } });
  }
}
export default UserGrid;
