
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType, PFormCheckBox } from "@/common/components";
import { i18n } from "@/i18n";
import { Group, IField, ServicePoint } from "@/entities";
import { Utilities } from "@/components/commons";
import { IRequestServicePointFilter, iniRequestServicePointFilter, PageRequestServicePoint } from "@/services";
import PGridAlert from "@/common/components/look/PGridAlert/PGridAlert.vue";
import { customDebounce } from "@/common/utils";

@Component({
  name: "manual-rules-grid",
  i18n,
  components: {
    PFormCheckBox,
    PGridAlert,
  },
})
export class ManualRulesGrid extends Vue {
  @Prop({ required: true }) group!: Group;
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.pagination.pageSize = 5;
    this.updatePaginationBack();
    this.mapFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    if (!Utilities.isObjectEmpty(this.filters) || this.group.assignedServicePoints.length > 0) {
      this.areData = true;
      if (this.firstLoad) {
        this.fetchServicePoints();
        this.firstLoad = false;
      } else customDebounce(() => this.fetchServicePoints(), 350);
    }
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortFields[0].direction != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortFields[0].field != this.sort.sortField
    ) {
      this.paginationBack.sortFields[0].direction = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortFields[0].field = this.sort.sortField;
      this.updateParams();
    }
  }
  areData = this.group.assignedServicePoints.length > 0 ? true : false;
  firstLoad = true;
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "id",
  };
  filters_field: IRequestServicePointFilter = { ...iniRequestServicePointFilter };
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageableServicePoint(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    type: "type",
    country: "countryCode",
    manufacturer: "manuFacturer",
    delegation: "delegationCode",
    text: "name",
  };
  servicePoints: ServicePoint[] = [];
  paginationBack: PageRequestServicePoint = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    filters: { ...iniRequestServicePointFilter },
    sortFields: [
      {
        field: this.params["sortField"] as string,
        direction: this.params["sortWay"] as string,
      },
    ],
  };
  servicePointsSelected: ServicePoint[] = [];
  isCheckedAll = false;
  hasEmitted = false;

  paginationDefault = {
    limit: 100,
  };

  mapFilterToRecord(): void {
    this.filters_field = { ...iniRequestServicePointFilter };
    if (!Utilities.isObjectEmpty(this.filters)) {
      this.filters_field = {
        servicePointName: this.filters.text,
        countryCode: this.filters.countryCode,
        entityCode: this.filters.entityCode,
        deviceId: this.filters.text,
      };
    }
    this.updateParams();
  }

  updateParams(): void {
    this.params = Utilities.pageableServicePoint(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortFields[0].field,
      this.paginationBack.sortFields[0].direction,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async fetchServicePoints(): Promise<void> {
    this.servicePointsSelected = [];
    this.isCheckedAll = false;
    const data = await this.$services.servicepoint.fetchServicePoints(this.params);
    this.servicePoints = data.results;
    this.pagination.totalElements = data.totalResult;
  }

  addServicePoints(): void {
    const servicePointsAssigned: string[] = [];
    if (this.group.assignedServicePoints) {
      this.group.assignedServicePoints.forEach((value) => {
        if (value.associationType != "automatic-group") {
          servicePointsAssigned.push(value.servicePoint.id);
        }
      });
    }
    this.servicePointsSelected.forEach((value) => servicePointsAssigned.push(value.id));
    this.$services.group.addCashTodays(this.group.id, servicePointsAssigned).then(() =>
      Vue.swal({
        icon: "success",
        title: this.$t("label.update_successful"),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        this.closeAlert();
        this.$emit("refresh");
      })
    );
  }

  isNotAssigned(item: ServicePoint): boolean {
    const check = this.group.assignedServicePoints.find((value) => value.servicePoint.id === item.id) === undefined;
    if (!this.hasEmitted) {
      if (!check) {
        this.$emit("assignedManual", true);
        this.hasEmitted = true;
      } else {
        this.$emit("assignedManual", false);
      }
    }
    return check;
  }

  get fields(): IField[] {
    return [
      {
        key: "actions",
        label: "",
        sortable: false,
        thClass: "table-header table-header-checkbox",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: true,
      },
      {
        key: "code",
        label: this.$t("machine.table.detail.point-code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "name",
        label: this.$t("machine.table.detail.point") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "entity.countryCode",
        label: this.$t("machine.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "entity.code",
        label: this.$t("machine.table.detail.entity-code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "entity.name",
        label: this.$t("machine.table.detail.entity") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center.code",
        label: this.$t("machine.table.detail.center-code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center.name",
        label: this.$t("machine.table.detail.center") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "device.code",
        label: this.$t("machine.table.machine_id") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "device.delegationCode",
        label: this.$t("machine.table.delegation") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }

  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.servicePointsSelected = [];
    if (this.isCheckedAll) {
      this.servicePointsSelected.push(...(this.servicePoints ?? []).filter((value) => this.isNotAssigned(value)));
    }
  }

  changeCheckAll(): void {
    this.isCheckedAll =
      this.servicePointsSelected.length === this.servicePoints?.filter((value) => this.isNotAssigned(value)).length;
  }

  closeAlert(): void {
    const listOfChecks = document.querySelectorAll("#manualRulesGrid input[type=checkbox]");
    listOfChecks.forEach((element: Element) => {
      (element as HTMLInputElement).checked = false;
    });
    this.servicePointsSelected = [];
    this.isCheckedAll = false;
  }
}
export default ManualRulesGrid;
