
import { Vue, Component, Prop } from "vue-property-decorator";
import { RawUser } from "@/entities";

@Component({
  name: "user-detail-nav",
})
export class UserDetailNav extends Vue {
  @Prop({ required: true }) user!: RawUser;

  goToUserDetail(): void {
    this.$router.push("/users");
  }
}
export default UserDetailNav;
