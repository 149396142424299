export class Solution {
  countryCode: string;
  solutionType: string;
  entityCode: string;
  entityName: string;
  centerCode: string;
  centerName: string;
  servicePointCode: string;
  servicePointName: string;
  delegationCode: string;
  delegationName: string;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  deviceManufacturer: string;
  deviceModel: string;
  deviceIp: string;
  devicePort: string;
  deviceCurrencies: string[];
  zipCode: string;
  cityName: string;
  provinceName: string;

  constructor() {
    this.countryCode = "";
    this.solutionType = "";
    this.entityCode = "";
    this.entityName = "";
    this.centerCode = "";
    this.centerName = "";
    this.servicePointCode = "";
    this.servicePointName = "";
    this.delegationCode = "";
    this.delegationName = "";
    this.deviceId = "";
    this.deviceName = "";
    this.deviceType = "";
    this.deviceManufacturer = "";
    this.deviceModel = "";
    this.deviceId = "";
    this.deviceIp = "";
    this.devicePort = "";
    this.deviceCurrencies = [];
    this.zipCode = "";
    this.cityName = "";
    this.provinceName = "";
  }
}
