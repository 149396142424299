import { EventBus } from "@/event-bus";
import { i18n } from "@/i18n";
import { ApiError } from "@/services";
import axios from "axios";
import Vue, { PluginObject } from "vue";
import Router from "vue-router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

export const addAxiosInterceptors = (router: Router): void => {
  _axios.interceptors.request.use(
    async (cfg) => {
      // Do something before request is sent
      const user = await Vue.$auth.getUser();

      cfg.headers = {
        Authorization: `${user?.token_type || "Bearer"} ${user?.id_token}`,
      };

      return cfg;
    },
    (err) => {
      // Do something with request error
      return Promise.reject(err);
    }
  );

  // Add a response interceptor
  _axios.interceptors.response.use(
    (res) => {
      // Do something with response data
      return res;
    },
    async (err) => {
      // Do something with response error
      if (typeof err.response === "undefined" && !err["__CANCEL__"]) {
        EventBus.$emit("on-network-error", err);
        Vue.swal({
          title: i18n.t("errorNetwork.title") as string,
          html: `<ol class='text-left'>
                  <li>${i18n.t("errorNetwork.cause1")}</li>
                  <li>${i18n.t("errorNetwork.cause2")}</li>
                  <li>${i18n.t("errorNetwork.cause3")}</li>
                </ol>`,
          icon: "error",
        });
      } else if (err.response.status === 401) {
        await Vue.$auth.signinRedirect();
      } else if (err.response.status === 403) {
        router.push({
          name: "callback",
          query: {
            error: "access_denied",
          },
        });
      } else if ((err.response.data as ApiError).message) {
        const responseError = err.response.data as ApiError;

        Vue.swal({
          title: i18n.t(responseError.message) as string,
          icon: "error",
        });
      }

      return Promise.reject(err);
    }
  );
};

const Plugin: PluginObject<unknown> = {
  install: (vue) => {
    vue.$axios = _axios;
  },
};

Plugin.install = (vue) => {
  vue.$axios = _axios;
  window.axios = _axios;
  Object.defineProperties(vue.prototype, {
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
