import { RawGroupUser, RawUser } from "@/entities";
import { AssignedServicePoint } from "@/entities/assignedServicePoint";

export enum UserStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

export class User {
  readonly id: string;
  readonly name: string;
  readonly lastName: string;
  readonly fullName: string;
  readonly email: string;
  readonly country: string;
  readonly group?: RawGroupUser;
  readonly type: string;
  readonly active: boolean;
  readonly status: UserStatus;
  readonly dateAdded: string;
  readonly avatarLetter: string;
  readonly assignedServicePoints: AssignedServicePoint[];
  readonly corporateAdmin: boolean;
  readonly newUser?: boolean;
  readonly assignedCountries?: string[];
  constructor(data: RawUser) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.lastName = data.lastName || "";
    this.fullName = data.fullName || "";
    this.email = data.email || "";
    this.country = data.country || "";
    this.group = data.group;
    this.type = data.type || "";
    this.active = data.active || false;
    this.status = this.determineStatus(data.status);
    this.dateAdded = data.group?.dateAdded || "";
    this.avatarLetter = data.name?.substring(0, 1).toUpperCase() || "";
    this.assignedServicePoints = data.assignedServicePoints
      ? data.assignedServicePoints.filter((value) => value.servicePoint).map((value) => new AssignedServicePoint(value))
      : [];
    this.assignedCountries = data.assignedCountries || Array.of();
    this.corporateAdmin = data.corporateAdmin;
    this.newUser = data.newUser;
  }
  private determineStatus(status: string | undefined): UserStatus {
    return status ? UserStatus[status.toUpperCase() as keyof typeof UserStatus] : UserStatus.PENDING;
  }
}
