
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SelectGroupType } from "@/common/components/look";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import {Placement} from "popper.js";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
export class PFormSelectMulti extends Vue {
  @Prop({ required: true }) value!: string[];
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Prop({ required: true }) options!: any | SelectGroupType[];
  @Prop({ required: false, default: false }) group!: boolean;
  @Prop({ required: false, default: "value" }) optionValue!: string;
  @Prop({ required: false, default: "text" }) optionText!: string;
  @Prop({ required: false, default: "" }) optionText2!: string;
  @Prop({ required: false, default: "" }) optionTextSeparator!: string;
  @Prop({ required: false, default: "" }) optionSubText!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: "Select an option" }) placeholder!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: undefined }) rules!: string;
  @Prop({ required: false, default: "" }) invalid_feedback!: string;
  @Prop({ required: false, default: false }) onlyOptions!: boolean;
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: undefined }) max!: number;
  @Prop({ required: false, default: "" }) variant!: "" | "round";
  @Prop({ required: false, default: "bottom-start" }) placement!: Placement;
  @Watch("pussOption", { immediate: true, deep: true })
  onChangePussOption(): void {
    if (!this.pussOption) {
      this.inputFilter = "";
      this.valueSearch = "";
    } else {
      this.$nextTick(() => {
        (this.$refs.inputSearch as HTMLElement).focus();
      });
    }
  }
  valueLocal: unknown[] = [];
  pussOption = false;
  inputFilter = "";
  valueSearch = "";

  get optionsFilering(): any[] {
    return this.options.filter(
      (opt: any) =>
        this.textOption(opt).toLowerCase().indexOf(this.valueSearch.toLowerCase()) >= 0 ||
        this.textOption(opt)
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.valueSearch.toLowerCase()) >= 0
    );
  }

  textOption(option: any): string{
    if(this.optionText2 !== ""){
      return option[this.optionText]+this.optionTextSeparator+option[this.optionText2];
    }else if(this.optionSubText){
      return option[this.optionText][this.optionSubText];
    }else{
      return option[this.optionText];
    }
  }

  get selectedTexts(): unknown[] {
    if (this.valueLocal.length > 0) {
      return this.valueLocal;
    } else {
      return [];
    }
  }
  get optionsComp(): any[] {
    if (this.group) {
      //Aqui sacamos todos los grupos y ponemos todos los items juntos para seguir la logica de selecionar los item
      let returnTemp: unknown[] = [];
      this.options.forEach((option: SelectGroupType) => {
        returnTemp = returnTemp.concat(option.items);
      });
      return returnTemp;
    } else {
      if (this.inputFilter != "") {
        return this.options.filter(
          (opt: any) =>
            this.textOption(opt).toLowerCase().indexOf(this.inputFilter.toLowerCase()) >= 0
        );
      } else {
        return this.options;
      }
    }
  }
  get classOnlyOptions(): string {
    if (this.onlyOptions) {
      return "optionsOnlyOptions_PFormSelectMulti";
    } else {
      return "";
    }
  }
  get classReadOnly(): string {
    if (this.readonly) {
      return "inputSelectReadonly_PFormSelectMulti";
    } else {
      return "";
    }
  }

  giveMeTextOption(selectedText: unknown): unknown {
    const optionSelected: any = this.optionsComp.find(
      (op: any) => op[this.optionValue] == selectedText
    );
    if (optionSelected) {
      return this.textOption(optionSelected);
    }
    return selectedText;
  }
  selectGroup(group: SelectGroupType): void {
    let isAllSelected = true;
    group.items.forEach((item) => {
      if (
        this.valueLocal.findIndex(
          (vt) => JSON.stringify(vt) == JSON.stringify(item[this.optionValue])
        ) == -1
      ) {
        isAllSelected = false;
      }
    });
    group.items.forEach((item) => {
      this.selectItem(item[this.optionValue], !isAllSelected);
    });
  }
  selectItem(itemValue: unknown, soloSeleccionar?: boolean): void {
    if (!this.valueLocal.find((vt) => JSON.stringify(vt) == JSON.stringify(itemValue))) {
      if (!this.max || this.max > this.valueLocal.length) {
        this.valueLocal.push(itemValue);
        this.$emit("input", this.valueLocal);
        this.$emit("change", this.valueLocal);
      }
    } else {
      if (!soloSeleccionar) {
        this.unSelectedItem(itemValue);
        this.$emit("input", this.valueLocal);
        this.$emit("change", this.valueLocal);
      }
    }
  }
  unSelectedItem(itemValue: unknown): void {
    this.valueLocal.splice(this.valueLocal.indexOf(itemValue), 1);

    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
  giveMeClass(itemValue: unknown): string {
    if (this.valueLocal.find((vt) => JSON.stringify(vt) == JSON.stringify(itemValue))) {
      return "itemSelectSelected_PFormSelectMulti";
    } else {
      return "itemSelect_PFormSelectMulti";
    }
  }
  open(): void {
    if (!this.readonly) {
      this.pussOption = !this.pussOption;
    }
  }

  get variantCop(): string {
    return  "inputSelect_PFormSelectMulti " + this.variant;
  }
}
export default PFormSelectMulti;
