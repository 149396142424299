export type RawDevice = {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly ip: string;
  readonly port: string;
  readonly type: string;
  readonly model: string;
  readonly countryCode: string;
  readonly manufacturer: string;
  readonly solutionType: string;
  readonly delegationCode: string;
  readonly lastUpdate: string;
  readonly currencies: string[];
  readonly noteCapacity: number;
  readonly isMulticlient: boolean;
  readonly servicePointCount: number;
};

export const iniRawDevice: RawDevice = {
  id: "",
  name: "",
  code: "",
  ip: "",
  port: "",
  type: "",
  model: "",
  countryCode: "",
  manufacturer: "",
  solutionType: "",
  delegationCode: "",
  lastUpdate: "",
  currencies: [],
  noteCapacity: 0,
  isMulticlient: false,
  servicePointCount: 0,
};
