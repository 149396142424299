import { UserMachine } from "./usermachine";
import { RawUserMachine } from "./usermachine.types";

export const mockRawUserMachine = (): RawUserMachine[] => [
  {
    aggregateId: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    entity: "ES010000101010 Sample Customer 1",
    center: "ES010000101010 Sample Center 1",
    servicePoint: "ES010000101010 Sample Service Point 1",
    deviceName: "New Retail Device 1",
    deviceId: "12121212",
  },
  {
    aggregateId: "037ee455-66d0-40f9-8269-3eb75f5e123",
    entity: "ES010000101010 Sample Customer 2",
    center: "ES010000101010 Sample Center 2",
    servicePoint: "ES010000101010 Sample Service Point 2",
    deviceName: "New Retail Device 2",
    deviceId: "1313131313",
  },
  {
    aggregateId: "037ee455-66d0-40f9-8269-3eb75f23545",
    entity: "ES010000101010 Sample Customer 3",
    center: "ES010000101010 Sample Center 3",
    servicePoint: "ES010000101010 Sample Service Point 3",
    deviceName: "New Retail Device 3",
    deviceId: "12312345",
  },
  {
    aggregateId: "037ee455-66d0-40f9-8269-3eb75f123455",
    entity: "ES010000101010 Sample Customer 4",
    center: "ES010000101010 Sample Center 4",
    servicePoint: "ES010000101010 Sample Service Point 4",
    deviceName: "New Retail Device 4",
    deviceId: "321456",
  },
  {
    aggregateId: "037ee455-66d0-40f9-8269-3eb75f432111",
    entity: "ES010000101010 Sample Customer 5",
    center: "ES010000101010 Sample Center 5",
    servicePoint: "ES010000101010 Sample Service Point 5",
    deviceName: "New Retail Device 5",
    deviceId: "3214321",
  },
  {
    aggregateId: "037ee455-66d0-40f9-8269-3eb751231245",
    entity: "ES010000101010 Sample Customer 6",
    center: "ES010000101010 Sample Center 6",
    servicePoint: "ES010000101010 Sample Service Point 6",
    deviceName: "New Retail Device 6",
    deviceId: "2132124",
  },
];

export const mockUserMachine = (data: RawUserMachine[] = mockRawUserMachine()): UserMachine[] =>
  data.map((item) => new UserMachine(item));
