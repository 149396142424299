import { iniRawServicePoint, RawServicePoint } from "@/entities";

export type RawAssignedServicePoint = {
  readonly groupId: string;
  readonly servicePoint: RawServicePoint;
  readonly associationType: string;
};

export const iniRawAssignedServicePoint: RawAssignedServicePoint = {
  groupId: "",
  servicePoint: { ...iniRawServicePoint },
  associationType: "",
};
