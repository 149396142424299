import Pageable from "@/entities/pageable/pageable_collection";
import { ServicePoint } from "@/entities";

export interface ServicePointService {
  fetchServicePoints(map: Record<string, unknown>): Promise<Pageable<ServicePoint>>;
  fetchServicePointsByGroupRules(groupId: string, map: Record<string, unknown>): Promise<Pageable<ServicePoint>>;
}

export interface IServicePointFilter {
  text?: string;
  countryCode?: string;
  entityCode?: string;
}

export const iniServicePointFilter: IServicePointFilter = {
  text: "",
  countryCode: "",
  entityCode: "",
};

export interface IRequestServicePointFilter {
  servicePointName?: string;
  countryCode?: string;
  entityCode?: string;
  deviceId?: string;
}

export const iniRequestServicePointFilter: IRequestServicePointFilter = {
  servicePointName: "",
  countryCode: "",
  entityCode: "",
  deviceId: "",
};

export interface ServicePointServiceMock {
  fetchServicePoints: jest.Mock<Promise<Pageable<ServicePoint>>>;
  fetchServicePointsByGroupRules: jest.Mock<Promise<Pageable<ServicePoint>>>;
}
