
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({
  name: "p-check-slide",
  components: {},
})
export class PCheckSlide extends Vue {
  @Prop({ required: false, default: false }) checked!: boolean;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: "lg" }) size!: string;
  @Prop({ required: false, default: "" }) status!: string;

  test="test";
}

export default PCheckSlide;
