import { User } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { UserServicePoints } from "@/entities/user-servicepoints";
import { SearchCustomerRequest } from "@/components";

export interface UserService {
  fetchMe(): Promise<User>;
  updateMe(): Promise<User>;
  fetchUsers(map: Record<string, unknown>): Promise<Pageable<User>>;
  updateUserById(userId: string, map: Record<string, unknown>): Promise<void>;
  fetchUsersV2(request: SearchCustomerRequest): Promise<Pageable<User>>;
  fetchUser(id: string): Promise<User>;
  fetchUserCustomer(id: string): Promise<User>;
  fetchUserCustomerServicepoints(id: string): Promise<UserServicePoints>;
  updateUserStatus(userId: string, status: string): Promise<void>;
  addCashTodays(userId: string, cashTodays: string[]): Promise<void>;
}

export interface IUserFilter {
  text?: string;
  type: string;
  country: string;
  group: string;
  enabled: boolean;
  email?: string;
  status?: string;
}

export interface IUserFilterCustom {
  active: boolean;
  countryCode: string;
  fullNameOrEmail: string;
  groupName: string;
  status: string;
  type: string;
}

export const iniUserFilterCustom: IUserFilterCustom = {
  active: true,
  countryCode: "",
  fullNameOrEmail: "",
  groupName: "",
  status: "",
  type: "",
};

export const iniUserFilter: IUserFilter = {
  text: "",
  type: "",
  country: "",
  group: "",
  enabled: true,
  status: "",
};

export interface UserServiceMock {
  fetchMe: jest.Mock<Promise<User>>;
  updateMe: jest.Mock<Promise<User>>;
  updateUserById: jest.Mock<Promise<void>>;
  fetchUsers: jest.Mock<Promise<Pageable<User>>>;
  fetchUsersV2: jest.Mock<Promise<Pageable<User>>>;
  fetchUser: jest.Mock<Promise<User>>;
  fetchUserCustomer: jest.Mock<Promise<User>>;
  fetchUserCustomerServicepoints: jest.Mock<Promise<UserServicePoints>>;
  updateUserStatus: jest.Mock<Promise<void>>;
  addCashTodays: jest.Mock<Promise<void>>;
}
