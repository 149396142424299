import { AssignedServicePoint } from "../assignedServicePoint";
import { RawAutomaticRules } from "./automaticRules.types";
import { Rule } from "@/entities";

export class AutomaticRules {
  readonly id: string;
  readonly rules: Rule[];
  assignedServicePoints: AssignedServicePoint[];

  constructor(data: RawAutomaticRules) {
    this.id = data.id || "";
    this.rules = data.rules.map((rule) => new Rule(rule));
    this.assignedServicePoints = data.assignedServicePoints
      ? data.assignedServicePoints.map((assignedServicePoints) => new AssignedServicePoint(assignedServicePoints))
      : [];
  }
}
