import { RawUserMachine } from "@/entities";

export class UserMachine {
  readonly id: string;
  readonly entity: string;
  readonly center: string;
  readonly servicePoint: string;
  readonly deviceName: string;
  readonly deviceId: string;

  constructor(data: RawUserMachine) {
    this.id = data.aggregateId;
    this.entity = data.entity;
    this.center = data.center;
    this.servicePoint = data.servicePoint;
    this.deviceName = data.servicePoint;
    this.deviceId = data.deviceId;
  }
}
