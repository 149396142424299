
import { Component, Prop, Vue } from "vue-property-decorator";
import { PButton, PFormSelect, PFormSelectSearchFilter, SelectType } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { User } from "@/entities";
import { TableHtml } from "@/components/commons";
import { FilterOperator } from "@/services";

@Component({
  name: "user-grid-alert",
  components: {
    PIcon,
    PButton,
    PFormSelect,
    PFormSelectSearchFilter,
  },
})
export class UserGridAlert extends Vue {
  @Prop({ required: true }) usersSelected!: User[];

  showmodal = false;
  filterProperties: SelectType[] = [];
  groupSelected = "";
  filters_field: Record<string, unknown>[] = [];
  defaultMap: Record<string, unknown> = {
    limit: 100,
    offset: 0,
    sortField: "lastUpdate",
    sortWay: "DESC",
    filters: [],
  };

  async handleSearchChange(value_Search: string) {
    this.defaultMap.filters = [{ field: "name", operator: FilterOperator.FULL_TEXT_SEARCH, value: value_Search }];
    this.defaultMap.sortField = "name";
    this.defaultMap.sortWay = "ASC";
    const response = await this.$services.group.fetchGroups(this.defaultMap);
    this.filterProperties = response.results
      .map((item) => ({ text: item.name, value: item.id }))
      .sort((a, b) => this.compareByInput(value_Search, a.text) - this.compareByInput(value_Search, b.text));
  }

  compareByInput(input: string, text: string): number {
    input = input.toLowerCase();
    text = text.toLowerCase();

    if (text.startsWith(input)) {
      return 0;
    }
    for (let i = input.length - 1; i >= 0; i--) {
      if (text.startsWith(input.substring(0, i))) {
        return input.length - i;
      }
    }
    return input.length;
  }

  hasUserSelected(): boolean {
    return this.usersSelected.length > 0;
  }

  userSelectedHasGroup(): boolean {
    return this.usersSelected.find((value) => value.group) != undefined;
  }

  addUsersToGroup(): void {
    if (this.groupSelected == "") return;
    const select: PFormSelect = this.$refs["pformselectUsrGrdAlert"] as PFormSelect;
    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
        cancelButton: "swal2_prosegur_cancel",
      },
      showCancelButton: true,
      cancelButtonText: this.$t("users.user_alert.cancel-button") as string,
      html: this.renderMessage(
        this.userSelectedHasGroup()
          ? (this.$t("users.user_alert.other-group-msg") as string)
          : (this.$t("users.user_alert.new-group-msg") as string),
        this.usersSelected
      ),
    }).then(async (response) => {
      if (response.isConfirmed) {
        await this.$services.userGroup.addMembers(
          this.usersSelected.map((user) => user.id as string),
          this.groupSelected
        );
        Vue.swal({
          icon: "success",
          title:
            (this.usersSelected.length > 1
              ? this.$t("users.user_alert.users-added")
              : this.$t("users.user_alert.user-added")) +
            " " +
            select.selectedText,
          showConfirmButton: false,
          timer: 2500,
        });
        this.closeModal();
        this.$emit("add-user-group");
      }
    });
  }

  private renderMessage(title: string, users: User[]): string {
    let htmlTable;
    if (this.userSelectedHasGroup()) {
      const headers: string[] = [this.$t("userPage.info.name") as string, this.$t("userPage.info.group") as string];
      type UserDataTable = { name: string; group: string };
      const usersMap: UserDataTable[] = users.map<UserDataTable>((user) => {
        return { name: user.fullName, group: user.group?.name || "" };
      });
      htmlTable = new TableHtml<UserDataTable>(headers, usersMap, title);
    } else {
      const headers: string[] = [this.$t("userPage.info.name") as string];
      type UserDataTable = { name: string };
      const usersMap: UserDataTable[] = users.map<UserDataTable>((user) => {
        return { name: user.fullName };
      });
      htmlTable = new TableHtml<UserDataTable>(headers, usersMap, title);
    }

    htmlTable.buildTable("user");
    return htmlTable.getTable();
  }

  get textoModal(): string {
    if (this.usersSelected.length > 1) {
      return this.$t("users.user_alert.add-users-group") as string;
    } else {
      return this.$t("users.user_alert.add-user-group") as string;
    }
  }
  closeAlert(): void {
    this.filterProperties = [];
    this.showmodal = false;
    this.$emit("close-alert");
  }
  closeModal(): void {
    this.filterProperties = [];
    this.showmodal = false;
  }
  showModal(): void {
    if (this.usersSelected.length == 1) {
      this.groupSelected = this.usersSelected[0].group?.id || "";
    } else {
      this.groupSelected = "";
    }
    this.showmodal = true;
  }
}
export default UserGridAlert;
