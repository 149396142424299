
import { Vue, Prop, Component } from "vue-property-decorator";
import { PButton } from "@/common/components/look";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { WizardConditions } from "./WizardConditions.vue";
import { Rule } from "@/entities";

@Component({
  components: {
    PButton,
    PIcon,
    WizardConditions,
  },
})
export default class Wizard extends Vue {
  @Prop({ required: true }) rules!: Rule[];
  @Prop({ required: true }) groupName!: string;

  launched = false;

  ShowWizard(): void {
    this.launched = true;
  }
  HideWizard(): void {
    this.launched = false;
  }
}
