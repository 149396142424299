
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MachineSearchFilter from "./machine-search-filter/MachineSearchFilter.vue";
import { IMachineFilter, iniMachineFilter } from "@/services";
import { Country, Delegation, RawMachineType, RawManufacturer, User } from "@/entities";
import { Getter } from "vuex-class";
import { SelectType } from "@/common/components";
import VueMethods from "@/vue-methods";
import { customDebounce } from "@/common/utils";

interface Filter {
  field: string;
  operator: string;
  value: string;
}
interface Query extends Record<string, unknown> {
  limit: number;
  offset: number;
  sortField: string;
  sortWay: string;
  filters: Filter[];
}
@Component({
  name: "machines-filters-wrapper",
  components: {
    MachineSearchFilter,
  },
  mixins: [VueMethods],
})
export class MachinesFiltersWrapper extends Vue {
  @Prop({ required: false }) value!: IMachineFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Getter("getCountries") getCountries!: Country[];
  @Getter("getLoggedUser") getLoggedUser!: User;

  valueLocal: IMachineFilter = { ...iniMachineFilter };
  loaded = false;
  manufactures: RawManufacturer[] = [];
  types: RawMachineType[] = [];
  delegations: SelectType[] = [];

  mounted(): void {
    Promise.all([this.searchManufactures(), this.searchTypes()]).then(() => {
      this.loaded = true;
      if (!this.getLoggedUser.corporateAdmin) {
        this.valueLocal.country = this.getLoggedUser.assignedCountries?.at(0) || "";
      }
    });
  }

  async searchManufactures(): Promise<void> {
    const res = await this.$services.machine.fetchManufacturers();
    if (res) {
      const machine = res.find((machine) => machine.name == "");
      if (machine) {
        machine.name = this.$t("machine.withoutManufacturer") as string;
      }
      res.sort((a, b) => this.sortAscendingIgnoreCase(a, b, "name"));
      this.manufactures = res;
    }
  }
  async searchTypes(): Promise<void> {
    const res = await this.$services.machine.fetchTypes();
    if (res) {
      this.types = res;
    }
  }

  async getDelegations(searchText = ""): Promise<void> {
    if (searchText.length > 3 || searchText.length === 1 || searchText.length === 2) return;
    const countryCode = this.valueLocal.country;
    const query: Query = {
      limit: 100,
      offset: 0,
      sortField: "code",
      sortWay: "ASC",
      filters: [],
    };
    query["filters"] = [{ field: "delegationCountryCode", operator: "FULL_TEXT_SEARCH", value: countryCode }];
    if (searchText.match(/^\w+$/i) && searchText.length > 0) {
      query["filters"].push({ field: "delegationCode", operator: "FULL_TEXT_SEARCH", value: searchText });
    }
    const response = await this.$services.machine.fetchDelegationSearch(query);
    const delegations = response.results;
    this.setDelegations(delegations);
  }

  setDelegations(delegations: Delegation[]): void {
    this.delegations = [];
    delegations.forEach((it: Delegation) => {
      if (it.code.length === 3) {       
        this.delegations.push({
          text: it.code + " - " + it.name,
          value: it.code,
          id: it.id,
          code: it.code,
          name: it.name,
        });
      }
    });
  }

  searchMachines(): void {
    customDebounce(() => {
      this.getDelegations();
      this.$emit("input", this.valueLocal);
    }, 100);
  }
}
export default MachinesFiltersWrapper;
