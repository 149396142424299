import { RawAssignedServicePoint } from "@/entities/assignedServicePoint";

export type RawUser = {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly email: string;
  readonly group?: RawGroupUser;
  readonly active: boolean;
  readonly status: string; // Values=>Pending, InProgress, Done
  readonly country: string;
  readonly fullName: string;
  readonly lastName: string;
  readonly assignedServicePoints: RawAssignedServicePoint[];
  readonly assignedCountries: string[];
  readonly corporateAdmin: boolean;
  readonly newUser?: boolean;
};

export type RawGroupUser = {
  readonly id: string;
  readonly name: string;
  readonly dateAdded: string;
};

export const iniRawGroupUser: RawGroupUser = {
  id: "",
  name: "",
  dateAdded: "",
};

export const iniRawUser: RawUser = {
  id: "",
  name: "",
  lastName: "",
  fullName: "",
  email: "",
  country: "",
  type: "",
  active: true,
  status: "Done", // Values=>Pending, InProgress, Done
  group: undefined,
  assignedServicePoints: Array.of(),
  assignedCountries: Array.of(),
  corporateAdmin: false,
};

export const STATUS_PENDING = "pending";
export const STATUS_DONE = "done";
export const STATUS_PENDING_BACK = "Pending";
export const STATUS_DONE_BACK = "Done";
