import { AssignedServicePoint } from "@/entities/assignedServicePoint";
import { RawUserServicePoints } from "./user_servicepoints.types";

export class UserServicePoints {
  readonly assignedServicePoints: AssignedServicePoint[];
  constructor(data: RawUserServicePoints) {
    this.assignedServicePoints = data.assignedServicePoints
      ? data.assignedServicePoints.map((value) => new AssignedServicePoint(value))
      : [];
  }
}
