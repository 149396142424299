import { Province, RawProvince } from "@/entities";

export const mockRawProvinces = (): RawProvince[] => [
  {
    id: "be89ee31-e052-4a19-ab7d-e399e87c1ca8",
    name: "Buenos Aires",
  },
  {
    id: "7d65854b-9964-467b-90e3-5bedc92e3cbb",
    name: "Madrid",
  },
];

export const mockProvince = (data: RawProvince[] = mockRawProvinces()): Province[] =>
  data.map((item) => new Province(item));
