import { RawDelegation } from "./delegation.type";

export class Delegation {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly countryCode: string;

  constructor(data: RawDelegation) {
    this.id = data.id || "";
    this.code = data.code || "";
    this.name = data.name || "";
    this.countryCode = data.countryCode || "";
  }
}
