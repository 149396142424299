
import { Component, Vue, Prop } from "vue-property-decorator";
import { RawSupportTransaction, SupportTransaction } from "@/entities";
import { PTableExtend, PFormCheckBox, PModal, PButton } from "@/common/components";
import { header_fields } from "./SupportPanelHeaderFields";
import { LocalPagination, NoData, DeepSet } from "@/components/commons";
import SupportPanelAlert from "@/components/support-panel/support-panel-alert/SupportPanelAlert.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { EventBus } from "@/event-bus";
import { i18n } from "@/i18n";

@Component({
  name: "support-panel-grid",
  i18n,
  components: {
    PIcon,
    PTableExtend,
    PFormCheckBox,
    NoData,
    LocalPagination,
    SupportPanelAlert,
    PModal,
    PButton,
  },
})
export class SupportPanelGrid extends Vue {
  @Prop({ required: false, default: 20 }) pageSize!: number;
  @Prop({ required: false, default: 1 }) currentPage!: number;

  Items: SupportTransaction[] = [];
  ItemsTemp: SupportTransaction[] = [];
  paramsfilter!: Record<string, unknown>;
  itemSize = 100;
  _pageSize!: number;
  _currentPage!: number;
  isLoading = true;
  showModal = false;
  showCompleted = false;
  title = "Detalle de transacción";
  lastColumnName = "Detalle transacción";
  jsonHtmlDetail = "<p></p>";
  transactionGroup: DeepSet<SupportTransaction> = new DeepSet<SupportTransaction>();
  nameFile = ".json";
  currentActualId = "";
  valor = "Completado";
  title1 = this.$i18n.t("support_panel.copytoclipboard");

  async created(): Promise<void> {
    this.paramsfilter = header_fields;
    const data = await this.$services.supportTransaction.fetchTransactions();
    this.itemSize = Number(data.toString());
    this.Items = data;
    this.paginate(this.$props.pageSize, this.$props.currentPage);
    this.ItemsTemp = this.Items.filter(
      (item: SupportTransaction) => item.status == "Pendiente" || item.status == "En progreso"
    );
    this.isLoading = false;
  }

  mounted(): void {
    EventBus.$on("search-support", this.search);
    EventBus.$on("filter-by-status-support", this.statusFilter);
    EventBus.$on("filter-by-status-support-clean", this.statusFilterReset);
  }

  statusFilter(gg: string[]): void {
    this.ItemsTemp = this.Items.filter(
      (item: SupportTransaction) => item.status == gg[0] || item.status == gg[1] || item.status == gg[2]
    );
  }

  statusFilterReset(): void {
    this.ItemsTemp = this.Items.filter(
      (item: SupportTransaction) => item.status == "Pendiente" || item.status == "En progreso"
    );
  }

  search(input: string): void {
    if (input == "") {
      this.ItemsTemp = this.Items;
      return;
    }
    const resultx = this.Items.filter((item: SupportTransaction) => {
      const itemKeyEntityId = (item[`entityId`] as string).toLowerCase();
      const itemKeyDeviceId = (item[`deviceId`] as string).toLowerCase();
      const itemKeyActualId = (item[`actualId`] as string).toLowerCase();

      return (
        itemKeyEntityId.includes(input.toLowerCase()) ||
        itemKeyDeviceId.includes(input.toLowerCase()) ||
        itemKeyActualId.includes(input.toLowerCase())
      );
    });

    this.ItemsTemp = resultx;
  }

  paginate(pageSize: number, currentPage: number): void {
    this._pageSize = pageSize;
    this._currentPage = currentPage;
    this.ItemsTemp = this.Items;
  }

  checkAll(): void {
    const checkboxAll = document.getElementById("select-all") as HTMLInputElement;
    const listOfChecks = document.querySelectorAll("input[type=checkbox]");
    listOfChecks.forEach((element: Element) => {
      if (checkboxAll.checked) {
        const chkElement: HTMLInputElement = element as HTMLInputElement;
        if (!chkElement.disabled) {
          (element as HTMLInputElement).checked = true;
        }
      } else {
        (element as HTMLInputElement).checked = false;
      }
    });
    this.$forceUpdate();
  }

  showJsonDetail(rowIndex: number): string {
    const transaction: SupportTransaction = this.ItemsTemp[rowIndex];
    const transactionDetail: string = (transaction.jsonDetail as string) || "";
    return JSON.stringify(transactionDetail.replace('\\"', '"'), null, "\t");
  }

  display(rowIndex: number): void {
    if (this.showModal && rowIndex == this.ItemsTemp.length - 1) return;
    this.jsonHtmlDetail = this.showJsonDetail(rowIndex);
  }

  openModal(rw: string): void {
    this.currentActualId = rw;
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
  }

  downloadJson(): void {
    const blob = new Blob([this.jsonHtmlDetail.replace(/<[^>]*>/g, "")], { type: "application/json" });
    const fileUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = this.currentActualId + this.nameFile;
    link.href = fileUrl;
    link.click();
  }

  showAlert(actualId: string): void {
    this.addTransaction(actualId);
    const checkedlist = document.querySelectorAll("input:checked").length;
    const transactionalert: SupportPanelAlert = this.$refs["transactionalert"] as SupportPanelAlert;
    if (checkedlist == 0) {
      transactionalert.closeAlert();
      transactionalert.message = "";
      return;
    }
    transactionalert.showAlert();
    transactionalert.message = checkedlist.toString();
  }

  addTransaction(actualId: string): void {
    const pformchkbox: PFormCheckBox = this.$refs["pformchkbox-" + actualId] as PFormCheckBox;
    const transactionRecord = this.Items.filter(
      (SupportTransaction: SupportTransaction) => SupportTransaction.actualId == actualId
    )[0];
    const transaction = new SupportTransaction(transactionRecord as RawSupportTransaction);
    if (pformchkbox.isChecked) {
      this.transactionGroup.add(transaction);
    } else {
      this.transactionGroup.delete(transaction);
    }
  }

  closeAlert(): void {
    const listOfChecks = document.querySelectorAll("input[type=checkbox]");
    listOfChecks.forEach((element: Element) => {
      (element as HTMLInputElement).checked = false;
    });
    this.clearTransactionsSelected();
    this.$forceUpdate();
  }

  clearTransactionsSelected(): void {
    this.transactionGroup.clear();
    const transactionalert: SupportPanelAlert = this.$refs["transactionalert"] as SupportPanelAlert;
    transactionalert.message = "";
    transactionalert.closeAlert();
    transactionalert.closeModal();
  }

  copyToCB(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copyText: any = document.getElementById("jsonText");
    if (copyText) {
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
    }
  }
}
export default SupportPanelGrid;
