
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType, PFormCheckBox } from "@/common/components";
import { i18n } from "@/i18n";
import { Group, IField, ServicePoint } from "@/entities";
import { Utilities } from "@/components/commons";
import { FilterOperator, PageRequest } from "@/services";
import PGridAlert from "@/common/components/look/PGridAlert/PGridAlert.vue";
import VueMethods from "@/vue-methods";

@Component({
  name: "manual-rules-grid-assign",
  i18n,
  components: {
    PFormCheckBox,
    PGridAlert,
  },
  mixins: [VueMethods],
})
export class ManualRulesGridAssign extends Vue {
  @Prop({ required: true }) group!: Group;
  @Watch("group", { immediate: true, deep: true })
  onChangeGroup(): void {
    this.fetchServicePoints();
  }
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.pagination.pageSize = 5;
    this.updatePaginationBack();
    this.mapFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    this.fetchServicePoints();
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }

  itemsSelected: ServicePoint[] = [];
  isCheckedAll = false;
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "id",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    type: "type",
    country: "countryCode",
    manufacturer: "manuFacturer",
    delegation: "delegationCode",
    text: "name",
  };
  servicePoints: ServicePoint[] = [];
  servicePointsAutomatic: ServicePoint[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };

  paginationDefault = {
    limit: 100,
  };

  mapFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: FilterOperator.FULL_TEXT_SEARCH,
            value: this.filters[key].constructor === Array ? this.filters[key].join() : this.filters[key],
          });
        }
      });
    }
    this.updateParams();
  }

  get haveServicePoints(): boolean {
    return this.servicePoints.length - this.servicePointsAutomatic.length > 0;
  }

  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  fetchServicePoints(): void {
    this.itemsSelected = [];
    this.isCheckedAll = false;
    this.servicePoints = [];
    this.servicePointsAutomatic = [];
    if (this.group.assignedServicePoints) {
      this.group.assignedServicePoints.forEach((value) => {
        this.servicePoints.push(value.servicePoint);
        if (value.associationType === "automatic-group") {
          this.servicePointsAutomatic.push(value.servicePoint);
        }
      });
    }
    this.pagination.totalElements = this.servicePoints.length;
  }

  isAutomaticServicePoint(servicePoint: ServicePoint): boolean {
    return this.servicePointsAutomatic.map((value) => value.id).includes(servicePoint.id);
  }

  rowClass(item: ServicePoint, type: string) {
    if (!item || type !== "row") return;
    if (this.isAutomaticServicePoint(item)) return "table-inactive";
  }

  get localPaginate(): ServicePoint[] {
    const sp = this.servicePoints;
    if (this.sort.sortDesc) {
      sp.sort((a, b) => this.sortDescendingIgnoreCase(a, b, this.sort.sortField));
    } else {
      sp.sort((a, b) => this.sortAscendingIgnoreCase(a, b, this.sort.sortField));
    }
    return sp.slice(this.paginationBack.offset, this.paginationBack.offset + this.paginationBack.limit);
  }

  closeAlert(): void {
    const listOfChecks = document.querySelectorAll("#manualRulesGridAssigned input[type=checkbox]");
    listOfChecks.forEach((element: Element) => {
      (element as HTMLInputElement).checked = false;
    });
    this.itemsSelected = [];
    this.isCheckedAll = false;
  }

  removeItems(): void {
    const servicePointsAssigned: string[] = [];
    if (this.group.assignedServicePoints) {
      this.group.assignedServicePoints.forEach((value) => {
        if (value.associationType != "automatic-group") {
          servicePointsAssigned.push(value.servicePoint.id);
        }
      });
    }
    this.itemsSelected.forEach((value) => {
      const index = servicePointsAssigned.indexOf(value.id);
      if (index >= 0) servicePointsAssigned.splice(index, 1);
    });
    this.$services.group
      .addCashTodays(this.group.id, servicePointsAssigned)
      .then(() =>
        Vue.swal({
          icon: "success",
          title: this.$t("label.update_successful"),
          showConfirmButton: false,
          timer: 2500,
        })
      )
      .then(() => {
        this.closeAlert();
        this.$emit("refresh");
      });
  }

  changeCheckAll(): void {
    this.isCheckedAll = this.itemsSelected.length == this.servicePoints.length - this.servicePointsAutomatic.length;
  }

  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.itemsSelected = [];
    if (this.isCheckedAll) {
      this.servicePoints.forEach((item) => {
        if (!this.isAutomaticServicePoint(item)) {
          this.itemsSelected.push(item);
        }
      });
    }
  }

  get fields(): IField[] {
    return [
      {
        key: "actions",
        label: "",
        sortable: false,
        class: "",
        thClass: "table-header table-header-checkbox",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: true,
        code: "",
      },
      {
        key: "code",
        label: this.$t("machine.table.detail.point-code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "name",
        label: this.$t("machine.table.detail.point") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "entity.countryCode",
        label: this.$t("machine.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "entity.code",
        label: this.$t("machine.table.detail.entity-code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "entity.name",
        label: this.$t("machine.table.detail.entity") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center.code",
        label: this.$t("machine.table.detail.center-code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center.name",
        label: this.$t("machine.table.detail.center") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "device.code",
        label: this.$t("machine.table.machine_id") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "device.delegationCode",
        label: this.$t("machine.table.delegation") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }
}
export default ManualRulesGridAssign;
