export type RawCenter = {
  readonly id: string;
  readonly entityId: string;
  readonly code: string;
  readonly name: string;
  readonly countryCode: string;
  readonly address?: string; //este atributo no existe, falta definir!
  readonly zipCode: string;
  readonly provinceName: string;
  readonly cityName: string;
  readonly serviceCount: number;
};

export const iniRawCenter: RawCenter = {
  id: "",
  entityId: "",
  code: "",
  name: "",
  countryCode: "",
  address: "",
  zipCode: "",
  provinceName: "",
  cityName: "",
  serviceCount: 0,
};
