import Vue from "vue";
import Vuex, { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IState } from "./store.types";

Vue.use(Vuex);

const state: IState = {
  version: process.env.VUE_APP_VERSION as string,
  locale: "",
  url: "",
  isSignedIn: false,
  loggedUser: undefined,
  countries: [],
};

export const useStore = (): Store<IState> =>
  new Vuex.Store<IState>({
    state,
    actions,
    mutations,
    getters,
    plugins: [createPersistedState({ paths: ["url", "locale", "countries"] })],
  });
