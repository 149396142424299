import { IRequestServicePointFilter } from "./servicepoint";

export const BACKEND_BASE_URL = process.env.VUE_APP_SVC_BACKEND_BASE_URL as string;
export const BACKEND_BASE_URL_READS = process.env.VUE_APP_SVC_BACKEND_READS_BASE_URL as string;

export type ApiError = {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
};

export type PageRequest = {
  limit: number;
  offset: number;
  sortField: string;
  sortWay: string;
  filters: Filter[];
};

export type PageRequestServicePoint = {
  limit: number;
  offset: number;
  filters: IRequestServicePointFilter;
  sortFields: SortField[];
};

export type Filter = {
  field: string;
  operator: FilterOperator;
  value: string;
};

export type SortField = {
  field: string;
  direction: string;
};

export enum FilterOperator {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  CONTAINS = "CONTAINS",
  NOT_CONTAINS = "NOT_CONTAINS",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_EQUALS = "LESS_THAN_EQUALS",
  IN = "IN",
  BETWEEN = "BETWEEN",
  FULL_TEXT_SEARCH = "FULL_TEXT_SEARCH",
}

export type SolutionDTO = {
  countryCode: string;
  solutionType: string;
  entityCode: string;
  entityName: string;
  centerCode: string;
  centerName: string;
  servicePointCode: string;
  servicePointName: string;
  delegationCode: string;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  deviceManufacturer: string;
  deviceModel: string;
  deviceIp: string;
  devicePort: string;
  deviceCurrencies: string[];
  zipCode: string;
  cityName: string;
  provinceName: string;
};

export type GroupDTO = {
  name: string;
  description: string;
};
