import { RawCurrency } from "./currency.type";

export class Currency {
  readonly isoCode: string;
  readonly description: string;

  constructor(data: RawCurrency) {
    this.isoCode = data.isoCode || "";
    this.description = data.description || "";
  }
}
