
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Utilities } from "@/components/commons";
import { FilterOperator, PageRequest } from "@/services";
import { IField, Machine } from "@/entities";
import VueFilters from "@/vue-filters";
import { iniPaginationPTable, PaginationPTableType } from "@/common/components";
import { i18n } from "@/i18n";
import MachineDetail from "@/components/machines/machine-grid/MachineDetail.vue";
import DateTimeMixin from "@/mixins/datetime";
import { customDebounce } from "@/common/utils";

@Component({
  name: "machine-grid",
  components: { MachineDetail },
  mixins: [VueFilters, DateTimeMixin],
})
export class MachineGrid extends Vue {
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapIMachineFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    customDebounce(() => this.getMachines(), 350);
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }

  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: false,
    sortField: "aggregateId",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(this.pagination.pageSize);
  mapFilters: Record<string, string> = {
    manufacturer: "deviceManufacturer",
    type: "deviceType",
    country: "deviceCountryCode",
    delegation: "deviceDelegationCode",
    text: "deviceName",
    code: "deviceCode",
  };
  machines: Machine[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };

  paginationDefault = {
    limit: 100,
  };

  mapIMachineFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: key != "text" && key != "code" ? FilterOperator.CONTAINS : FilterOperator.FULL_TEXT_SEARCH,
            value:
              this.filters[key].constructor === Array
                ? this.filters[key].join()
                : this.filters[key] == this.$t("machine.withoutManufacturer")
                ? "-"
                : this.filters[key],
          });
        }
      });
    }
    this.updateParams();
  }

  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async getMachines(): Promise<void> {
    const data = await this.$services.machine.fetchMachines(this.params);
    this.machines = data.results;
    this.pagination.totalElements = data.totalResult;
  }

  get fields(): IField[] {
    return [
      {
        key: "name",
        label: this.$t("machine.table.machine_name") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "code",
        label: this.$t("machine.table.machine_id") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "maker",
        label: this.$t("machine.table.producer") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "model",
        label: this.$t("machine.table.model") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "type",
        label: this.$t("machine.table.type") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "country",
        label: this.$t("machine.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "delegation",
        label: this.$t("machine.table.delegation") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "last_update",
        label: this.$t("machine.table.last_update") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "service_point_count",
        label: "",
        sortable: false,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle table-cell-expand",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }
}

export default MachineGrid;
