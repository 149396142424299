import { TranslateResult } from "vue-i18n";
import { Vue } from "vue-property-decorator";

export class SwalFactory {
  public static swalAlertSuccess = (title: TranslateResult | string, meth: () => void): void => {
    Vue.swal({
      icon: "success",
      title,
      showConfirmButton: false,
      timer: 2000,
    }).then(() => meth());
  };

  public static swalAlertWarning = (title: TranslateResult | string, meth: () => void): void => {
    Vue.swal({
      icon: "warning",
      title,
      showConfirmButton: false,
      timer: 2000,
    }).then(() => meth());
  };
}

export default SwalFactory;
