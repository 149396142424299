
import { Component, Vue } from "vue-property-decorator";
import { UserGrid, UserGroupGrid, UserTabs, UserLinkHidden, UserHeader, UserFilterWrapper } from "@/components/users";
import { UserGroupFilterWrapper } from "@/components/users/user-group-filter-wrapper/UserGroupFilterWrapper.vue";
import { IGroupFilter, iniGroupFilter, iniUserFilter, IUserFilter } from "@/services";
import { Getter } from "vuex-class";
import { User } from "@/entities";

@Component({
  components: {
    UserTabs,
    UserLinkHidden,
    UserHeader,
    UserGrid,
    UserFilterWrapper,
    UserGroupGrid,
    UserGroupFilterWrapper,
  },
})
export class Users extends Vue {
  @Getter("getLoggedUser") getLoggedUser!: User;

  userFilter: IUserFilter = { ...iniUserFilter };
  groupFilter: IGroupFilter = { ...iniGroupFilter };

  countResults: { users: number; groups: number } = {
    users: 0,
    groups: 0,
  };

  get filtrosUserToBack(): IUserFilter {
    const filtrosBack = { ...iniUserFilter };
    filtrosBack.type = this.userFilter.type;
    filtrosBack.status = this.userFilter.status;
    filtrosBack.country = this.userFilter.country;
    filtrosBack.group = this.userFilter.group;
    filtrosBack.enabled = this.userFilter.enabled;
    filtrosBack.text = this.userFilter.text;
    return filtrosBack;
  }

  get filtrosGroupToBack(): IGroupFilter {
    const filtrosBack = { ...iniGroupFilter };
    filtrosBack.group = this.groupFilter.group;
    return filtrosBack;
  }

  search(): void {
    this.groupFilter = { ...iniGroupFilter };
    this.groupFilter.forceRefresh = !this.groupFilter.forceRefresh;
  }
}
export default Users;
