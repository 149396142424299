
import { Vue, Component, Prop } from "vue-property-decorator";
import { PButton, PFormSelect } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { ServicePoint } from "@/entities";
import { TableHtml } from "@/components/commons";

@Component({
  name: "user-grid-alert",
  components: {
    PIcon,
    PButton,
    PFormSelect,
  },
})
export class GroupGridAlert extends Vue {
  @Prop({ required: true }) servicePointsSelected!: ServicePoint[];

  hasUserSelected(): boolean {
    return this.servicePointsSelected.length > 0;
  }

  addCashTodayToGroup(): void {
    const select: PFormSelect = this.$refs["pformselectUsrGrdAlert"] as PFormSelect;
    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
        cancelButton: "swal2_prosegur_cancel",
      },
      showCancelButton: true,
      cancelButtonText: this.$t("users.user_alert.cancel-button") as string,
      html: this.renderMessage(this.$t("groups.grid_alert.add_cashToday_title") as string, this.servicePointsSelected),
    }).then(async (response) => {
      if (response.isConfirmed) {
        Vue.swal({
          icon: "success",
          title:
            this.servicePointsSelected.length > 1
              ? this.$t("groups.grid_alert.cashTodays_added")
              : this.$t("groups.grid_alert.cashToday_added"),
          showConfirmButton: false,
          timer: 2500,
        });
        this.$emit("add-user-group");
      }
    });
  }

  private renderMessage(title: string, users: ServicePoint[]): string {
    const headers: string[] = [this.$t("userPage.info.name") as string];
    type UserDataTable = { name: string };
    const usersMap: UserDataTable[] = users.map<UserDataTable>((user) => {
      return { name: user.name as string };
    });
    const htmlTable: TableHtml<UserDataTable> = new TableHtml<UserDataTable>(headers, usersMap, title);
    htmlTable.buildTable("user");
    return htmlTable.getTable();
  }

  get textoModal(): string {
    if (this.servicePointsSelected.length > 1) {
      return this.$t("users.user_alert.add-users-group") as string;
    } else {
      return this.$t("users.user_alert.add-user-group") as string;
    }
  }
  closeAlert(): void {
    this.$emit("close-alert");
  }
}
export default GroupGridAlert;
