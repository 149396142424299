
import { Entity, IField } from "@/entities";
import { iniPaginationPTable, PaginationPTableType, PTableSimple } from "@/common/components";
import { PCol, PRow } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { LocalPagination, NoData, TitleSizePage, Utilities } from "@/components/commons";
import { FilterOperator, PageRequest } from "@/services";
import { i18n } from "@/i18n";

@Component({
  name: "entity-grid",
  components: {
    PRow,
    PCol,
    PTableSimple,
    LocalPagination,
    TitleSizePage,
    NoData,
  },
})
export class EntityGrid extends Vue {
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapIFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    this.fetch();
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }
  firstLoad = true;
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: false,
    sortField: "aggregateId",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(this.pagination.pageSize);
  mapFilters: Record<string, string> = {
    code: "entityCode",
    name: "entityName",
    country: "entityCountryCode",
  };
  entities: Entity[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };
  imageDefault = require("@/assets/images/imago-prosegur@3x.png");

  paginationDefault = {
    limit: 100,
  };

  mapIFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: key != "name" && key != "code" ? FilterOperator.CONTAINS : FilterOperator.FULL_TEXT_SEARCH,
            value: this.filters[key].constructor === Array ? this.filters[key].join() : this.filters[key],
          });
        }
      });
    }
    this.updateParams();
  }

  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async fetch(): Promise<void> {
    const data = await this.$services.entity.fetchEntities(this.params);
    this.entities = data.results;
    this.pagination.totalElements = data.totalResult;
  }

  get fields(): IField[] {
    return [
      {
        key: "name",
        label: this.$t("entity.table.entity") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "code",
        label: this.$t("entity.table.code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "center",
        label: this.$t("entity.table.centers") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "service_point",
        label: this.$t("entity.table.servicePoints") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "machine",
        label: this.$t("entity.table.machines") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "user",
        label: this.$t("entity.table.users") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "countryCode",
        label: this.$t("machine.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }

  goToEntity(row: Entity): void {
    this.$router.push({ name: "entity-centers", params: { id: row.id } });
  }

  imageUrlAlt(event: any) {
    event.target.src = this.imageDefault;
  }
}

export default EntityGrid;
