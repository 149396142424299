export const header_fields = {
  fields: [
    {
      key: "entity",
      type: "text",
      label: "Entidad",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
      // formatter: (id: string) => format(new Date(id), "dd/MM/yyyy HH:mm:ss"),
      width: "300px",
    },
    {
      key: "center",
      type: "text",
      label: "Centro",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
      width: "300px",
    },
    {
      key: "servicePoint",
      type: "text",
      label: "Punto de servicio",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "name",
      type: "text",
      label: "Device Name",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
    {
      key: "code",
      type: "text",
      label: "Device ID",
      filter: [],
      groupedField: false,
      fixColumn: false,
      show: true,
      showOrderField: true,
      showGroupedField: true,
      showHideField: true,
      showFilterField: false,
    },
  ],
};
