
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationObserver,
  },
})
export class PForm extends Vue {
  @Prop({ required: true }) value!: boolean;

  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.validadoLocal = this.value;
  }

  @Ref("observer") readonly observer!: any;
  @Prop({ required: false, default: "accept" }) action!: string;
  @Prop({ required: false, default: "Accept" }) textAction!: string;
  @Prop({ required: false, default: "Cancel" }) textCancel!: string;
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: "center" }) buttonsAlign!: string;

  validadoLocal = false;

  async datosModificados(): Promise<void> {
    if (this.observer) {
      this.$emit("submit", this.validadoLocal);
    }
  }

  get alignButtons(): string {
    return this.buttonsAlign;
  }

  cancel(): void {
    this.$emit("cancel");
  }

  accept(): void {
    this.$emit("accept", this.action);
  }
}

export default PForm;
