
import { Component, Vue } from "vue-property-decorator";
import { EntityFilters, EntityGrid, EntityHeader } from "@/components";
import { IEntityFilter, iniEntityFilter } from "@/services";

@Component({
  components: {
    EntityHeader,
    EntityFilters,
    EntityGrid,
  },
})
export class EntityPage extends Vue {
  filters: IEntityFilter = { ...iniEntityFilter };
}

export default EntityPage;
