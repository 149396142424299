import { Machine, MachineDetail, MachineServicePoint, MachineType, Manufacturer } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { Delegation } from "@/entities/delegation";

export interface MachineService {
  fetchMachines(map: Record<string, unknown>): Promise<Pageable<Machine>>;
  fetchManufacturers(): Promise<Manufacturer[]>;
  fetchTypes(): Promise<MachineType[]>;
  fetchDelegations(): Promise<Delegation[]>;
  fetchDelegationSearch(map: Record<string, unknown>): Promise<Pageable<Delegation>>;
  fetchMachineServicePoint(deviceId: string): Promise<Pageable<MachineServicePoint>>;
  fetchMachinesByAutomaticRules(map: Record<string, unknown>): Promise<Pageable<MachineDetail>>;
  createMachine(file: FormData): Promise<any>;
  downloadSolutionTemplate(): Promise<File>;
  fetchMachinesByManualRules(map: Record<string, unknown>): Promise<Pageable<MachineDetail>>;
}

export interface IMachineFilter {
  text?: string;
  manufacturer: string;
  type: string;
  country: string;
  delegation: string;
  forceRefresh: boolean;
  code?: string;
}

export const iniMachineFilter: IMachineFilter = {
  text: "",
  manufacturer: "",
  type: "",
  country: "",
  delegation: "",
  forceRefresh: false,
  code: "",
};

export interface MachineServiceMock {
  fetchMachines: jest.Mock<Promise<Pageable<Machine>>>;
  fetchManufacturers: jest.Mock<Promise<Manufacturer[]>>;
  fetchTypes: jest.Mock<Promise<MachineType[]>>;
  fetchDelegations: jest.Mock<Promise<Delegation[]>>;
  fetchDelegationSearch: jest.Mock<Promise<Pageable<Delegation>>>;
  fetchMachineServicePoint: jest.Mock<Promise<Pageable<MachineServicePoint>>>;
  fetchMachinesByAutomaticRules: jest.Mock<Promise<Pageable<MachineDetail>>>;
  createMachine: jest.Mock<Promise<void>>;
  downloadSolutionTemplate: jest.Mock<Promise<File>>;
  fetchMachinesByManualRules: jest.Mock<Promise<Pageable<MachineDetail>>>;
}
