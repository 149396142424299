
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { EventBus } from "@/event-bus";
import { i18n } from "@/i18n";

@Component({
  name: "group-members-tab",
  i18n,
  components: {
    "b-tabs": BTabs,
    "b-tab": BTab,
  },
})
export class GroupMembersTab extends Vue {
  @Prop({ required: true }) value!: { users: number };

  get titleMembers(): string {
    const users = this.value?.users ?? 0;
    return `${this.$t("groups.members_tab")} (${users})`;
  }
}
export default GroupMembersTab;
