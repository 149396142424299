
import { Component, Vue } from "vue-property-decorator";
import SupportActualIdFilter from "./support-actual-id-filter/SupportActualIdFilter.vue";
import SupportEntityIdFilter from "./support-entity-id-filter/SupportEntityIdFilter.vue";
import SupportDeviceIdFilter from "./support-device-id-filter/SupportDeviceIdFilter.vue";
import SupportStatusFilter from "./support-status-filter/SupportStatusFilter.vue";
import SupportSearchFilter from "./support-search-filter/SupportSearchFilter.vue";

@Component({
  name: "support-filters-wrapper",
  components: {
    SupportEntityIdFilter,
    SupportDeviceIdFilter,
    SupportStatusFilter,
    SupportSearchFilter,
    SupportActualIdFilter,
  },
})
export class SupportFiltersWrapper extends Vue {}
export default SupportFiltersWrapper;
