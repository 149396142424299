
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  components: {
    PDropDownContainer,
    PIcon,
  },
})
export class PTabs extends Vue {
  @Prop({ required: false, default: 0 }) value!: number;
  @Prop({ required: false, default: 0 }) showNumberTabs!: number;
  @Prop({ required: true }) id!: string;
  @Prop({ required: false, default: "Add" }) addText!: string;
  tabsTitles: string[] = [];
  tabsIcons: string[] = [];
  tabsShow: boolean[] = [];
  tabSelected = 0;
  divTabs: any = [];
  showNumberTabsLocal = 0;
  mounted(): void {
    this.tabSelected = this.value;
    this.showNumberTabsLocal = this.showNumberTabs;
    this.init();
  }
  init(): void {
    const divId = document.getElementById(this.id);

    if (divId) {
      this.tabsTitles = [];
      this.tabsIcons = [];
      this.divTabs = divId.getElementsByClassName("content_PTab");
      const tabs = divId.getElementsByClassName("title_PTab");
      const icons = divId.getElementsByClassName("icon_PTab");
      let numTabsView = tabs.length;
      if (this.showNumberTabsLocal > 0 && this.showNumberTabsLocal <= numTabsView) {
        numTabsView = this.showNumberTabsLocal;
      }
      for (var i = 0; i < tabs.length; i++) {
        this.tabsIcons.push(icons[i].innerHTML);
        this.tabsTitles.push(tabs[i].innerHTML);
        if (numTabsView > i) {
          if (!this.tabsShow[i]) {
            this.tabsShow.push(true);
          }
        } else {
          if (!this.tabsShow[i]) {
            this.tabsShow.push(false);
          }
        }
      }
    }
    this.selectTap(this.tabSelected);
  }
  isSelected(index: number): string {
    if (this.tabSelected == index) {
      return "tapTitleSelected_PTabs";
    } else {
      return "";
    }
  }
  get showAdd(): boolean {
    if (this.showNumberTabs == 0 || this.showNumberTabs >= this.tabsTitles.length) {
      return false;
    } else {
      return true;
    }
  }
  showTap(index: number): void {
    this.init();
    if (this.showNumberTabsLocal == index && this.tabsShow[index]) {
      this.tabSelected = 0;
    }
    this.tabsShow[index] = !this.tabsShow[index];
    this.selectTap(index);
  }
  selectTap(index: number): void {
    this.tabSelected = index;
    if (this.divTabs) {
      for (var i = 0; i < this.divTabs.length; i++) {
        if (index == i) {
          this.divTabs[i]?.setAttribute("style", "display:block");
        } else {
          this.divTabs[i]?.setAttribute("style", "display:none");
        }
      }
      // se agrego porque se necesita saber que tab se hizo click.
      this.$emit("click", index);
    }
  }
}
export default PTabs;
