
import { Component, Vue } from "vue-property-decorator";
import UserDetailHeader from "@/components/user-detail/user-detail-header/UserDetailHeader.vue";
import UserDetailTab from "@/components/user-detail/user-detail-tab/UserDetailTab.vue";
import UserDetailNav from "@/components/user-detail/user-detail-nav/UserDetailNav.vue";
import UserDetailGroup from "@/components/user-detail/user-detail-group/UserDetailGroup.vue";
import UserDetailInfo from "@/components/user-detail/user-detail-info/UserDetailInfo.vue";
import UserDetailMachinesTab from "@/components/user-detail/user-detail-machines-tab/UserDetailMachinesTab.vue";
import { User, UserStatus } from "@/entities";
import UserGridAlert from "@/components/users/user-grid-alert/UserGridAlert.vue";
import { AssignedServicePoint } from "@/entities/assignedServicePoint";
import { UserServicePoints } from "@/entities/user-servicepoints";

@Component({
  components: {
    UserDetailHeader,
    UserDetailTab,
    UserDetailNav,
    UserDetailGroup,
    UserDetailInfo,
    UserDetailMachinesTab,
    UserGridAlert,
  },
})
export class UserPage extends Vue {
  user!: User;
  assignedServicePoints: AssignedServicePoint[] = [];
  userId!: string;
  loaded = false;
  update = false;
  firstLoad = true;

  created(): void {
    this.userId = this.$route.params.id;
  }

  mounted(): void {
    this.fetchUserCustomer();
  }

  fetchUserCustomer(): void {
    this.$services.user
      .fetchUserCustomer(this.userId)
      .then((res: User) => {
        if (res) {
          this.loaded = true;
          this.user = res;
          this.update = !this.update;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchUserCustomerServicepoints(): void {
    this.$services.user
      .fetchUserCustomerServicepoints(this.userId)
      .then((res: UserServicePoints) => {
        if (res) {
          this.assignedServicePoints = res.assignedServicePoints;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  isActive(item: User): boolean {
    if (!item.active) return false;
    return item.status !== UserStatus.PENDING;
  }

  onTabSelected(tab: any) {
    if (tab === "machines" && this.firstLoad) {
      this.fetchUserCustomerServicepoints();
      this.firstLoad = false;
    }
  }
}

export default UserPage;
