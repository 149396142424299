export class DeepSet<T> extends Set<T> {
  add(o: T) {
    for (const i of this) if (this.deepCompare(o, i)) return this;
    super.add.call(this, o);
    return this;
  }

  delete(o: T): boolean {
    for (const i of this) if (this.deepCompare(o, i)) return super.delete(i);
    return false;
  }

  private deepCompare(o: T, i: T) {
    return JSON.stringify(o) === JSON.stringify(i);
  }
}
