export interface FieldPTableType {
  [key: string]: any;
  key: string;
  type: string;
  groupField: string;
  label: string;
  filter: string[];
  groupedField: boolean;
  fixColumn: boolean;
  show: boolean;
  showOrderField: boolean;
  showGroupedField: boolean;
  showHideField: boolean;
  showFilterField: boolean;
  width?: string;
  formatter?: Function;
}

export interface PaginationPTableType {
  pageSize: number;
  pageSizeDefault?: number;
  totalElements: number;
  currentPage: number;
}

export const iniPaginationPTable: PaginationPTableType = {
  pageSize: 20,
  totalElements: 0,
  currentPage: 1,
}

export interface OrderPTableType {
  orderField: string;
  orderDirection: string;
  groupedFields: string[];
}

export interface PTableType {
  fields: FieldPTableType[];
  pagination: PaginationPTableType;
  order: OrderPTableType;
}
