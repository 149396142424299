import { Country, RawCountry } from "@/entities";

export const mockRawCountries = (): RawCountry[] => [
  {
    name: "España",
    isoCode: "ES",
  },
  {
    name: "Argentina",
    isoCode: "AR",
  },
  {
    name: "Brasil",
    isoCode: "BR",
  },
  {
    name: "Chile",
    isoCode: "CH",
  },
];

export const mockCountries = (data: RawCountry[] = mockRawCountries()): Country[] =>
  data.map((item) => new Country(item));
