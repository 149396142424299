import { Country, User } from "@/entities";
import { IGetters, IState } from "@/store";

export const getters: IGetters = {
  // Root
  getLocale(state: IState): string {
    return state.locale;
  },

  getUrl(state: IState): string {
    return state.url;
  },

  getIsSignedIn(state: IState): boolean {
    return state.isSignedIn;
  },

  // User
  getLoggedUser(state: IState): User | undefined {
    if (!state.loggedUser) {
      return;
    }

    return state.loggedUser;
  },

  // Country
  getCountries(state: IState): Country[] {
    return state.countries;
  },
};
