import Vue from "vue";
import { Entity, RawEntity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { BACKEND_BASE_URL_READS } from "../backend.types";
import { EntityService } from "./entity.types";
import { RawPage } from "@/entities/pageable/pageable_types";

export class EntityServiceImpl implements EntityService {
  async fetchEntities(map: Record<string, unknown>): Promise<Pageable<Entity>> {
    const { data } = await Vue.$axios.post<RawPage<RawEntity>>(`${BACKEND_BASE_URL_READS}/api/v1/me/entities`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawEntity) => new Entity(rawEntity)) ?? [],
    };
  }
}
