
import { Component, Vue, Prop } from "vue-property-decorator";
import { GroupNav, GroupLastUpdate, GroupHeader } from "@/components/groups";
import { RawGroup } from "@/entities";

@Component({
  name: "group-header-wrapper",
  components: {
    GroupNav,
    GroupLastUpdate,
    GroupHeader,
  },
})
export class GroupHeaderWrapper extends Vue {
  @Prop({ required: true }) group!: RawGroup;

  get lastUpdate(): string {
    return this.group.lastUpdate;
  }
}

export default GroupHeaderWrapper;
