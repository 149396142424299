import { UserServiceMock } from "./user.types";

export const mockUserService = (): UserServiceMock => ({
  fetchMe: jest.fn(),
  updateMe: jest.fn(),
  updateUserById: jest.fn(),
  fetchUsers: jest.fn(),
  fetchUsersV2: jest.fn(),
  fetchUser: jest.fn(),
  fetchUserCustomer: jest.fn(),
  fetchUserCustomerServicepoints: jest.fn(),
  updateUserStatus: jest.fn(),
  addCashTodays: jest.fn(),
});
