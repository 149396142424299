import Vue from "vue";
import Pageable from "@/entities/pageable/pageable_collection";
import { RawServicePoint, ServicePoint } from "@/entities";
import { BACKEND_BASE_URL, BACKEND_BASE_URL_READS} from "../backend.types";
import { ServicePointService } from "@/services";
import { RawPage } from "@/entities/pageable/pageable_types";

export class ServicePointServiceImpl implements ServicePointService {
  async fetchServicePoints(map: Record<string, unknown>): Promise<Pageable<ServicePoint>> {
    const { data } = await Vue.$axios.post<RawPage<RawServicePoint>>(`${BACKEND_BASE_URL_READS}/api/v2/servicepoints`, map);
    if (!data.results && data.servicepoints) {
      data.results = data.servicepoints;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawServicePoint) => new ServicePoint(rawServicePoint)) ?? [],
    };
  }

  async fetchServicePointsByGroupRules(groupId: string, map: Record<string, unknown>): Promise<Pageable<ServicePoint>> {
    const { data } = await Vue.$axios.post<RawPage<RawServicePoint>>(
      `${BACKEND_BASE_URL_READS}/api/v1/group/${groupId}/rules/servicepoints`,
      map
    );
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawServicePoint) => new ServicePoint(rawServicePoint)) ?? [],
    };
  }
}
