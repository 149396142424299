
import { Modal, PLoading, SessionExpiredModal } from "@/common/components";
import { User } from "@/entities";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { EventBus } from "./event-bus";

@Component({
  name: "app",
  components: {
    SessionExpiredModal,
    Modal,
    PLoading,
  },
})
export default class App extends Vue {
  @Getter("getIsSignedIn") getIsSignedIn!: boolean;
  @Getter("getLoggedUser") getLoggedUser!: User;
  @Action("setLocale") setLocale!: (locale: string) => void;

  accepted: boolean = localStorage.getItem("acceptCookies") === "true";
  loadings = 0;
  disable = false;

  constructor() {
    super();
  }

  get isLoading(): boolean {
    return this.loadings > 0;
  }

  async created(): Promise<void> {
    this.setLocale(this.$i18n.locale);

    Vue.$axios.interceptors.request.use(
      (config) => {
        this.loadings++;
        return config;
      },
      (error) => {
        this.loadings--;
        return Promise.reject(error);
      }
    );

    Vue.$axios.interceptors.response.use(
      (response) => {
        this.loadings--;
        return response;
      },
      (error) => {
        this.loadings--;
        return Promise.reject(error);
      }
    );
  }

  mounted(): void {
    EventBus.$on("language-change", (value: string) => {
      {
        this.$i18n.locale = value;
        this.setLocale(value);
        document.cookie = `LOCALE=${value}; path=/`;
        const html = document.documentElement;
        html.setAttribute("lang", value);
      }
    });
    EventBus.$on("disable-loading", (value: boolean) => {
      this.disable = value;
    });
  }

  acceptCookies(): void {
    localStorage.setItem("acceptCookies", "true");
    this.accepted = true;
  }
}
