
import { Vue, Component } from "vue-property-decorator";
import MachineGrid from "../machine-grid/MachineGrid.vue";
import SolutionHistoryGrid from "@/components/solution-history/solution-history-grid/SolutionHistoryGrid.vue";
import MachineImportNewModal from "../machine-import-new-modal/MachineImportNewModal.vue";

@Component({
  name: "machine-tabs",
  components: {
    MachineGrid,
    SolutionHistoryGrid,
    MachineImportNewModal,
  },
})
export class MachineTabs extends Vue {
  titleCashToday = "Cash Today";
  titleRegisterSolution = "Alta de Solución";

  cashGridShowContent(value: string): void {
    this.titleCashToday = "Cash Today (" + value + ")";
  }
}
export default MachineTabs;
