
import { Component, Vue } from "vue-property-decorator";
import { PFormInputText, PDropDownContainer, PButton, SelectType, PFormCheckBox } from "@/common/components";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { EventBus } from "@/event-bus";

@Component({
  name: "support-drop-down-status",
  components: {
    PRow,
    PButton,
    PFormInputText,
    PDropDownContainer,
    PFormCheckBox,
    PIcon,
  },
})
export class SupportDropDownStatus extends Vue {
  options: SelectType[] = [];
  optionsSelected: string[] = [];
  link = "link";
  allCheck = false;
  isCheckedPending = false;
  isCheckedInProgress = false;
  isCheckedDone = false;
  Pendiente = "Pendiente";
  EnProgreso = "En progreso";
  Completado = "Completado";

  async created(): Promise<void> {
    const result1: string[] = await this.$services.supportTransaction.fetchStatus();
    result1.forEach((it: string) => this.options.push({ text: it.toString(), value: it.toString() }));
  }

  reset(): void {
    this.allCheck = false;
    this.isCheckedPending = false;
    this.isCheckedInProgress = false;
    this.isCheckedDone = false;
    this.optionsSelected = [];
    EventBus.$emit("filter-by-status-support-clean", this.optionsSelected);
  }

  filter(): void {
    if (this.optionsSelected.length == 0) {
      alert("Debe seleccionar por lo menos una opción");
      return;
    } else {
      EventBus.$emit("filter-by-status-support", this.optionsSelected);
    }
  }

  selectAll(): void {
    if (this.allCheck == false) {
      this.allCheck = true;
      this.isCheckedPending = true;
      this.isCheckedInProgress = true;
      this.isCheckedDone = true;
      this.optionsSelected.push("Pendiente", "En progreso", "Completado");
    } else {
      this.allCheck = false;
      this.isCheckedPending = false;
      this.isCheckedInProgress = false;
      this.isCheckedDone = false;
      this.optionsSelected = [];
    }
  }

  changePending(): void {
    this.isCheckedPending = !this.isCheckedPending;
    if (this.isCheckedPending == true) {
      this.optionsSelected.push("Pendiente");
    } else {
      this.optionsSelected = this.optionsSelected.filter((item) => item !== "Pendiente");
    }
  }

  changeInProgress(): void {
    this.isCheckedInProgress = !this.isCheckedInProgress;
    if (this.isCheckedInProgress == true) {
      this.optionsSelected.push("En progreso");
    } else {
      this.optionsSelected = this.optionsSelected.filter((item) => item !== "En progreso");
    }
  }

  changeDone(): void {
    this.isCheckedDone = !this.isCheckedDone;
    if (this.isCheckedDone == true) {
      this.optionsSelected.push("Completado");
    } else {
      this.optionsSelected = this.optionsSelected.filter((item) => item !== "Completado");
    }
  }
}
export default SupportDropDownStatus;
