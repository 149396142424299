import { RawLocation } from "@/entities";

export class Location {
  readonly id: string;
  readonly name: string;
  readonly country: string;
  readonly city: string;

  constructor(data: RawLocation) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.country = data.country || "";
    this.city = data.city || "";
  }
}
