import { Point, RawPoint } from "@/entities";

export const mockRawPoints = (): RawPoint[] => [
  {
    id: "39defcf3-24d2-4f89-9d33-978ad60caa68",
    name: "ESPAÑA",
    entity: "Zara ES",
    center: 164,
    code: "1234",
  },
  {
    id: "33f1a478-ad6e-498d-8dd1-ac1b11545675",
    name: "ARGENTINA",
    entity: "Cencosud AR",
    center: 456,
    code: "2323",
  },
  {
    id: "abe2e200-1006-416a-a504-e3207f65f85b",
    name: "BRAZIL",
    entity: "Unicenter BR",
    center: 3434,
    code: "4400",
  },
];

export const mockPoints = (data: RawPoint[] = mockRawPoints()): Point[] => data.map((item) => new Point(item));
