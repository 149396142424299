
import { Vue, Component, Prop } from "vue-property-decorator";
import { RawGroup } from "@/entities";

@Component({
  name: "group-nav",
})
export class GroupNav extends Vue {
  @Prop({ required: false, default: {} }) group!: RawGroup;

  goToUsers(): void {
    this.$router.push("/users");
  }
}
export default GroupNav;
