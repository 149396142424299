
import { Component, Vue } from "vue-property-decorator";
import { PDropDownContainer } from "@/common/components";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import SupportDropDownStatus from "./SupportDropDownStatus.vue";

@Component({
  name: "support-status-filter",
  components: {
    PRow,
    PIcon,
    PDropDownContainer,
    SupportDropDownStatus,
  },
})
export default class SupportStatusFilter extends Vue {}
