
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "p-check-box-dropdown",
})
export class PCheckboxDropdown extends Vue {
  @Prop({ required: true }) options!: Array<{ value: string; text: string }>;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: false, default: [] }) existingValues!: string[];
  @Prop({ required: false, default: false }) readonly!: boolean;
  isOpen = false;
  selectedValues: string[] = this.existingValues || [];
  
  // Watch for changes in existingValues
  @Watch('existingValues', { immediate: true })
  onExistingValuesChanged(newValues: string[]): void {
    this.selectedValues = [...newValues];
  }


  get displayText(): string {
    return this.selectedValues.length > 0
      ? this.selectedValues.join(', ')
      : this.placeholder;
  }

  toggleDropdown(): void {
    if (!this.readonly) return;
    this.isOpen = !this.isOpen;
  }

  updateSelectedValues(newValues: any[]): void {
    this.selectedValues = newValues;
    this.$emit('update:selectedValues', this.selectedValues);
  }

  isSelected(value: string): boolean {
    return this.selectedValues.includes(value);
  }
}
export default PCheckboxDropdown;
