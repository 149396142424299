import { IActionsMock, IRootState, IState, mockGetters } from "@/store";
import { ActionContext } from "vuex";
import { mockRootState, mockState } from "../store.mock";

export const mockActionsContext = (): ActionContext<IState, IRootState> => ({
  commit: jest.fn(),
  dispatch: jest.fn(),
  state: mockState(),
  getters: mockGetters(),
  rootState: mockRootState(),
  rootGetters: {},
});

export const mockActions = (): IActionsMock => ({
  // Root
  initialize: jest.fn(),
  setUrl: jest.fn(),
  setLocale: jest.fn(),
  //Auth
  setSignedIn: jest.fn(),
  // User
  fetchLoggedUser: jest.fn(),
  updateLoggedUser: jest.fn(),

  // Auth

  // I118n
  fetchI18n: jest.fn(),

  // Country
  fetchCountries: jest.fn(),
});
