
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  components: {
    PIcon,
  },
})
export class PSidebar extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.showSidebar = this.value;
  }
  @Prop({ required: false, default: "" }) width!: string;
  @Prop({ required: false, default: "" }) title!: string;

  showSidebar = false;

  get widthComp(): string {
    if (this.width != "") {
      return "width:" + this.width + ";";
    } else {
      return "";
    }
  }
  close(): void {
    this.showSidebar = false;
    this.$emit("input", this.showSidebar);
  }
}
export default PSidebar;
