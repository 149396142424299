export const SolutionFileHistoryReportFields = {
  fields : [
    {
      field: "id",
      label: "id",
    },
    {
      field: "serviceOrigin",
      label: "serviceOrigin",
    },
    {
      field: "serviceDate",
      label: "serviceDate",
    },
    {
      field: "countryCode",
      label: "countryCode",
    },
    {
      field: "solutionStatus",
      label: "solutionStatus",
    },
    {
      field: "solutionType",
      label: "solutionType",
    },
    {
      field: "deviceId",
      label: "deviceId",
    },
    {
      field: "deviceName",
      label: "deviceName",
    },
    {
      field: "deviceType",
      label: "deviceType",
    },
    {
      field: "deviceManufacturer",
      label: "deviceManufacturer",
    },
    {
      field: "deviceModel",
      label: "deviceModel",
    },
    {
      field: "deviceIp",
      label: "deviceIp",
    },
    {
      field: "devicePort",
      label: "devicePort",
    },
    {
      field: "deviceCurrencies",
      label: "deviceCurrencies",
    },
    {
      field: "deviceIsMulticlient",
      label: "deviceIsMulticlient",
    },
    {
      field: "deviceIsEarlyValue",
      label: "deviceIsEarlyValue",
    },
    {
      field: "manufacturerNoteCapacity",
      label: "manufacturerNoteCapacity",
    },
    {
      field: "realNoteCapacity",
      label: "realNoteCapacity",
    },
    {
      field: "prosegurNoteCapacity",
      label: "prosegurNoteCapacity",
    },
    {
      field: "cityName",
      label: "cityName",
    },
    {
      field: "provinceName",
      label: "provinceName",
    },
    {
      field: "zipCode",
      label: "zipCode",
    },
    {
      field: "delegationCode",
      label: "delegationCode",
    },
    {
      field: "delegationName",
      label: "delegationName",
    },
    {
      field: "entityCode",
      label: "entityCode",
    },
    {
      field: "entityName",
      label: "entityName",
    },
    {
      field: "centerCode",
      label: "centerCode",
    },
    {
      field: "centerName",
      label: "centerName",
    },
    {
      field: "servicePointCode",
      label: "servicePointCode",
    },
    {
      field: "servicePointName",
      label: "servicePointName",
    },
    {
      field: "status",
      label: "status",
    },
    {
      field: "statusDescriptionErrors",
      label: "statusDescriptionErrors",
    },
    {
      field: "solutionFileHistoryId",
      label: "solutionFileHistoryId",
    },
  ]  
}