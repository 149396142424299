import { RawLocality } from "@/entities";

export class Locality {
  readonly id: string;
  readonly name: string;

  constructor(data: RawLocality) {
    this.id = data.id || "";
    this.name = data.name || "";
  }
}
