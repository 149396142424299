
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class PCol extends Vue {
  @Prop({ required: false }) md!: string;
  get giveMeClass(): string {
    if (this.md) {
      return "divPCol_" + this.md + " divPColPadding";
    } else {
      return "divPCol divPColPadding";
    }
  }
}
export default PCol;
