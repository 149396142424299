
import { Component, Prop, Vue } from "vue-property-decorator";
import { i18n } from "@/i18n";
import { PButton, PTableSimple } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import Wizard from "@/components/rules/wizard/Wizard.vue";
import ConditionsContainer from "./conditions-container/ConditionsContainer.vue";
import VisualizationToggle from "./visualization-toggle/VisualizationToggle.vue";
import AutomaticRulesGrid from "./automatic-rules-grid/AutomaticRulesGrid.vue";
import { Group, Rule } from "@/entities";
import { EventBus } from "@/event-bus";
import ManualRulesGrid from "./manual-rules-grid/ManualRulesGrid.vue";
import ManualRulesGridAssign from "./manual-rules-grid-assign/ManualRulesGridAssign.vue";
import { MachinesFiltersWrapper } from "@/components/machines";
import { CashTodayFilter } from "@/components/groups/group-permissions-tab/cashtoday-filter/CashTodayFilter.vue";
import { IServicePointFilter, iniServicePointFilter } from "@/services";

@Component({
  name: "group-permissions-tab",
  i18n,
  components: {
    PButton,
    PIcon,
    Wizard,
    ConditionsContainer,
    VisualizationToggle,
    AutomaticRulesGrid,
    PTableSimple,
    ManualRulesGrid,
    ManualRulesGridAssign,
    MachinesFiltersWrapper,
    CashTodayFilter,
  },
})
export class GroupPermissionsTab extends Vue {
  @Prop({ required: true }) group!: Group;
  @Prop({ required: true }) update!: boolean;

  tabTitle = this.$i18n.t("groups.permissions.tab_title");
  link = "link";
  isCheckedPermissions = false;
  isCheckedPermissionsMachine = false;
  isCheckedAutomaticSettings = false;
  isCheckManualRule = false;
  isCheckAutomaticRule = false;
  isAutomaticSelected = "";
  isManualSelected = "";
  rules: Rule[] = [];
  isLoading = true;
  filters: IServicePointFilter = { ...iniServicePointFilter };
  filtersAssign = { text: "" };

  created(): void {
    this.refresh();
  }

  refreshGroup(): void {
    this.$emit("refresh");
  }

  assignedManualGroup(data: boolean): void {
    this.isManualSelected = this.isCheckManualRule || data ? "Manual" : "";
    this.isCheckedPermissionsMachine =
      this.isCheckedPermissionsMachine ||
      this.group.assignedServicePoints?.length > 0 ||
      this.rules?.length > 0 ||
      data;
    this.isCheckManualRule = this.isCheckManualRule || data;
  }

  async refresh(): Promise<void> {
    const result = await this.$services.group.fetchAutomaticRules(this.group.id);
    this.rules = result.rules;
    this.rules.forEach((element) => {
      if (element.fieldName == "countryCode") {
        const translationLabel = `country.${element.fieldValue.toLowerCase()}`;
        element.showValue = this.$t(translationLabel) as string;
        if (element.showValue === translationLabel) {
          element.showValue = `${element.showValue}`;
        }
      }
    });
    this.isCheckedPermissionsMachine =
      this.isCheckedPermissionsMachine || this.group.assignedServicePoints?.length > 0 || result.rules?.length > 0;
    this.isAutomaticSelected = result.rules?.length > 0 ? "Automatic" : "";
    this.isLoading = false;
  }

  checkManualRules(): void {
    this.isCheckManualRule = true;
  }

  mounted(): void {
    EventBus.$on("delete-automatic-condition", this.deleteAutomaticCondition);
    EventBus.$on("delete-all-automatic-rules", this.deleteAllAutomaticRules);
    EventBus.$on("group-conditions-saved", this.replaceRules);
  }

  beforeDestroy(): void {
    EventBus.$off("delete-automatic-condition", this.deleteAutomaticCondition);
    EventBus.$off("delete-all-automatic-rules", this.deleteAllAutomaticRules);
    EventBus.$off("group-conditions-saved", this.replaceRules);
  }

  togglePermissions(): void {
    this.isCheckedPermissions = !this.isCheckedPermissions;
  }

  showWizard(): void {
    const wizard: Wizard = this.$refs["pWizard"] as Wizard;
    wizard?.ShowWizard();
  }

  async replaceRules(rules: Rule[]): Promise<void> {
    if (!rules[rules.length - 1].fieldName) {
      rules.pop();
    }
    await this.$services.group.addRules(this.group.id, rules);

    const wizard: Wizard = this.$refs["pWizard"] as Wizard;
    wizard?.HideWizard();

    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
      },
      icon: "success",
      title: this.$t("groups.permissions.save_success"),
      showConfirmButton: true,
      confirmButtonText: this.$t("action.accept") as string,
    }).then(() => {
      this.$router.go(0);
    });
    await this.refresh();
  }

  async removeRuleById(idToRemove: string) {
    const index = this.rules.findIndex((rule) => rule.id === idToRemove);
    if (index !== -1) {
      this.rules.splice(index, 1);
    }
  }
  async deleteAutomaticCondition(conditionId: string): Promise<void> {
    await this.removeRuleById(conditionId);
    await this.$services.group.addRules(this.group.id, this.rules);
    await this.refresh();

    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
      },
      icon: "success",
      title: this.$t("groups.permissions.save_success"),
      showConfirmButton: true,
      confirmButtonText: this.$t("action.accept") as string,
    }).then(() => {
      this.$router.go(0);
    });
  }

  async deleteAllAutomaticRules(): Promise<void> {
    await this.$services.group.deleteAllAutomaticRules(this.group.id);
    await this.refresh();

    Vue.swal({
      customClass: {
        confirmButton: "swal2_prosegur_confirm",
      },
      icon: "success",
      title: this.$t("groups.permissions.save_success"),
      showConfirmButton: true,
      confirmButtonText: this.$t("action.accept") as string,
    }).then(() => {
      this.$router.go(0);
    });
  }
}
export default GroupPermissionsTab;
