import { RawRole } from "@/entities";

export class Role {
  readonly id?: number;
  readonly code?: string;

  constructor(data: RawRole) {
    this.id = data.id || 0;
    this.code = data.code || "";
  }
}
