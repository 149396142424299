
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityFilter, iniEntityFilter } from "@/services";
import { Getter } from "vuex-class";
import { Country, User } from "@/entities";

@Component({
  name: "entity-filters",
})
export class EntityFilters extends Vue {
  @Prop({ required: false }) value!: IEntityFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Getter("getCountries") getCountries!: Country[];
  @Getter("getLoggedUser") getLoggedUser!: User;

  valueLocal: IEntityFilter = { ...iniEntityFilter };

  search(): void {
    this.$emit("input", this.valueLocal);
  }
}
export default EntityFilters;
