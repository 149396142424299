import { RawUser, User } from "@/entities";
import { BACKEND_BASE_URL, BACKEND_BASE_URL_READS } from "../backend.types";
import Pageable from "@/entities/pageable/pageable_collection";
import Vue from "vue";
import { UserService } from "@/services";
import { RawPage } from "@/entities/pageable/pageable_types";
import { RawUserServicePoints, UserServicePoints } from "@/entities/user-servicepoints";
import { SearchCustomerRequest } from "@/components";

export class UserServiceImpl implements UserService {
  async fetchMe(): Promise<User> {
    const { data } = await Vue.$axios.get<RawUser>(`${BACKEND_BASE_URL_READS}/api/v1/me`);
    return new User(data);
  }

  async fetchUserCustomer(userId: string): Promise<User> {
    const { data } = await Vue.$axios.get<RawUser>(`${BACKEND_BASE_URL_READS}/api/v1/users/${userId}`);
    return new User(data);
  }

  async fetchUserCustomerServicepoints(userId: string): Promise<UserServicePoints> {
    const { data } = await Vue.$axios.get<RawUserServicePoints>(
      `${BACKEND_BASE_URL_READS}/api/v1/users/${userId}/servicepoints`
    );
    return new UserServicePoints(data);
  }

  async updateMe(): Promise<User> {
    const { data } = await Vue.$axios.patch<RawUser>(`${BACKEND_BASE_URL}/api/v1/me`);
    return new User(data);
  }

  async updateUserById(userId: string, map: Record<string, unknown>): Promise<void> {
    await Vue.$axios.patch<RawUser>(`${BACKEND_BASE_URL}/api/v1/user/${userId}`, map);
  }

  async fetchUsers(map: Record<string, unknown>): Promise<Pageable<User>> {
    const { data } = await Vue.$axios.post<RawPage<RawUser>>(`${BACKEND_BASE_URL_READS}/api/v1/users`, map);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawUser) => new User(rawUser)) ?? [],
    };
  }

  async fetchUsersV2(request: SearchCustomerRequest): Promise<Pageable<User>> {
    const { data } = await Vue.$axios.post<RawPage<RawUser>>(`${BACKEND_BASE_URL_READS}/api/v2/users`, request);
    if (!data.results && data.projections) {
      data.results = data.projections;
    }
    return {
      totalResult: data.totalResult,
      results: data.results?.map((rawUser) => new User(rawUser)) ?? [],
    };
  }

  async fetchUser(id: string): Promise<User> {
    const map: Record<string, unknown> = {
      limit: 1,
      offset: 0,
      sortField: "id",
      sortWay: "ASC",
      filters: [{ field: "id", operator: "equals", value: id }],
    };
    const { data } = await Vue.$axios.post<RawPage<RawUser>>(`${BACKEND_BASE_URL_READS}/api/v1/users`, map);
    const result = data.results || data.projections;
    return new User(result[0]);
  }

  async updateUserStatus(userId: string, status: string) {
    const map: Record<string, unknown> = {
      aggregateId: userId,
      customerStatus: status,
    };

    await Vue.$axios.patch(`${BACKEND_BASE_URL}/api/v1/user/status`, map);
  }

  async addCashTodays(userId: string, cashTodays: string[]): Promise<void> {
    await Vue.$axios.put(`${BACKEND_BASE_URL}/api/v1/users/${userId}/servicepoints`, cashTodays);
  }
}
