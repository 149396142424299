import { Device, RawDevice } from "@/entities";

export const mockRawDevices = (): RawDevice[] => [
  {
    id: "289e126a-de22-3376-9a03-cd6f9e8e80a1",
    ip: "10.28.51.79",
    code: "98234000170",
    name: "EDS MEDELLIN UNIVERSIDAD",
    port: "1288",
    type: "Multicenter",
    model: "SDM 504",
    currencies: ["COP"],
    lastUpdate: "2023-04-10T16:22:54.945579500-03:00[America/Buenos_Aires]",
    countryCode: "CO",
    manufacturer: "CIMA",
    noteCapacity: 10000,
    solutionType: "Dep├│sito f├ícil",
    isMulticlient: false,
    delegationCode: "3",
    servicePointCount: 2,
  },
];

export const mockDevices = (data: RawDevice[] = mockRawDevices()): Device[] => data.map((item) => new Device(item));
