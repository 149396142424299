
import { Component, Prop, Vue } from "vue-property-decorator";
import { PRow, PCol } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { CenterTabs } from "@/components/centers";
import { PFormInputText } from "@/common/components";
import { RawGroup } from "@/entities";

@Component({
  name: "group-header",
  components: {
    PRow,
    PCol,
    CenterTabs,
    PFormInputText,
  },
})
export class GroupHeader extends Vue {
  @Prop({ required: true }) group!: RawGroup;

  goToUsers(): void {
    this.$router.push("/users");
  }
}
export default GroupHeader;
