
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "visualization-toggle",
  components: {},
})
export class VisualizationToggle extends Vue {
  isCheckedPermissions = false;
}
export default VisualizationToggle;
