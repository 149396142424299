
import { Component, Vue } from "vue-property-decorator";
import {
  SupportTabs,
  SupportPanelHeader,
  SupportPanelGrid,
  SupportFiltersWrapper,
  SolutionHistoryGrid,
} from "@/components";
import { iniSolutionHistoryFilter, ISolutionHistoryFilter } from "@/services";
import SolutionHistoryFilterWrapper from "@/components/solution-history/solution-history-filter-wrapper/SolutionHistoryFilterWrapper.vue";

@Component({
  name: "support-page",
  components: {
    SupportPanelHeader,
    SupportPanelGrid,
    SupportFiltersWrapper,
    SupportTabs,
    SolutionHistoryGrid,
    SolutionHistoryFilterWrapper,
  },
})
export class SupportPage extends Vue {
  solutionHistoryFilter: ISolutionHistoryFilter = { ...iniSolutionHistoryFilter };
}
export default SupportPage;
