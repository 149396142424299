
import Utilities from "@/components/commons/Utilities";
import { fi } from "date-fns/locale";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { PTableSimpleType, FieldPTableSimpleType } from "../PTableSimple/pTableSimple.type";
import { PTableSimpleRowColorize } from "../PTableSimple/PTableSimpleRowColorize";
@Component({
  components: {
    PIcon,
  },
})
export class PTableSimple extends Vue {
  @Prop({ required: true }) value!: PTableSimpleType;
  @Prop({ required: true }) items!: any[];
  @Prop({ required: false, default: "" }) personalizeClass!: string;
  @Prop({ required: false, default: false }) hasColorizeRow!: boolean;
  @Prop({ required: false, default: undefined }) colorizeObject!: PTableSimpleRowColorize;

  @Watch("value", { deep: true, immediate: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.fieldsFormatter = this.fieldsFormatter.concat(this.value.fields);
  }

  valueLocal: PTableSimpleType = this.getDefaultValues();
  fieldsFormatter: FieldPTableSimpleType[] = [];
  orderByField: string = "";
  direction: boolean = true;

  formatFiled(field: any, item: any): any {
    let spanHtml = document.createElement("span");
    const filedTemp: any = this.fieldsFormatter.find((fi: FieldPTableSimpleType) => fi.key == field.key);
    if (filedTemp?.eventName) spanHtml.addEventListener(filedTemp.eventName, filedTemp.eventFunction);

    if (filedTemp?.formatter) {
      return (spanHtml.innerHTML = filedTemp.formatter(item[field.key]));
    } else {
      return (spanHtml.innerHTML = item[field.key]);
    }
  }

  propertiesExistAndHaveData(items: any[]): boolean {
    const emptydata: boolean = false;
    const propertiesAreOK: boolean = true;
    if (items.length == 0) return emptydata;
    items.forEach((item) => {
      const itmBool = item != undefined && Object(item).length > 0;
      if (!itmBool) return emptydata;
    });
    return propertiesAreOK;
  }
  getStringValue(item?: string): string {
    return item == undefined ? "" : item;
  }
  getBooleanValue(item?: boolean): boolean {
    return item == undefined ? false : item;
  }

  setInactiveColorRowByItemCondition(item: any): string {
    const emptyReturn: string = "";
    const itemHasData: boolean = this.propertiesExistAndHaveData([
      this.colorizeObject.fieldsKeyInactive,
      this.colorizeObject.fieldValueInactiveRow,
      this.colorizeObject.inactiveCSSStyle,
    ]);
    if (itemHasData) {
      const field: string = this.getStringValue(this.colorizeObject.fieldsKeyInactive);
      const fieldvalue: boolean = this.getBooleanValue(this.colorizeObject.fieldValueInactiveRow);
      const cssInactive: string = this.getStringValue(this.colorizeObject.inactiveCSSStyle);
      const fieldsExists: boolean = item[field] != undefined;
      if (fieldsExists && fieldvalue == Boolean(item[field])) {
        return cssInactive;
      }
    }
    return emptyReturn;
  }
  setColorizeRowByItemCondition(item: any): string {
    const emptyReturn: string = "";
    if (this.colorizeObject == undefined || !this.hasColorizeRow) return emptyReturn;
    const rowInactiveStyle: string = this.setInactiveColorRowByItemCondition(item);
    if (rowInactiveStyle.length > 0) return rowInactiveStyle;
    const field: string = this.colorizeObject.fieldKey;
    const fieldsExists: boolean = item[field] != undefined;
    const itemsHasData: boolean = this.colorizeObject.itemData.length > 0;
    if (fieldsExists && !itemsHasData) {
      return emptyReturn;
    } else {
      for (const element of this.colorizeObject.itemData) {
        const _itemdata = element;
        if (_itemdata.fieldItem.toLowerCase() == item[field].toLowerCase()) return _itemdata.fieldColor;
      }
      return emptyReturn;
    }
  }

  fireClick(item: any): void {
    this.$emit("click", item);
  }

  checkAll(): void {
    this.$emit("checkAll");
  }

  toogleOrderIcon(fieldKey: string): void {
    const htmlSpan: Vue = this.$refs[fieldKey] as Vue;
    if (htmlSpan == undefined) return;

    let valueSpan: Element = Array.isArray(htmlSpan) ? htmlSpan[0] : htmlSpan;
    if (valueSpan == undefined) return;

    if (valueSpan.innerHTML == "▼") {
      this.direction = true;
      valueSpan.innerHTML = "▲";
    } else {
      valueSpan.innerHTML = "▼";
      this.direction = false;
    }
    this.orderByField = fieldKey;
    this.$emit('sort-changed', { sortField: this.orderByField, sortOrder: this.direction ? 'ASC' : 'DESC' });
  }

  get itemsComp(): any[] {
    let itemsTemp: any[] = this.items.slice();
    return itemsTemp.sort((a, b) => {
      return this.orderItems(a, b, this.orderByField, this.direction);
    });
  }

  existData(): boolean {
    return this.items.length > 0;
  }

  orderItems(a: Record<string, any>, b: Record<string, any>, fieldKey: string, direction: boolean): number {
    return Utilities.sort(a, b, fieldKey, direction);
  }

  get fields(): FieldPTableSimpleType[] {
    if (this.value.fields == undefined) return [];
    else return this.value.fields.filter((fie: FieldPTableSimpleType) => fie?.show);
  }

  private getDefaultValues(): PTableSimpleType {
    return {
      fields: [],
    };
  }
}
export default PTableSimple;
