
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "user-link-hidden",
  components: {},
})
export class UserLinkHidden extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.enabled = JSON.parse(JSON.stringify(this.value));
  }

  enabled = true;

  toggleActiveUsers(): void {
    this.enabled = !this.enabled;
    this.$emit("input", this.enabled);
  }
}

export default UserLinkHidden;
