
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "p-check-box-multi",
})
export class PFormCheckBoxMulti extends Vue {
  @Prop({ required: true }) options!: Array<{ value: any, text: string }>;
  @Prop({ required: true }) selectedValues!: any[];

  isSelected(value: any): boolean {
    return this.selectedValues.includes(value);
  }

  isSelectedValue(value: any): string {
    if(this.isSelected(value)) return value;
    return '';
  }

  toggleSelection(value: any): void {
    let newSelectedValues = [...this.selectedValues];

    if (this.isSelected(value)) {
      // Remove the value if it is already selected
      newSelectedValues = newSelectedValues.filter(v => v !== value);
    } else {
      // Add the value if it is not selected
      newSelectedValues.push(value);
    }

    this.$emit('update:selectedValues', newSelectedValues);
  }
}
export default PFormCheckBoxMulti;
