import { render, staticRenderFns } from "./SupportDropDownActualId.vue?vue&type=template&id=c1746a12&scoped=true&"
import script from "./SupportDropDownActualId.vue?vue&type=script&lang=ts&"
export * from "./SupportDropDownActualId.vue?vue&type=script&lang=ts&"
import style0 from "./SupportDropDownActualId.vue?vue&type=style&index=0&id=c1746a12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1746a12",
  null
  
)

export default component.exports