
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { PFormInputTextSearch, PFormSelect } from "@/common/components";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { FilterOperator, IServicePointFilter, iniServicePointFilter } from "@/services";
import { Country, Entity } from "@/entities";
import { Getter } from "vuex-class";

@Component({
  name: "user-detail-machines-header",
  components: {
    PFormInputTextSearch,
    PFormSelect,
    PIcon,
  },
})
export class CashTodayFilter extends Vue {
  @Prop({ required: true }) value!: IServicePointFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Getter("getCountries") getCountries!: Country[];

  valueLocal: IServicePointFilter = { ...iniServicePointFilter };
  entities: Entity[] = [];
  defaultMap: Record<string, unknown> = {
    limit: 100,
    offset: 0,
    sortField: "lastUpdate",
    sortWay: "DESC",
    filters: [],
  };
  isButtonDisabled = true;
  previousCountryCode = "";

  created(): void {
    this.fetch();
  }

  async fetch(): Promise<void> {
    const data = await this.$services.entity.fetchEntities(this.defaultMap);
    this.entities = data.results;
  }

  validateTextFilter(): void {
    const previousCountryCode = this.previousCountryCode || "";
    const currentCountryCode = this.valueLocal.countryCode || "";
    const hasValidText = this.valueLocal.text && this.valueLocal.text.length >= 3;
    const hasCountryCode = !!this.valueLocal.countryCode;
    const hasEntityCode = !!this.valueLocal.entityCode;
    this.isButtonDisabled = !(hasValidText || hasCountryCode || hasEntityCode);
    if (previousCountryCode !== currentCountryCode) {
      this.defaultMap.filters = [
        { field: "entityCountryCode", operator: FilterOperator.CONTAINS, value: currentCountryCode },
      ];
      this.fetch();
    }
    this.previousCountryCode = currentCountryCode;
  }

  search(): void {
    this.$emit("input", this.valueLocal);
  }
}
export default CashTodayFilter;
