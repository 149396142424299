import { RawMachineDetail } from "@/entities";

export class MachineDetail {
  readonly id: string;
  readonly name: string;
  readonly entity: string;
  readonly servicePoint: string;
  readonly code: string;
  readonly center: string;

  constructor(data: RawMachineDetail) {
    this.id = data.id || "";
    this.name = data.name || "";
    this.entity = data.entity || "";
    this.servicePoint = data.servicePoint || "";
    this.center = data.center || "";
    this.code = data.code || "";
  }
}
