import { AutomaticRules, Group, Rule, UserGroup } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";
import { GroupDTO } from "@/services";

export interface GroupService {
  fetchGroups(map?: Record<string, unknown>): Promise<Pageable<Group>>;
  fetchUsersGroup(map: Record<string, unknown>): Promise<UserGroup>;
  fetchGroup(id: string): Promise<Group>;
  createGroup(group: GroupDTO): Promise<void>;
  updateGroupDescription(groupDescription: string, groupId: string): Promise<void>;
  renameGroup(groupId: string, newGroupName: string): Promise<void>;
  deleteGroup(groupId: string): Promise<void>;
  fetchAutomaticRules(groupId: string): Promise<AutomaticRules>;
  addRules(groupId: string, rules: Rule[]): Promise<void>;
  deleteAutomaticCondition(groupId: string, conditionId: string): Promise<void>;
  deleteAllAutomaticRules(groupId: string): Promise<void>;
  addCashTodays(groupId: string, cashTodays: string[]): Promise<void>;
}

export interface IGroupFilter {
  group: string;
  forceRefresh: boolean;
}
export const iniGroupFilter: IGroupFilter = {
  group: "",
  forceRefresh: false,
};

export interface GroupServiceMock {
  fetchGroups: jest.Mock<Promise<Pageable<Group>>>;
  fetchUsersGroup: jest.Mock<Promise<UserGroup>>;
  fetchGroup: jest.Mock<Promise<Group>>;
  createGroup: jest.Mock<Promise<void>>;
  updateGroupDescription: jest.Mock<Promise<void>>;
  renameGroup: jest.Mock<Promise<void>>;
  deleteGroup: jest.Mock<Promise<void>>;
  fetchAutomaticRules: jest.Mock<Promise<AutomaticRules>>;
  addRules: jest.Mock<Promise<void>>;
  deleteAutomaticCondition: jest.Mock<Promise<void>>;
  deleteAllAutomaticRules: jest.Mock<Promise<void>>;
  addCashTodays: jest.Mock<Promise<void>>;
}
