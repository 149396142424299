import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";

import "material-icons/iconfont/material-icons.scss";
import "./assets/scss/_index.scss";
import "@/plugins";

import { prepareI18n, i18n } from "./i18n";
import { useRouter } from "./router";
import { useStore } from "./store";
import { prepareServices } from "./services";

import App from "./App.vue";
import "./registerServiceWorker";
import { addAxiosInterceptors } from "@/plugins/axios";

const APP_VERSION = process.env.VUE_APP_VERSION as string;
console.info(`Version: ${APP_VERSION}`);

export const store = useStore();
const router = useRouter(store);

// Axios
addAxiosInterceptors(router);
Vue.config.productionTip = false;

prepareServices(store);
prepareI18n().finally(() => {
  new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
// const handleAuthenticationStatusChange = async (isLogged: boolean) => {
//   await store.dispatch("setSignedIn", isLogged);
//   if (isLogged) {
//     await store.dispatch("initialize");
//   }
// };
//
// Vue.$auth.getUser().then(async (user) => {
//   await handleAuthenticationStatusChange(!!user);
// });
//
// Vue.$auth.events.addUserLoaded(async (user) => {
//   await handleAuthenticationStatusChange(!!user);
// });
//
// Vue.$auth.events.removeUserLoaded(async () => {
//   await handleAuthenticationStatusChange(false);
// });
