import { render, staticRenderFns } from "./GroupFilterWrapper.vue?vue&type=template&id=baa4b630&scoped=true&"
import script from "./GroupFilterWrapper.vue?vue&type=script&lang=ts&"
export * from "./GroupFilterWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./GroupFilterWrapper.vue?vue&type=style&index=0&id=baa4b630&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa4b630",
  null
  
)

export default component.exports