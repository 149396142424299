
import { i18n } from "@/i18n";
import { IField, User, UserStatus } from "@/entities";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType, PFormCheckBox, PPagination } from "@/common/components";
import { Utilities } from "@/components/commons";
import { FilterOperator, PageRequest } from "@/services";
import GroupGridOptions from "@/components/groups/group-grid/group-grid-options/GroupGridOptions.vue";
import VueFilters from "@/vue-filters";
import DateTimeMixin from "@/mixins/datetime";
import PGridAlert from "@/common/components/look/PGridAlert/PGridAlert.vue";
import { customDebounce } from "@/common/utils";
import { EventBus } from "@/event-bus";
import UserInitials from "@/components/users/user-initials/UserInitials.vue";

@Component({
  name: "group-grid",
  i18n,
  components: {
    PFormCheckBox,
    GroupGridOptions,
    UserInitials,
    PGridAlert,
    PPagination,
  },
  mixins: [VueFilters, DateTimeMixin],
})
export class GroupGrid extends Vue {
  @Prop({ required: true }) value!: { users: number };

  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Prop({ required: false, default: "" }) localFilter!: string;
  @Prop({ required: true }) filters!: any;

  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapIUserFilterToRecord();
  }

  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    if (this.firstLoad) {
      this.getUsers();
      this.firstLoad = false;
    } else customDebounce(() => this.getUsers(), 350);
  }

  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }
  firstLoad = true;
  usersSelected: User[] = [];
  isCheckedAll = false;
  valueLocal: { users: number } = {
    users: 0,
  };
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "status",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    text: "fullName",
    group: "group,id",
  };
  users: User[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };

  paginationDefault = {
    limit: 100,
  };

  created(): void {
    EventBus.$on("search-users", (value: User[]) => {
      value.map((user: any) => {
        this.users.unshift(new User(user));
      });
    });
    EventBus.$on("cancel-users", (value: User[]) => {
      value.map(() => {
        this.users = this.users.filter((user) => !value.some((v: User) => v.id === user.id));
      });
    });
  }

  getStatus(item: User): string {
    if (!item.active) return this.$t("users.table.disabled") as string;
    if (item.status === UserStatus.PENDING) return this.$t("users.table.pending") as string;
    return this.$t("users.table.active") as string;
  }

  getStatusColor(item: User): string {
    if (!item.active) return "#6f6f6f";
    if (item.status === UserStatus.PENDING) return "#f5a623";
    return "#b6c608";
  }

  rowClass(item: User, type: string) {
    if (!item || type !== "row") return;
    if (!item.active) return "table-inactive";
  }

  isActive(item: User): boolean {
    if (!item.active) return false;
    if (item.status === UserStatus.PENDING) return false;
    return true;
  }

  get filterUsers(): User[] | undefined {
    return this.users?.filter(
      (opt: User) =>
        opt.fullName.toLowerCase().indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.fullName
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.email.toLowerCase().indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.email
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.id.toLowerCase().indexOf(this.localFilter.toLowerCase()) >= 0 ||
        opt.id
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .indexOf(this.localFilter.toLowerCase()) >= 0
    );
  }

  mapIUserFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: FilterOperator.FULL_TEXT_SEARCH,
            value: this.filters[key].constructor === Array ? this.filters[key].join() : this.filters[key],
          });
        }
      });
    }
    this.updateParams();
  }

  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async getUsers(): Promise<void> {
    const data = await this.$services.user.fetchUsers(this.params);
    this.users = data.results;
    this.pagination.totalElements = data.totalResult;
    this.valueLocal.users = data.totalResult;
    this.$emit("input", this.valueLocal);
  }

  action(data: { action: string; data: User }): void {
    if (data.action === "info") {
      this.goToUserPage(data.data);
    } else if (data.action === "delete") {
      this.removeUsers();
    }
  }

  changeCheckAll(): void {
    this.isCheckedAll = this.usersSelected.length == this.users?.length;
  }

  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.usersSelected = [];
    if (this.isCheckedAll) {
      this.users?.forEach((item) => {
        this.usersSelected.push(item);
      });
    }
  }

  closeAlert(): void {
    const listOfChecks = document.querySelectorAll("input[type=checkbox]");
    listOfChecks.forEach((element: Element) => {
      (element as HTMLInputElement).checked = false;
    });
    this.usersSelected = [];
    this.isCheckedAll = false;
  }

  get fields(): IField[] {
    return [
      {
        key: "actions",
        label: "",
        sortable: false,
        class: "",
        thClass: "table-header table-header-checkbox",
        tdClass: "table-cell-first table-cell",
        visible: true,
        aux: true,
        code: "",
      },
      {
        key: "fullName",
        label: this.$t("users.table.fullName") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "email",
        label: this.$t("users.table.email") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "country",
        label: this.$t("users.table.country") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "status",
        label: this.$t("users.table.status") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }

  goToUserPage(row: User): void {
    this.$router.push({ name: "user-page", params: { id: row.id } });
  }

  removeUsers(): void {
    this.$services.userGroup
      .removeMembers(
        this.usersSelected.map((value1) => value1.id),
        this.filters.group
      )
      .then(() => {
        this.closeAlert();
        Vue.swal({
          icon: "success",
          title: this.$t("groups.update_successful"),
          showConfirmButton: false,
          timer: 2500,
        }).then(() => this.getUsers());
      });
  }
}
export default GroupGrid;
