
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Group, iniRawGroup } from "@/entities";

@Component({
  components: {},
})
export class GroupForm extends Vue {
  @Prop({ required: true }) value!: Group;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.group = JSON.parse(JSON.stringify(this.value));
  }
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Prop({ required: false }) textCancelName!: string;
  @Prop({ required: false, default: false }) isRenameGroup!: string;
  showModal = false;

  group: Group = new Group({ ...iniRawGroup });
  validado = false;

  cancel(): void {
    this.$emit("cancel");
  }

  action(): void {
    this.$emit("action", this.group);
  }
}

export default GroupForm;
