import { Entity } from "@/entities";
import Pageable from "@/entities/pageable/pageable_collection";

export interface EntityService {
  fetchEntities(map: Record<string, unknown>): Promise<Pageable<Entity>>;
}

export interface EntityServiceMock {
  fetchEntities: jest.Mock<Promise<Pageable<Entity>>>;
}

export interface IEntityFilter {
  code?: string;
  name?: string;
  country?: string;
}

export const iniEntityFilter: IEntityFilter = {
  code: "",
  name: "",
  country: "",
};
