
import { Vue, Component } from "vue-property-decorator";
import UserNewGroupModal from "../user-new-group-modal/UserNewGroupModal.vue";

@Component({
  name: "user-header",
  components: {
    UserNewGroupModal,
  },
})
export class UserHeader extends Vue {
  search(): void {
    this.$emit("action");
  }
}
export default UserHeader;
