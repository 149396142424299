export type RawEntity = {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly imageLink: string;
  readonly centerCount: number;
  readonly servicePointCount: number;
  readonly logicCashTodayCount: number;
  readonly customerCount: number;
  readonly countryCode: string;
};

export const iniRawEntity: RawEntity = {
  id: "",
  name: "",
  code: "",
  imageLink: "",
  centerCount: 0,
  servicePointCount: 0,
  logicCashTodayCount: 0,
  customerCount: 0,
  countryCode: "",
};
