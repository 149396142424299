
import { Vue, Component, Prop } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";
import { RawUser } from "@/entities";
import { i18n } from "@/i18n";

@Component({
  i18n,
  components: {
    "b-tabs": BTabs,
    "b-tab": BTab,
  },
})
export class UserDetailTab extends Vue {
  @Prop({ required: true }) user!: RawUser;

  titleInfo = this.$i18n.t("userPage.tab_info");
  titleMachines = this.$i18n.t("userPage.tab_machines");
  isActive = false;

  mounted(): void {
    if (this.user.status.toLowerCase() === "done") {
      this.isActive = true;
    }
  }

  selectTab(tab: string) {
    this.$emit("tab-selected", tab);
  }
}

export default UserDetailTab;
