
import { i18n } from "@/i18n";
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType } from "@/common/components";
import { FilterOperator, PageRequest } from "@/services";
import Utilities from "@/components/commons/Utilities";
import { Group, IField, iniRawGroup } from "@/entities";
import VueFilters from "@/vue-filters";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { GroupGridOptions, GroupForm } from "@/components";
import { TableHtml } from "@/components/commons";
import { customDebounce } from "@/common/utils";

@Component({
  name: "user-group-grid",
  i18n,
  components: { PIcon, GroupForm, GroupGridOptions },
  mixins: [VueFilters],
})
export class UserGroupGrid extends Vue {
  @Prop({ required: true }) value!: { users: number; groups: number };
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapFilterToRecord();
  }

  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    let counterValidFilters = 0;

    Object.keys(this.filters).forEach((key: any) => {
      if (!Utilities.isObjectEmpty(this.filters[key])) {
        counterValidFilters++;
      }
    });

    if (counterValidFilters > 1) {
      this.atLeastOneFilter = true;
      customDebounce(() => this.getGroups(), 350);
    }
  }

  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }

  atLeastOneFilter = false;

  valueLocal: { users: number; groups: number } = {
    users: 0,
    groups: 0,
  };
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "id",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    group: "name",
  };
  groups: Group[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };
  selectedGroup: Group = new Group({ ...iniRawGroup });
  showRenameModal = false;

  paginationDefault = {
    limit: 100,
  };
  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  mapFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: FilterOperator.FULL_TEXT_SEARCH,
            value: this.filters[key].constructor === Array ? this.filters[key].join() : this.filters[key],
          });
        }
      });
    }
    this.updateParams();
  }

  action(data: { action: string; data: Group }): void {
    if (data.action === "rename") {
      this.openRenameModal(data.data);
    } else if (data.action === "delete") {
      this.deleteGroup(data.data);
    }
  }

  async getGroups(): Promise<void> {
    const data = await this.$services.group.fetchGroups(this.params);
    this.groups = data.results;
    this.pagination.totalElements = data.totalResult;
    this.valueLocal.groups = data.totalResult;
    this.$emit("input", this.valueLocal);
  }

  get fields(): IField[] {
    return [
      {
        key: "name",
        label: this.$t("groups.name") as string,
        sortable: true,
        class: "cell-30",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "description",
        label: this.$t("groups.description") as string,
        sortable: true,
        class: "cell-30",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle table-description-group",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "members",
        label: this.$t("groups.members_tab") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "lastUpdate",
        label: this.$t("groups.lastUpdate") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "actions",
        label: "",
        sortable: false,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-popup",
        visible: true,
        aux: true,
        code: "",
      },
    ];
  }

  formatedDate(date: string): string {
    return this.filterDate(date.replace("[GMT]", "")) + " " + this.filterTime(date.replace("[GMT]", ""));
  }

  openRenameModal(group: Group): void {
    this.selectedGroup = group;
    this.showRenameModal = true;
  }

  closeRenameModal(): void {
    this.showRenameModal = false;
  }

  async submitRename(data: Group): Promise<void> {
    await this.$services.group.renameGroup(data.id, data.name);
    Vue.swal({
      icon: "success",
      title: this.$t("groups.update_successful"),
      showConfirmButton: false,
      timer: 2000,
    });
    this.showRenameModal = false;
    this.selectedGroup = new Group({ ...iniRawGroup });
    await this.getGroups();
  }

  async deleteGroup(group: Group): Promise<void> {
    if (group.members <= 0) {
      const htmlTable = new TableHtml(
        [],
        [
          { title: (this.$t("groups.name") as string) + ": ", value: group.name },
          { title: this.$t("groups.description") + ": ", value: group.description },
        ],
        this.$t("groups.delete_confirmation") as string
      );
      htmlTable.buildTable("user");
      Vue.swal({
        customClass: {
          confirmButton: "swal2_prosegur_confirm",
          cancelButton: "swal2_prosegur_cancel",
        },
        showCancelButton: true,
        cancelButtonText: this.$t("users.user_alert.cancel-button") as string,
        html: htmlTable.getTable(),
      }).then(async (response) => {
        if (response.isConfirmed) {
          await this.$services.group.deleteGroup(group.id);
          Vue.swal({
            icon: "success",
            title: this.$t("groups.grid_alert.delete_success"),
            showConfirmButton: false,
            timer: 2500,
          });
          await this.getGroups();
        }
      });
    }
  }

  goToGroupPage(row: Group): void {
    this.$router.push({ name: "group-page", params: { id: row.id } });
  }
}
export default UserGroupGrid;
