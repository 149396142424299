
import { RawUser } from "@/entities";
import { Vue, Component, Prop } from "vue-property-decorator";
import UserInitials from "@/components/users/user-initials/UserInitials.vue";

@Component({
  name: "user-detail-header",
  components: {
    UserInitials,
  },
})
export class UserDetailHeader extends Vue {
  @Prop({ required: true }) user!: RawUser;
}
export default UserDetailHeader;
