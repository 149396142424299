import { RawUserGroup } from "@/entities";
export class UserGroup {
  readonly groupId: string;
  readonly customerId: string;

  constructor(data: RawUserGroup) {
    this.groupId = data.groupId || "";
    this.customerId = data.customerId || "";
  }
}
