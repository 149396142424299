
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { PFormInputTextSearch} from "@/common/components/look/PFormInput/PFormInputTextSearch.vue";

@Component({
  name: "p-form-select-search",
  components: {
    PDropDownContainer,
    PIcon,
    PFormInputTextSearch
  },
})
export class PFormSelectSearch extends Vue {
  @Prop({ required: true }) value!: any;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    if (this.value) {
      this.selectedItemValue = Object.assign(this.value);
    }
  }
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "Select an option" }) placeholder!: string;
  @Prop({ required: false, default: "primary" }) variant!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: false }) isScrollable!: boolean;
  @Prop({ required: false, default: true }) showText!: boolean;
  textSearch = "";
  showInput = false;
  selectedItemValue = "";
  optionSelect = this.isScrollable ? "optionsSelect_PFormSelect" + "_with_scrooll" : "optionsSelect_PFormSelect";
  pussOption = false;
  optionsFiltered = this.options;
  get variantCop(): string {
    let returnTemp: string = "inputSelect_PFormSelect_" + this.variant;
    if (this.variant === "button" && this.selectedItemValue != "") {
      returnTemp = returnTemp + " borderSelected_PFormSelect";
    }
    return returnTemp;
  }
  get variantExpandCop(): string {
    return "expand_moreSelect_PFormSelect_" + this.variant;
  }

  get selectedText(): string {
    const optionFind = this.options.find((opt) => opt.value == this.selectedItemValue);
    return optionFind && this.showText ?  optionFind.text : this.placeholder;
  }
  selectItem(itemValue: any): void {
    //this.selectedItemValue = Object.assign(itemValue);
    this.$emit("input", itemValue);
    this.$emit("change", itemValue);
    this.pussOption = false;
  }
  giveMeClass(itemValue: any): string {
    return this.selectedItemValue == itemValue ? "itemSelectSelected_PFormSelect" : "itemSelect_PFormSelect";
  }
  search(){
    if (this.validateIsEmptyOrHasEspecialsChars(this.textSearch)) {
        this.optionsFiltered = this.options;
        return;
    }
    let searchWord = this.textSearch.toLowerCase();
    this.optionsFiltered = this.options.filter(data => {
        return data.text.toLowerCase().includes(searchWord);
    })
  }
  validateIsEmptyOrHasEspecialsChars(textSearch: string)
  {
    return !textSearch.match(/\w+/i) || textSearch.length == 0;
  }
}
export default PFormSelectSearch;
