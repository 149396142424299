
import VueFilters from "@/vue-filters";
import { BvTableFieldArray } from "bootstrap-vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { MachineServicePoint } from "@/entities";

@Component({
  name: "machine-detail",
  mixins: [VueFilters],
})
export default class MachineDetail extends Vue {
  @Prop() public machineId!: string;
  @Action("setLoading") setLoading!: (loading: boolean) => void;

  detalles: MachineServicePoint[] = [];

  get fields(): BvTableFieldArray {
    return [
      // {
      //   key: "entityCode",
      //   label: this.$t("machine.table.detail.entity-code") as string,
      //   thClass: "table-header-detail",
      //   tdClass: "table-cell-detail",
      // },
      // {
      //   key: "entity",
      //   label: this.$t("machine.table.detail.entity") as string,
      //   thClass: "table-header-detail",
      //   tdClass: "table-cell-detail",
      // },
      // {
      //   key: "centerCode",
      //   label: this.$t("machine.table.detail.center-code") as string,
      //   thClass: "table-header-detail",
      //   tdClass: "table-cell-detail",
      // },
      {
        key: "center",
        label: this.$t("machine.table.detail.center") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
      // {
      //   key: "pointCode",
      //   label: this.$t("machine.table.detail.point-code") as string,
      //   thClass: "table-header-detail",
      //   tdClass: "table-cell-detail",
      // },
      {
        key: "servicePoint",
        label: this.$t("machine.table.detail.point") as string,
        thClass: "table-header-detail",
        tdClass: "table-cell-detail",
      },
    ];
  }
  constructor() {
    super();
  }
  mounted(): void {
    this.fetch();
  }
  async fetch(): Promise<void> {
    const data = await this.$services.machine.fetchMachineServicePoint(this.machineId);
    this.detalles = data.results;
  }
}
