
import { i18n } from "@/i18n";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Group, iniRawGroup } from "@/entities";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  i18n,
  components: { PIcon },
})
export class GroupGridOptions extends Vue {
  @Prop({ required: true }) value!: Group;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  open = false;
  valueLocal: Group = new Group({ ...iniRawGroup });

  get disabledDeleteClass(): string {
    return this.groupIsDeleteable() ? "" : "disabled";
  }

  getMessageDeleteGroupDisabled(): string {
    return this.$t("groups.rules.has_members").toString();
  }

  openRenameModal(): void {
    this.open = false;
    this.$emit("action", { action: "rename", data: this.valueLocal });
  }

  deleteGroup(): void {
    if (this.groupIsDeleteable()) {
      this.open = false;
      this.$emit("action", { action: "delete", data: this.valueLocal });
    }
  }

  groupIsDeleteable(): boolean {
    return this.valueLocal.members <= 0;
  }
}
export default GroupGridOptions;
