import { iniRawCenter, iniRawDevice, iniRawEntity, RawCenter, RawDevice, RawEntity } from "@/entities";

export type RawServicePoint = {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly earlyValue: boolean;
  readonly aggregateId: string;
  readonly entity: RawEntity;
  readonly center: RawCenter;
  readonly device: RawDevice;
};

export const iniRawServicePoint: RawServicePoint = {
  id: "",
  name: "",
  code: "",
  earlyValue: false,
  aggregateId: "",
  entity: { ...iniRawEntity },
  center: { ...iniRawCenter },
  device: { ...iniRawDevice },
};
