
import { Component, Vue } from "vue-property-decorator";
import { PRow, PCol } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import MachineNewModal from "../machine-new-modal/MachineNewModal.vue";

@Component({
  name: "machine-header",
  components: {
    PRow,
    PCol,
    MachineNewModal,
  },
})
export class MachineHeader extends Vue {
  search(): void {
    this.$emit("action");
  }
}
export default MachineHeader;
