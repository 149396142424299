
import { Component, Vue } from "vue-property-decorator";
import { PFormInputTextSearch } from "@/common/components";
import { EventBus } from "@/event-bus";

@Component({
  name: "user-search",
  components: {
    PFormInputTextSearch,
  },
})
export class UserSearch extends Vue {
  search = "";
  user_name = "";

  setSearch(text: string): void {
    this.search = text;
  }

  searchUser(): void {
    EventBus.$emit("search-user", this.search);
  }
}
export default UserSearch;
