
import { Vue, Component } from "vue-property-decorator";
import { BTabs, BTab } from "bootstrap-vue";

@Component({
  components: {
    "b-tabs": BTabs,
    "b-tab": BTab,
  },
})
export class UserTabs extends Vue {
  get userGridShowItems(): string {
    return "Usuarios";
  }

  get groupGridShowItems(): string {
    return "Grupos";
  }
}
export default UserTabs;
