import { Machine, RawMachine } from "@/entities";

export const mockRawMachines = (): RawMachine[] => [
  {
    name: "New Retail Device 1",
    id: "987654111170",
    manufacturer: "CIMA",
    model: "SDM 504",
    type: "RECYCLER",
    countryCode: "ES",
    delegationCode: "ES028",
    lastUpdate: "12/01/21",
    code: "200",
    servicePointCount: 5,
  },
  {
    name: "New Retail Device 2",
    id: "887654111170",
    manufacturer: "CIMA",
    model: "SDM 604",
    type: "RECYCLER",
    countryCode: "ES",
    delegationCode: "ES028",
    lastUpdate: "22/02/22",
    code: "330",
    servicePointCount: 3,
  },
  {
    name: "New Retail Device 3",
    id: "227654111170",
    manufacturer: "CIMA",
    model: "SDM 808",
    type: "RECYCLER",
    countryCode: "AR",
    delegationCode: "AR028",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 2,
  },
  {
    name: "New Retail Device 4",
    id: "947682748596",
    manufacturer: "CIMAX",
    model: "SDM 808X",
    type: "RECYCLERX",
    countryCode: "AR",
    delegationCode: "AR028X",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 10,
  },
  {
    name: "New Retail Device 5",
    id: "135780936584",
    manufacturer: "TEST",
    model: "SDM 808Z",
    type: "RECYCLERZ",
    countryCode: "BR",
    delegationCode: "AR028Z",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 12,
  },
  {
    name: "New Retail Device 6",
    id: "295734566789",
    manufacturer: "TEST",
    model: "SDM 80822",
    type: "RECYCLER22",
    countryCode: "BR",
    delegationCode: "AR02822",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 2,
  },
  {
    name: "New Retail Device 7",
    id: "643128664003",
    manufacturer: "CIMAX",
    model: "SDM 567",
    type: "TESTR",
    countryCode: "CH",
    delegationCode: "AR553",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 1,
  },
  {
    name: "New Retail Device 8",
    id: "987654111170",
    manufacturer: "CIMA",
    model: "SDM 504",
    type: "RECYCLER",
    countryCode: "ES",
    delegationCode: "ES028",
    lastUpdate: "12/01/21",
    code: "200",
    servicePointCount: 5,
  },
  {
    name: "New Retail Device 9",
    id: "887654111170",
    manufacturer: "CIMA",
    model: "SDM 604",
    type: "RECYCLER",
    countryCode: "ES",
    delegationCode: "ES028",
    lastUpdate: "22/02/22",
    code: "330",
    servicePointCount: 3,
  },
  {
    name: "New Retail Device 10",
    id: "227654111170",
    manufacturer: "CIMA",
    model: "SDM 808",
    type: "RECYCLER",
    countryCode: "AR",
    delegationCode: "AR028",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 2,
  },
  {
    name: "New Retail Device 11",
    id: "947682748596",
    manufacturer: "CIMAX",
    model: "SDM 808X",
    type: "RECYCLERX",
    countryCode: "AR",
    delegationCode: "AR028X",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 10,
  },
  {
    name: "New Retail Device 12",
    id: "135780936584",
    manufacturer: "TEST",
    model: "SDM 808Z",
    type: "RECYCLERZ",
    countryCode: "BR",
    delegationCode: "AR028Z",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 12,
  },
  {
    name: "New Retail Device 13",
    id: "295734566789",
    manufacturer: "TEST",
    model: "SDM 80822",
    type: "RECYCLER22",
    countryCode: "BR",
    delegationCode: "AR02822",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 2,
  },
  {
    name: "New Retail Device 14",
    id: "643128664003",
    manufacturer: "CIMAX",
    model: "SDM 567",
    type: "TESTR",
    countryCode: "CH",
    delegationCode: "AR553",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 1,
  },
  {
    name: "New Retail Device 4",
    id: "947682748596",
    manufacturer: "CIMAX",
    model: "SDM 808X",
    type: "RECYCLERX",
    countryCode: "AR",
    delegationCode: "AR028X",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 1,
  },
  {
    name: "New Retail Device 5",
    id: "135780936584",
    manufacturer: "TEST",
    model: "SDM 808Z",
    type: "RECYCLERZ",
    countryCode: "BR",
    delegationCode: "AR028Z",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 7,
  },
  {
    name: "New Retail Device 6",
    id: "295734566789",
    manufacturer: "TEST",
    model: "SDM 80822",
    type: "RECYCLER22",
    countryCode: "BR",
    delegationCode: "AR02822",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 9,
  },
  {
    name: "New Retail Device 7",
    id: "643128664003",
    manufacturer: "CIMAX",
    model: "SDM 567",
    type: "TESTR",
    countryCode: "CH",
    delegationCode: "AR553",
    lastUpdate: "12/12/21",
    code: "400",
    servicePointCount: 14,
  },
];

export const mockMachines = (data: RawMachine[] = mockRawMachines()): Machine[] =>
  data.map((item) => new Machine(item));
