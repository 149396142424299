
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IGroupFilter, iniGroupFilter } from "@/services";
import { PFormInputTextSearch } from "@/common/components";

@Component({
  name: "user-group-filter-wrapper",
  components: {
    PFormInputTextSearch,
  },
})
export class UserGroupFilterWrapper extends Vue {
  @Prop({ required: true }) value!: IGroupFilter;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  isBtnSearchDisabled: boolean = true;
  valueLocal: IGroupFilter = iniGroupFilter;

  enableBtnSearch(): void {
    const hasGroup = this.valueLocal.group && this.valueLocal.group.length >= 3;
    this.isBtnSearchDisabled = !hasGroup;
  }

  search(): void {
    if (!this.isBtnSearchDisabled)  {
      this.$emit("input", this.valueLocal);
    }
  }
}
export default UserGroupFilterWrapper;
