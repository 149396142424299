
import { Component, Vue } from "vue-property-decorator";
import { MachineGrid, MachineHeader, MachinesFiltersWrapper, MachineTabs } from "@/components";
import SolutionHistoryGrid from "@/components/solution-history/solution-history-grid/SolutionHistoryGrid.vue";
import MachineImportNewModal from "@/components/machines/machine-import-new-modal/MachineImportNewModal.vue";
import { IMachineFilter, iniMachineFilter } from "@/services";

@Component({
  components: {
    MachineHeader,
    MachineGrid,
    MachinesFiltersWrapper,
    MachineTabs,
    SolutionHistoryGrid,
    MachineImportNewModal,
  },
})
export class MachinePage extends Vue {
  machineFilter: IMachineFilter = { ...iniMachineFilter };

  get filtrosToBack(): IMachineFilter {
    const filtrosBack = { ...iniMachineFilter };
    filtrosBack.country = this.machineFilter.country;
    filtrosBack.type = this.machineFilter.type;
    filtrosBack.delegation = this.machineFilter.delegation;
    filtrosBack.manufacturer = this.machineFilter.manufacturer;
    filtrosBack.text = this.machineFilter.text;
    filtrosBack.code = this.machineFilter.code;
    return filtrosBack;
  }

  search(): void {
    this.machineFilter = { ...iniMachineFilter };
    this.machineFilter.forceRefresh = !this.machineFilter.forceRefresh;
  }
}

export default MachinePage;
