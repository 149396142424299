
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelectType } from "./pFormSelect.types";
import PDropDownContainer from "../PDropDownContainer/PDropDownContainer.vue";
import { PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { PFormInputTextSearch} from "@/common/components/look/PFormInput/PFormInputTextSearch.vue";

@Component({
  name: "p-form-select-multi-search",
  components: {
    PDropDownContainer,
    PIcon,
    PFormInputTextSearch
  },
})
export class PFormSelectMultiSearch extends Vue {
  @Prop({ required: true }) value!: string[];
  @Prop({ required: true }) options!: SelectType[];
  @Prop({ required: false, default: "Select an option" }) placeholder!: string;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: 3 }) maxSizeSelected!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: false }) isScrollable!: boolean;

  mounted() {
    this.rellenarValueTempInicial();
  }
  valueTemp: string[] = [];
  optionSelect = this.isScrollable ? "optionsSelect_PFormSelectMulti" + "_with_scrooll" : "optionsSelect_PFormSelectMulti";
  pussOption = false;
  textSearch = "";
  optionsFiltered = this.options;

  get selectedTexts(): string[] {
    return this.valueTemp.length > 0 ? this.valueTemp : [];
  }
  giveMeTextOption(selectedText: string): string {
    const optionSelected = this.options.find((op) => op.value === selectedText);
    if (optionSelected) {
      return optionSelected.text;
    }
    return selectedText;
  }
  rellenarValueTempInicial(): void {
    this.$nextTick(() => {});
    this.options.forEach((op, index) => {
      if (this.value.findIndex((val) => val === op.value) > -1) {
        this.$set(this.valueTemp, index, op.value); //es porque sino no esreactivo el valor
      }
    });
  }
  selectItem(itemValue: any): void {
    this.validateNotExistNewItemAndNotExceedMaxItemsToPush(itemValue, this.valueTemp)
    this.$emit("input", this.valueTemp);
    this.$emit("change", this.valueTemp);
    this.pussOption = false;
  }
  validateNotExistNewItemAndNotExceedMaxItemsToPush(itemValue: any, array: string[]){
    if (!array.find((vt) => vt === itemValue)) {
        if(array.length <= this.maxSizeSelected - 1){
          array.push(itemValue);
        }
    }else{
      this.unSelectedItem(itemValue);
    }
  }
  unSelectedItem(itemValue: any): void {
    this.valueTemp.splice(this.valueTemp.indexOf(itemValue), 1);

    this.$emit("input", this.valueTemp);
    this.$emit("change", this.valueTemp);
  }
  giveMeClass(itemValue: any): string {
    return this.valueTemp.find((vt) => vt === itemValue) ? "itemSelectSelected_PFormSelectMulti"
           : "itemSelect_PFormSelectMulti";
  }
  search(){
    if (this.validateIsEmptyOrHasEspecialsChars(this.textSearch)) {
        this.optionsFiltered = this.options;
        return;
    }
    let searchWord = this.textSearch.toLowerCase();
    this.optionsFiltered = this.options.filter(data => {
        return data.text.toLowerCase().includes(searchWord);
    })
  }
  validateIsEmptyOrHasEspecialsChars(textSearch: string)
  {
    return !textSearch.match(/\w+/i) || textSearch.length == 0;
  }
} 
export default PFormSelectMultiSearch;
