import { RawDelegation } from "./delegation.type";
import { Delegation } from "./delegation";

export const mockRawDelegation = (): RawDelegation[] => [
  {
    id: "0fb3ee2a-651f-33a6-9a07-318e7313b1dd",
    code: "028",
    name: "SIM-20",
    countryCode: "PE",
  },
  {
    id: "1fc5efe0-2098-3df2-813a-97ce9f2f586f",
    code: "030",
    name: "SIM-21",
    countryCode: "PE",
  },
];

export const mockDelegation = (data: RawDelegation[] = mockRawDelegation()): Delegation[] =>
  data.map((item) => new Delegation(item));
