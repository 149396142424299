import { RawSupportTransaction } from "@/entities";

export class SupportTransaction {
  readonly date: string;
  readonly code: string;
  readonly description: string;
  readonly actualId: string;
  readonly entity: string;
  readonly entityId: string;
  readonly deviceId: string;
  readonly status: string;
  readonly jsonDetail: string;

  constructor(data: RawSupportTransaction) {
    this.date = data.date || "";
    this.code = data.code || "";
    this.description = data.description || "";
    this.actualId = data.actualId || "";
    this.entity = data.entity || "";
    this.entityId = data.entityId || "";
    this.deviceId = data.deviceId || "";
    this.status = data.status || "";
    this.jsonDetail = data.jsonDetail || "";
  }
}
