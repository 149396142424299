
import { Component, Vue } from "vue-property-decorator";
import { PFormInputText, PButton, SelectType, PFormSelect } from "@/common/components";
import { PRow, PIcon } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";
import { EventBus } from "@/event-bus";

@Component({
  name: "support-drop-down-entity-id",
  components: {
    PRow,
    PButton,
    PFormInputText,
    PFormSelect,
    PIcon,
  },
})
export class SupportDropDownEntityId extends Vue {
  filterProperties: SelectType[] = [];
  selectItemFilterPropertie = "";
  link = "link";

  async created(): Promise<void> {
    const result1: string[] = await this.$services.supportTransaction.fetchEntityIds();
    result1.forEach((it: string) => this.filterProperties.push({ text: it.toString(), value: it.toString() }));
  }

  setItem(item: string): void {
    this.selectItemFilterPropertie = item;
  }

  reset(): void {
    this.selectItemFilterPropertie = this.$t("support_panel.dropdown.placeholder_entity_id").toString();
    EventBus.$emit("clear-filter-by-entity-id", this.selectItemFilterPropertie);
    alert("Filtro reseteado");
  }

  filter(): void {
    EventBus.$emit("filter-by-entity-id-support", { entityId: this.selectItemFilterPropertie });
    alert("Filtro aplicado");
  }
}
export default SupportDropDownEntityId;
