
import { Component, Vue, Prop } from "vue-property-decorator";
import { PFormCheckBox } from "@/common/components";

@Component({
  name: "members-list",
  components: {
    PFormCheckBox,
  },
})
export class MembersList extends Vue {
  @Prop({ required: true }) usersList!: Record<string, unknown>[];

  usersToAddList: object[] = [];
  usersSelected: string[] = [];
  title1 = "";

  setUser(value: string | boolean, user: Record<string, string>): void {
    if (value === false) {
      this.usersToAddList = this.usersToAddList.filter((item) => item != user);
      this.$emit("users-to-add", this.usersToAddList);
      this.usersSelected = this.usersSelected.filter((item) => item != user.name + " " + user.lastName);
      this.title1 = this.usersSelected.join(", ");
    } else {
      this.usersToAddList.push(user);
      this.usersSelected.push(user.name + " " + user.lastName);
      this.title1 = this.usersSelected.join(", ");
      this.$emit("users-to-add", this.usersToAddList);
    }
  }

  clearList(): void {
    this.usersToAddList = [];
    this.usersSelected = [];
    this.title1 = "";
  }
}
export default MembersList;
