
import { Vue, Component } from "vue-property-decorator";
import { PFormSelect, PFormInputText, PDropDownContainer, SelectType, PButton } from "@/common/components";
import { PRow } from "@prosegur/cash-innovation-shared-vue2-ui-component-library";

@Component({
  name: "users-all-drop-down",
  components: {
    PRow,
    PFormSelect,
    PFormInputText,
    PDropDownContainer,
    PButton,
  },
})
export class UsersAllDropDown extends Vue {
  selectItemFilterProperties = "";
  link = "link";
  filterProperties: SelectType[] = [
    { text: "Prosegur", value: "Prosegur" },
    { text: "Customer", value: "Customer" },
  ];

  setItem(item: string): void {
    this.selectItemFilterProperties = item;
  }

  filter(): void {
    this.$emit("filter-user-by-type", this.selectItemFilterProperties);
  }

  reset(): void {
    this.selectItemFilterProperties = "Seleccione un tipo de usuario";
    this.$emit("reset-filter-user-by-type");
  }
}
export default UsersAllDropDown;
