import {PSubtableMaker} from './PSubtableMaker'
import { PTableMultilevelSettings, HeaderRows } from "./PTableMultilevel.type";
import { EventBus } from "@/event-bus";
import { PTableMakerParent } from "./PtableMakerParent";
export class PTableMaker extends PTableMakerParent {

class_collapseRow= "collapse_row";
class_clickShowIcon ="click-show-icon";
idtable="";
rows: any[] = [];
table: HTMLTableElement ={} as HTMLTableElement;
tbody: HTMLTableSectionElement= {} as HTMLTableSectionElement;
pSubtableMaker: PSubtableMaker={} as PSubtableMaker;
settings: PTableMultilevelSettings = {} as PTableMultilevelSettings;
headers: HeaderRows[] = [];

constructor(idtable: string, settings: PTableMultilevelSettings){     
   super();
   this.settings= settings;
   this.idtable =idtable;
   this.rows= this.settings.datasource.rows;
   this.headers= this.settings.datasource.header.fields;
}

initializeTable():void {
      this.setStructure(this.idtable);
      this.initializeSubtableMaker();      
      this.setDataSource();
}
      
clickOnFirstCell(value: string): void {    
   EventBus.$emit("ptablemaker_click-on-first-table-cell",value);
}

showSubTable(rowindex: number): void {
   this.$emit("show-subtable",rowindex);
}

clickOnSubTableLastCell(rowindex: number):void { 
   EventBus.$emit("ptablemaker_subtable_click", rowindex);   
}

refreshDataSource(rows:Record<string,unknown>[]):void{
   this.clearRows();
   this.setDataSource(rows);
}

private clearRows():void{
   while (this.tbody.rows.length > 0) {
    const child = this.tbody.firstChild;
    if (child !==  null){
      this.tbody.removeChild(child);
    }
   }
}
  
private setStructure(idtable: string): void {
   this.table = document.getElementById(idtable) as HTMLTableElement;
   this.tbody = this.table?.createTBody();
   this.createHeadersTable();
}

private createHeadersTable(): void {
   const thead: HTMLTableSectionElement= this.table.createTHead();   
   const thead_tr : HTMLTableRowElement = thead.insertRow(0);
   const headers = this.headers.filter((header)=> header.isVisible);
   headers.forEach(header=>{
         const th: HTMLTableCellElement = document.createElement("th");
         th.textContent=header.value.toUpperCase();
         th.style.cssText  = this.settings.datasource.header.style || "";
         if(header.handleClickEvent){
            this.addOrdenIcon(th,header.key);
            th.addEventListener('click',()=>{
               EventBus.$emit("p-table-multilevel-header-click",header.key)
            });
         }
         thead_tr.appendChild(th);
   });
}

private addOrdenIcon(th: HTMLTableCellElement,id:string){
   const span = document.createElement("span");
   span.className = "filter-icon";
   span.innerHTML ="&#x25BC";
   span.id ="span-icon-"+id;
   th.appendChild(span);
}

private initializeSubtableMaker():void{
   this.pSubtableMaker= new PSubtableMaker(this.tbody, this.table);
   this.pSubtableMaker.$on("click-event-on-last-cell", this.clickOnSubTableLastCell);
}

private setDataSource(rows?:Record<string,unknown>[], tbody?:HTMLTableSectionElement): void {
   const localRows = rows != undefined ? rows : this.rows;
   localRows.forEach((row:Record<string,unknown>,idx)=> { 
      let customRow:Record<string,unknown>= {};
      this.headers.forEach((header)=> {
         if( row.hasOwnProperty(header.key) && header.isVisible){
            customRow[header.key] =row[header.key];
         }
      });     
     this.addRow(customRow,idx,tbody);
   });        
}

private addRow(values: any, index: number, tbody?:HTMLTableSectionElement):void {
   const localTbody:HTMLTableSectionElement = tbody != undefined ? tbody : this.tbody;
   const newRow : HTMLTableRowElement =localTbody.insertRow(index); 
   newRow.classList.add("table_multilevel_"+ index);
   Object.entries(values).forEach((entry,idx)=>{
         const columnValue : string =  String(entry[1]) + " ";
         const columnName: string = String(entry[0])
            const newCell : HTMLTableCellElement = newRow.insertCell();    
            const cell : Text = document.createTextNode(columnValue);         
            
            if (columnName=="ShowSubLevel"){           
               newCell.classList.add(this.class_collapseRow);
               newCell.classList.add(this.class_clickShowIcon);
               if(entry[1] as number > 0) {
               newCell.addEventListener('click', ()=>{this.showSubTable(index)} );
               newCell.insertAdjacentHTML("beforeend","<span>" + columnValue +  this.chevrondown() + "</span>")
               
               }else
               newCell.insertAdjacentHTML("beforeend","<span>" +  this.chevrondown() + "</span>")
              
                 
            } else {
               if (idx == 0) {
                  newCell.addEventListener('click', ()=>{this.clickOnFirstCell(columnValue)});
                  newCell.classList.add(this.class_clickShowIcon);     
               }             
               newCell.appendChild(cell);
            }      
   }); 
}

}