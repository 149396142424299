import Vue from "vue";
import Router from "vue-router";
import { IStore } from "@/store";
import CallbackPage from "@/pages/CallbackPage.vue";
import { DefaultLayout } from "@/common/components";
import ErrorPage from "@/pages/ErrorPage.vue";
import NotFoundErrorPage from "@/pages/NotFoundErrorPage.vue";
import Users from "@/pages/users/Users.vue";
import EntityPage from "@/pages/entity/EntityPage.vue";
import CenterPage from "@/pages/centers/CenterPage.vue";
import MachinePage from "@/pages/machines/MachinePage.vue";
import GroupPage from "@/pages/groups/GroupPage.vue";
import UserPage from "@/pages/user/UserPage.vue";
import SupportPage from "@/pages/support/SupportPage.vue";
import { EventBus } from "@/event-bus";
import { i18n } from "@/i18n";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "root",
      component: DefaultLayout,
      redirect: "/users",
      children: [
        {
          path: "users",
          name: "users",
          component: Users,
          meta: {
            resource: "Users",
          },
        },
        {
          path: "users/:id/group",
          name: "group-page",
          component: GroupPage,
          meta: {
            resource: "GroupPage",
          },
        },
        {
          path: "users/:id",
          name: "user-page",
          component: UserPage,
          meta: {
            resource: "UserPage",
          },
        },
        {
          path: "entities/",
          name: "entities",
          component: EntityPage,
          meta: {
            resource: "EntityPage",
          },
        },
        {
          path: "entity/:id/centers/",
          name: "entity-centers",
          component: CenterPage,
          meta: {
            resource: "CenterPage",
          },
        },
        {
          path: "machines",
          name: "machines",
          component: MachinePage,
          meta: {
            resource: "MachinePage",
          },
        },
        {
          path: "panel-support",
          name: "panel-support",
          component: SupportPage,
          meta: {
            resource: "SupportPage",
          },
        },
        {
          path: "callback",
          name: "callback",
          component: CallbackPage,
          meta: {
            anonymous: true,
          },
        },
        {
          path: "404",
          name: "404",
          component: NotFoundErrorPage,
          meta: {
            anonymous: true,
          },
        },
        {
          path: "error",
          name: "error",
          component: ErrorPage,
          meta: {
            anonymous: true,
          },
        },
      ],
    },
    { path: "*", redirect: "/404" },
  ],
});

let usersCount = 0;

EventBus.$on("updateUsersCount", (count: number) => {
  usersCount = count;
});

export const useRouter = (store: IStore): Router => {
  router.beforeEach(async (to, _from, next) => {
    if (to.matched.some((record) => record.meta.anonymous)) {
      next();
    } else {
      await store.dispatch("setUrl", `${window.origin}${to.fullPath}`);

      if (!store.getters.getIsSignedIn) {
        await store.dispatch("initialize");
      }

      if (store.getters.getIsSignedIn) {
        if (usersCount > 0) {
          Vue.swal({
            icon: "warning",
            title: i18n.t("changeNotSave"),
            customClass: {
              confirmButton: "swal2_prosegur_confirm",
              cancelButton: "swal2_prosegur_cancel",
            },
            showCancelButton: true,
            cancelButtonText: i18n.t("users.user_alert.cancel-button") as string,
          }).then(async (response) => {
            if (response.isConfirmed) {
              usersCount = 0;
              next();
            }
          });
        } else {
          next();
        }
      }
    }
  });

  return router;
};
