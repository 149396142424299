import { mockRawCountries } from "@/entities";
import { User } from "./user";
import { RawUser } from "./user.types";

export const mockRawUser = (): RawUser[] => [
  {
    id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    name: "Pablo",
    lastName: "Ocanto",
    fullName: "Pablo Ocanto",
    email: "email@prosegur.com",
    country: mockRawCountries()[0].isoCode,
    group: {
      id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
      name: "Group 1",
      dateAdded: "2021-07-01T00:00:00.000Z",
    },
    type: "Prosegur",
    active: true,
    status: "Pending",
    assignedServicePoints: Array.of(),
    assignedCountries: Array.of(),
    corporateAdmin: true,
  },
  {
    id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    name: "Emiliano",
    lastName: "Ayala",
    fullName: "Emiliano Ayala",
    email: "email@prosegur.com",
    country: mockRawCountries()[1].isoCode,
    group: {
      id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
      name: "Group 1",
      dateAdded: "2021-07-01T00:00:00.000Z",
    },
    type: "Customer",
    active: true,
    status: "Pending",
    assignedServicePoints: Array.of(),
    assignedCountries: Array.of(),
    corporateAdmin: false,
  },
  {
    id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    name: "Raul",
    lastName: "Cruz",
    fullName: "Raul Cruz",
    email: "email@prosegur.com",
    country: mockRawCountries()[1].isoCode,
    group: undefined,
    type: "Prosegur",
    active: true,
    status: "Done",
    assignedServicePoints: Array.of(),
    assignedCountries: Array.of(),
    corporateAdmin: false,
  },
  {
    id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    name: "Marinel",
    lastName: "Perdomo",
    fullName: "Marinel Perdomo",
    email: "email@prosegur.com",
    country: mockRawCountries()[1].isoCode,
    group: undefined,
    type: "Customer",
    active: true,
    status: "Done",
    assignedServicePoints: Array.of(),
    assignedCountries: Array.of(),
    corporateAdmin: false,
  },
  {
    id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    name: "Manuel",
    lastName: "Leone",
    fullName: "Manuel Leone",
    email: "email@prosegur.com",
    country: mockRawCountries()[3].isoCode,
    group: undefined,
    type: "Prosegur",
    active: false,
    status: "Pending",
    assignedServicePoints: Array.of(),
    assignedCountries: Array.of(),
    corporateAdmin: false,
  },
  {
    id: "037ee455-66d0-40f9-8269-3eb75f5e0066",
    name: "user 5",
    lastName: "lastName",
    fullName: "user 5 lastname",
    email: "email@prosegur.com",
    country: mockRawCountries()[2].isoCode,
    group: undefined,
    type: "Company",
    active: true,
    status: "Done",
    assignedServicePoints: Array.of(),
    assignedCountries: Array.of(),
    corporateAdmin: false,
  },
];

export const mockUser = (data: RawUser[] = mockRawUser()): User[] => data.map((item) => new User(item));
