import {
  mockAuthService,
  mockCenterService,
  mockCountryService,
  mockCurrencyService,
  mockEntityService,
  mockGroupService,
  mockMachineService,
  mockServicePointService,
  mockSolutionHistoryService,
  mockSolutionService,
  mockSupportTransactionService,
  mockUserGroupService,
  mockUserService,
} from "@/services";

import { IProviderMock } from "./provider.types";

export const mockProvider = (): IProviderMock => ({
  auth: mockAuthService(),
  user: mockUserService(),
  country: mockCountryService(),
  entity: mockEntityService(),
  center: mockCenterService(),
  machine: mockMachineService(),
  group: mockGroupService(),
  supportTransaction: mockSupportTransactionService(),
  userGroup: mockUserGroupService(),
  solutionHistory: mockSolutionHistoryService(),
  currency: mockCurrencyService(),
  solution: mockSolutionService(),
  servicepoint: mockServicePointService(),
});
