import { RawRule } from "./rule.types";

export class Rule {
  readonly id: string;
  readonly fieldName: string;
  readonly fieldOperator: string;
  readonly fieldValue: string;
  readonly ruleOperator: string;
  readonly fieldType: string;
  showValue: string;
  constructor(data: RawRule) {
    this.id = data.id || "";
    this.fieldName = data.fieldName || "";
    this.fieldOperator = data.fieldOperator || "";
    this.fieldValue = data.fieldValue || "";
    this.ruleOperator = data.ruleOperator || "";
    this.fieldType = data.fieldType || "";
    this.showValue = data.showValue ? data.showValue : data.fieldValue;
  }
}
