
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "user-initials",
  components: {},
})
export class UserInitials extends Vue {
  @Prop({ default: "" }) public name!: string;
  @Prop({ default: "" }) public lastName!: string;
  @Prop({ default: "" }) public type!: string;
  @Prop({ default: false }) public onlyIcon!: boolean;

  userProsegur = true;

  getCaps(): string {
    return this.name.toString().substr(0, 1).toUpperCase() + this.lastName.toString().substr(0, 1).toUpperCase();
  }

  mounted(): void {
    this.checkType(this.type);
  }

  updated(): void {
    this.checkType(this.type);
  }

  private checkType(type: string): void {
    if (type == "Prosegur") {
      this.userProsegur = true;
    } else {
      this.userProsegur = false;
    }
  }
}

export default UserInitials;
