
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { iniPaginationPTable, PaginationPTableType } from "@/common/components";
import Utilities from "@/components/commons/Utilities";
import { i18n } from "@/i18n";
import { FilterOperator, PageRequest } from "@/services";
import { IField, Center } from "@/entities";
import { customDebounce } from "@/common/utils";

@Component({
  name: "center-grid",
  components: {},
})
export class CenterGrid extends Vue {
  @Prop({ required: true }) entityId!: string;
  @Prop({ required: true }) value!: { users: number; groups: number };
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    if (this.value) this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }
  @Prop({ required: true }) filters!: any;
  @Watch("filters", { immediate: true, deep: true })
  onChangeFilters(): void {
    this.pagination = { ...iniPaginationPTable };
    this.updatePaginationBack();
    this.mapIUserFilterToRecord();
  }
  @Watch("params", { immediate: true, deep: true })
  onChangeParams(): void {
    if (this.firstLoad) {
      this.getCenters();
      this.firstLoad = false;
    } else customDebounce(() => this.getCenters(), 350);
  }
  @Watch("sort", { immediate: true, deep: true })
  onChangeSort(): void {
    if (
      this.paginationBack.sortWay != (this.sort.sortDesc ? "DESC" : "ASC") ||
      this.paginationBack.sortField != this.sort.sortField
    ) {
      this.paginationBack.sortWay = this.sort.sortDesc ? "DESC" : "ASC";
      this.paginationBack.sortField = this.sort.sortField;
      this.updateParams();
    }
  }
  firstLoad = true;
  valueLocal: { centers: number } = {
    centers: 0,
  };
  language = i18n.locale;
  sort: { sortDesc: boolean; sortField: string } = {
    sortDesc: true,
    sortField: "status",
  };
  filters_field: Record<string, unknown>[] = [];
  pagination: PaginationPTableType = { ...iniPaginationPTable };
  params: Record<string, unknown> = Utilities.pageable(
    this.pagination.pageSize,
    null,
    this.sort.sortField,
    this.sort.sortDesc ? "DESC" : "ASC"
  );
  mapFilters: Record<string, string> = {
    name: "centerName",
    code: "centerCode",
  };
  centers: Center[] = [];
  paginationBack: PageRequest = {
    limit: Number(this.params["limit"]),
    offset: Number(this.params["offset"]),
    sortField: this.params["sortField"] as string,
    sortWay: this.params["sortWay"] as string,
    filters: [],
  };
  paginationDefault = {
    limit: 100,
  };

  mapIUserFilterToRecord(): void {
    this.filters_field = [];
    if (!Utilities.isObjectEmpty(this.filters)) {
      Object.keys(this.filters).forEach((key: any) => {
        if (!Utilities.isObjectEmpty(this.filters[key]) && this.mapFilters[key]) {
          this.filters_field.push({
            field: this.mapFilters[key],
            operator: FilterOperator.FULL_TEXT_SEARCH,
            value: this.filters[key].constructor === Array ? this.filters[key].join() : this.filters[key],
          });
        }
      });
    }
    this.updateParams();
  }

  updateParams(): void {
    this.params = Utilities.pageable(
      this.paginationBack.limit,
      this.paginationBack.offset,
      this.paginationBack.sortField,
      this.paginationBack.sortWay,
      this.filters_field
    );
  }

  paginate(): void {
    this.updatePaginationBack();
    this.updateParams();
  }

  updatePaginationBack(): void {
    this.paginationBack.limit = this.pagination.pageSize;
    this.paginationBack.offset = (this.pagination.currentPage - 1) * this.pagination.pageSize;
  }

  async getCenters(): Promise<void> {
    const data = await this.$services.center.fetchCentersByEntityId(this.entityId, this.params);
    this.centers = data.results;
    this.pagination.totalElements = data.totalResult;
    this.valueLocal.centers = data.totalResult;
    this.$emit("input", this.valueLocal);
  }

  get fields(): IField[] {
    return [
      {
        key: "code",
        label: this.$t("centerPage.table.code") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "name",
        label: this.$t("centerPage.table.name") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "cityName",
        label: this.$t("centerPage.table.cityName") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "zipCode",
        label: this.$t("centerPage.table.zipCode") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
      {
        key: "provinceName",
        label: this.$t("centerPage.table.provinceName") as string,
        sortable: true,
        class: "",
        thClass: "table-header",
        tdClass: "table-cell table-cell-middle",
        visible: true,
        aux: false,
        code: "",
      },
    ];
  }
}
export default CenterGrid;
