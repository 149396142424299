
import { Component, Vue } from "vue-property-decorator";
import {
  GroupDescription,
  GroupFilterWrapper,
  GroupGrid,
  GroupHeaderWrapper,
  GroupMembersTab,
  GroupPermissionsTab,
} from "@/components/groups";
import { Group } from "@/entities";
import { MachinesFiltersWrapper } from "@/components";
import { iniUserFilter, IUserFilter } from "@/services";

@Component({
  name: "group-page",
  components: {
    GroupHeaderWrapper,
    GroupMembersTab,
    GroupPermissionsTab,
    GroupFilterWrapper,
    GroupGrid,
    GroupDescription,
    MachinesFiltersWrapper,
  },
})
export class GroupPage extends Vue {
  userFilter: IUserFilter = { ...iniUserFilter };

  groupId!: string;
  group!: Group;
  isLoaded = false;
  update = false;
  countResults: { users: number } = {
    users: 0,
  };

  refresh() {
    this.$services.group.fetchGroup(this.groupId).then((value: Group) => {
      this.group = value;
      this.userFilter.group = this.group.id;
      this.isLoaded = true;
      this.update = !this.update;
    });
  }

  created() {
    this.groupId = this.$route.params.id;
    this.$services.group.fetchGroup(this.groupId).then((value: Group) => {
      this.group = value;
      this.userFilter.group = this.group.id;
      this.isLoaded = true;
    });
  }

  get filtrosUserToBack(): IUserFilter {
    const filtrosBack = { ...iniUserFilter };
    filtrosBack.text = this.userFilter.text;
    filtrosBack.group = this.userFilter.group;
    return filtrosBack;
  }
}
export default GroupPage;
