import { RawUserGroup, UserGroup } from "@/entities";

export const mockRawUserGroup = (): RawUserGroup[] => [
  {
    groupId: "63b15c17-1885-4891-8f1a-169cb7329770",
    customerId: "63b15c17-1885-4891-8f1a-169cb7329abc",
  },
];

export const mockUserGroup = (data: RawUserGroup[] = mockRawUserGroup()): UserGroup[] =>
  data.map((item) => new UserGroup(item));
