export class TableHtml<T extends object> {
  private table = "";
  private headers: string[] = [];
  private rows: T[] = [];
  private headerTitle = "";

  constructor(_headers: string[], _rows: T[], _headerTitle: string) {
    this.headers = _headers;
    this.rows = _rows;
    this.headerTitle = _headerTitle;
  }
  getTable(): string {
    return this.table;
  }

  buildTable(extclass: string): void {
    this.clearTable();
    this.writeContentOnTable("<div id='customtable_prosegur_" + extclass + "'>");
    if (extclass == "user") {
      this.writeContentOnTable("<h5>" + this.headerTitle + "</h5> <br/>");
    }
    this.writeContentOnTable("<table>");
    this.addHeader(this.headers);
    this.writeContentOnTable("<tbody>");
    for (const row of this.rows) {
      this.addRow(row);
    }
    this.writeContentOnTable("</tbody>");
    this.writeContentOnTable("</table>");
    this.writeContentOnTable("</div>");
  }
  private clearTable(): void {
    this.table = "";
  }
  private writeContentOnTable(content: string): void {
    this.table += " " + content + " ";
  }
  private addRow(row: T): void {
    this.writeContentOnTable("<tr>");
    Object.values(row).forEach((property) => {
      this.writeContentOnTable("<td>");
      this.writeContentOnTable(String(property));
      this.writeContentOnTable("</td>");
    });
    this.writeContentOnTable("</tr>");
  }
  private addHeader(headers: string[]): void {
    this.writeContentOnTable("<thead>");
    this.writeContentOnTable("<tr>");
    for (const header of headers) {
      this.writeContentOnTable("<th>");
      this.writeContentOnTable(header);
      this.writeContentOnTable("</th>");
    }
    this.writeContentOnTable("</tr>");
    this.writeContentOnTable("</thead>");
  }
}
