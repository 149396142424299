
import { RawUser } from "@/entities";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export class UserDetailGroup extends Vue {
  @Prop({ required: true }) user!: RawUser;

  get userHaveGroup(): boolean {
    return !!this.user.group?.id;
  }

  goToGroup(): void {
    this.$router.push({ name: "group-page", params: { id: this.user.group?.id! } });
  }
}

export default UserDetailGroup;
